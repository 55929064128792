<div class="audit-detail-drawer">
	<h2>{{auditInformation.pchName}}</h2>
	<div class="skill-information">
		<div class="header-content">
			<h3 class="title">Audit Information</h3>
			<p [class]="{
										'decision-approved': auditInformation.skillRequestDecision === 'Approved',
										'decision-declined': auditInformation.skillRequestDecision !== 'Approved'
										}">{{auditInformation.skillRequestDecision || 'No Decision'}}</p>
		</div>
		<hr />
		<div class="info-grid-three">
      <div class="info-item mw-153">
				<p><strong>Request Date</strong><br> {{auditInformation.skillRequestDate || 'No Request Date'}}</p>
			</div>
			<div class="info-item mw-164">
				<p><strong>Taxonomy Change Date</strong><br> {{auditInformation.skillTaxonomyChangeDate || 'No Taxonomy Change Date'}}
				</p>
			</div>
			<div class="info-item type-info">
				<p><strong>Type</strong><br> {{auditInformation.skillRequestType || 'No Request Type' }} </p>
			</div>
		</div>
		<div class="info-grid">
			<div>
				<p><strong>Requestor</strong><br> {{auditInformation.skillRequestor || 'No Requestor'}} </p>
			</div>
		</div>
    <div>
			<p><strong>Requestor Comment</strong><br>
			</p>
      <p class="m-comment" [ngClass]="{'truncated':!showFullRequestorText}"> {{ auditInformation.skillRequestorComment|| 'No Requestor Change' }}</p>
      @if (showMoreVisible(auditInformation.skillRequestorComment)) {
				<a class="show-more" (click)="toggleText('Requestor')">{{ showFullRequestorText ? 'Show Less' : 'Show More' }}</a>
			}
		</div>
		<div class="info-grid">
			<div>
				<p><strong>Processor</strong><br> {{auditInformation.skillProcessor || 'No Processor'}} </p>
			</div>
			<div>
				<p><strong>Processor's Email</strong><br> {{auditInformation.skillProcessorEmail || 'No Processor Email'}} </p>
			</div>

		</div>
		<div>
			<p><strong>Processor Comment</strong><br>
			</p>
      <p class="m-comment" [ngClass]="{'truncated':!showFullProcessorText}">
				{{auditInformation.skillProcessorComment || 'No Processor Comment' }}
			</p>
      @if(showMoreVisible(auditInformation.skillProcessorComment)){
      <a class="show-more" (click)="toggleText('Processor')">{{ showFullProcessorText ? 'Show Less' : 'Show More' }}</a>
      }
		</div>
	</div>
</div>
