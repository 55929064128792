import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Optional, Self, HostBinding, Input, Output, ViewChild, NgModule } from '@angular/core';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import { FormAccessorMixin, ErrorStateMixin, DestroyMixin } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import { generateId, coerceBooleanProperty } from '@usitsdasdesign/dds-ng/core/utils';
import { WidthState, DDS_FORM_CONTROL_GROUP, AdditionalClass, Themes, KeyCodes, DDS_FORM_CONTROL } from '@usitsdasdesign/dds-ng/shared';
import { takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/forms';
import * as i2 from '@usitsdasdesign/dds-ng/shared/dds-config';
import * as i4 from '@usitsdasdesign/dds-ng/core/focus-handler';
import { FocusHandlerDirective, FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import * as i5 from '@usitsdasdesign/dds-ng/core/theme';
import { ThemeMapEnum, ThemeModule } from '@usitsdasdesign/dds-ng/core/theme';
const _c0 = ["*"];
const _c1 = a0 => ({
  "dds-full-width": a0
});
const _c2 = a0 => ({
  "dds-custom-control_inverse": a0
});
function RadioGroupComponent_Conditional_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 2);
  }
}
function RadioGroupComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 0);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, RadioGroupComponent_Conditional_0_Conditional_2_Template, 1, 0, "span", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(4, _c2, ctx_r0.isInverse));
    i0.ɵɵattribute("id", ctx_r0.id);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("", ctx_r0.title, " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.isRequired ? 2 : -1);
  }
}
const _c3 = ["radio"];
const _c4 = (a0, a1) => ({
  "dds-custom-control_disabled": a0,
  "dds-custom-control_inverse": a1
});
function RadioComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.label, " ");
  }
}
const RadioGroupBase = FormAccessorMixin(ErrorStateMixin(DestroyMixin()));
class RadioGroupComponent extends RadioGroupBase {
  get isFullWidth() {
    return this.width === WidthState.full;
  }
  set value(value) {
    this._value = value;
    if (!this.firstChange) {
      this.setActiveItem(this.value, this.radioItems);
    }
  }
  get value() {
    return this._value;
  }
  constructor(ngControl, parentForm, parentFormGroup, cdr, el, ddsConfig) {
    super();
    this.ngControl = ngControl;
    this.parentForm = parentForm;
    this.parentFormGroup = parentFormGroup;
    this.cdr = cdr;
    this.el = el;
    this.ddsConfig = ddsConfig;
    this._isError = false;
    this.isRequired = false;
    this.errorState = this.ddsConfig.getConfig().errorState;
    this.stateChanged = new EventEmitter();
    this.firstChange = true;
    this.radioItems = [];
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit() {
    super.ngOnInit();
    this.id = generateId();
    this.isError$.pipe(takeUntil(this.destroy)).subscribe(status => this.setRadioError(status));
  }
  ngOnChanges(changes) {
    if (changes.disabled) {
      this.setRadioDisabled();
    }
    if (changes.isRequired) {
      this.isRequired = coerceBooleanProperty(changes.isRequired?.currentValue);
      this.setRadioRequired(this.isRequired);
    }
    if (changes.isInverse) {
      this.setItemsInverse(changes.isInverse?.currentValue);
    }
    if (changes.width) {
      this.setWidth(changes.width.currentValue);
    }
    super.ngOnChanges(changes);
  }
  ngAfterContentInit() {
    if (this.value) {
      this.setActiveItem(this.value, this.radioItems);
    }
    this.setRadioRequired(this.isRequired);
    this.setRadioError(this._isError);
    this.setItemsInverse(this.isInverse);
    this.firstChange = false;
  }
  addRadioItem(radio) {
    this.radioItems.push(radio);
  }
  changeItem(value) {
    if (this.isUncheckable) {
      this.value = this.value === value ? null : value;
    } else {
      this.value = value;
    }
    this.onModelTouched();
    this.onModelChange(this.value);
    this.stateChanged.emit(this.value);
    this.checkError();
  }
  writeValue(value) {
    this.value = value;
    super.writeValue(value);
    this.cdr.markForCheck();
  }
  setDisabledState(disabled) {
    this.disabled = disabled;
    this.setRadioDisabled();
    this.cdr.markForCheck();
  }
  reset() {
    this.changeItem(null);
    this.cdr.markForCheck();
  }
  setRadioDisabled() {
    this.radioItems.forEach(radio => {
      radio.disabled = this.disabled;
      radio.cdr.detectChanges();
    });
  }
  setRadioRequired(isRequired) {
    this.radioItems.forEach(radio => {
      radio.isRequiredGroup = isRequired;
      radio.isFormControlRequired = this._isFormControlRequired;
      radio.cdr.detectChanges();
    });
  }
  setRadioError(isError) {
    this.radioItems.forEach(radio => {
      radio.isErrorGroup = isError;
      radio.cdr.detectChanges();
    });
  }
  setItemsInverse(isInverse) {
    this.radioItems.forEach(radio => {
      radio.setInverse(isInverse);
    });
  }
  setWidth(width) {
    this.width = width;
    this.cdr.detectChanges();
  }
  setActiveItem(value, radioItems) {
    radioItems.forEach(item => {
      item.checked = value === item.value;
      item.stateChanged.emit(item.checked);
    });
  }
  static {
    this.ɵfac = function RadioGroupComponent_Factory(t) {
      return new (t || RadioGroupComponent)(i0.ɵɵdirectiveInject(i1.NgControl, 10), i0.ɵɵdirectiveInject(i1.NgForm, 8), i0.ɵɵdirectiveInject(i1.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.DdsConfigService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: RadioGroupComponent,
      selectors: [["dds-radio-group"]],
      hostAttrs: ["role", "radiogroup"],
      hostVars: 4,
      hostBindings: function RadioGroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("dds-radio-group_error", ctx._isError)("dds-full-width", ctx.isFullWidth);
        }
      },
      inputs: {
        name: "name",
        disabled: "disabled",
        isError: "isError",
        isUncheckable: "isUncheckable",
        isInverse: "isInverse",
        title: "title",
        width: "width",
        isRequired: "isRequired",
        errorState: "errorState",
        value: "value"
      },
      outputs: {
        stateChanged: "stateChanged"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: DDS_FORM_CONTROL_GROUP,
        useExisting: RadioGroupComponent
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 3,
      vars: 5,
      consts: [[1, "dds-custom-control__title", 3, "ngClass"], ["role", "radiogroup", 3, "ngClass"], [1, "dds-required"]],
      template: function RadioGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, RadioGroupComponent_Conditional_0_Template, 3, 6, "span", 0);
          i0.ɵɵelementStart(1, "div", 1);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.title ? 0 : -1);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, ctx.isFullWidth));
          i0.ɵɵattribute("aria-labelledby", ctx.id);
        }
      },
      dependencies: [CommonModule, i3.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioGroupComponent, [{
    type: Component,
    args: [{
      selector: 'dds-radio-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        role: 'radiogroup'
      },
      providers: [{
        provide: DDS_FORM_CONTROL_GROUP,
        useExisting: RadioGroupComponent
      }],
      standalone: true,
      imports: [CommonModule],
      template: "@if (title) {\r\n  <span [ngClass]=\"{\r\n            'dds-custom-control_inverse': isInverse\r\n      }\"\r\n        [attr.id]=\"id\"\r\n        class=\"dds-custom-control__title\">{{title}}\r\n    @if (isRequired) {\r\n      <span class=\"dds-required\"></span>\r\n    }\r\n  </span>\r\n}\r\n<div [attr.aria-labelledby]=\"id\"\r\n     [ngClass]=\"{\r\n            'dds-full-width': isFullWidth\r\n      }\"\r\n     role=\"radiogroup\">\r\n  <ng-content></ng-content>\r\n</div>"
    }]
  }], () => [{
    type: i1.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }]
  }, {
    type: i1.NgForm,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i1.FormGroupDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i2.DdsConfigService
  }], {
    _isError: [{
      type: HostBinding,
      args: ['class.dds-radio-group_error']
    }],
    isFullWidth: [{
      type: HostBinding,
      args: [`class.${AdditionalClass.fullWidth}`]
    }],
    name: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    isError: [{
      type: Input
    }],
    isUncheckable: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    isRequired: [{
      type: Input
    }],
    errorState: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    stateChanged: [{
      type: Output
    }]
  });
})();
const themeClassesMap = {
  [ThemeMapEnum.Themes]: {
    [Themes.green]: 'dds-custom-control_green',
    [Themes.blue]: 'dds-custom-control_blue'
  }
};
class RadioComponent {
  set checked(value) {
    this._checked = value;
    this.cdr.detectChanges();
  }
  get checked() {
    return this._checked;
  }
  set groupName(value) {
    this._groupName = value;
    this.cdr.detectChanges();
  }
  get groupName() {
    return this._groupName;
  }
  constructor(cdr, radioGroup, el, ddsConfig) {
    this.cdr = cdr;
    this.radioGroup = radioGroup;
    this.el = el;
    this.ddsConfig = ddsConfig;
    this.theme = this.ddsConfig.getConfig().theme;
    this.stateChanged = new EventEmitter();
    this.themeConfig = themeClassesMap;
    this.isRequiredGroup = false;
    this.isFormControlRequired = false;
    this.isErrorGroup = false;
  }
  ngOnInit() {
    this.radioGroup.addRadioItem(this);
    this.setValueFromParent();
  }
  handleKeydown(event) {
    if (event.keyCode === KeyCodes.ENTER || event.keyCode === KeyCodes.SPACE) {
      this.radioGroup.changeItem(this.value);
    }
  }
  // Fix double click for FF
  preventEvent(event) {
    if (event.keyCode === KeyCodes.ENTER || event.keyCode === KeyCodes.SPACE) {
      event.preventDefault();
    }
  }
  selectItem() {
    this.radioGroup.changeItem(this.value);
  }
  setValueFromParent() {
    this.groupName = this.radioGroup.name;
    this.disabled ||= this.radioGroup.disabled;
    this.cdr.detectChanges();
  }
  setInverse(isInverse) {
    this.isInverse = isInverse;
    this.cdr.detectChanges();
  }
  static {
    this.ɵfac = function RadioComponent_Factory(t) {
      return new (t || RadioComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(RadioGroupComponent), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.DdsConfigService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: RadioComponent,
      selectors: [["dds-radio"]],
      viewQuery: function RadioComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c3, 5);
          i0.ɵɵviewQuery(FocusHandlerDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.radio = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.focusDir = _t.first);
        }
      },
      hostVars: 2,
      hostBindings: function RadioComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("dds-disabled", ctx.disabled);
        }
      },
      inputs: {
        disabled: "disabled",
        label: "label",
        value: "value",
        customClass: "customClass",
        theme: "theme",
        isInverse: "isInverse"
      },
      outputs: {
        stateChanged: "stateChanged"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: DDS_FORM_CONTROL,
        useExisting: RadioComponent
      }]), i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 20,
      consts: [["radio", ""], [3, "ngClass", "theme", "ddsTheme"], ["role", "radio", "ddsFocus", "", "type", "radio", 1, "dds-custom-control__field", "dds-custom-control__field_radio", 3, "click", "keydown", "keyup", "name", "disabled", "checked", "required", "ddsFocusCheck"], [1, "dds-custom-control__icon", "dds-custom-control__icon_radio"], [1, "dds-custom-control__text"]],
      template: function RadioComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "label", 1)(1, "input", 2, 0);
          i0.ɵɵlistener("click", function RadioComponent_Template_input_click_1_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.selectItem());
          })("keydown", function RadioComponent_Template_input_keydown_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            ctx.handleKeydown($event);
            return i0.ɵɵresetView(ctx.preventEvent($event));
          })("keyup", function RadioComponent_Template_input_keyup_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.preventEvent($event));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelement(3, "span", 3);
          i0.ɵɵtemplate(4, RadioComponent_Conditional_4_Template, 2, 1, "span", 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-custom-control ", ctx.customClass, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(17, _c4, ctx.disabled, ctx.isInverse))("theme", ctx.theme)("ddsTheme", ctx.themeConfig);
          i0.ɵɵadvance();
          i0.ɵɵproperty("name", ctx.groupName)("disabled", ctx.disabled)("checked", ctx.checked)("required", ctx.isRequiredGroup)("ddsFocusCheck", true);
          i0.ɵɵattribute("value", ctx.value)("aria-checked", ctx.checked)("aria-disabled", ctx.disabled)("aria-required", ctx.isRequiredGroup || ctx.isFormControlRequired)("aria-invalid", ctx.isErrorGroup);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.label ? 4 : -1);
        }
      },
      dependencies: [CommonModule, i3.NgClass, FocusHandlerModule, i4.FocusHandlerDirective, ThemeModule, i5.ThemeDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioComponent, [{
    type: Component,
    args: [{
      selector: 'dds-radio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: DDS_FORM_CONTROL,
        useExisting: RadioComponent
      }],
      standalone: true,
      imports: [CommonModule, FocusHandlerModule, ThemeModule],
      template: "<label [ngClass]=\"{\r\n        'dds-custom-control_disabled': disabled,\r\n        'dds-custom-control_inverse': isInverse\r\n       }\"\r\n       [theme]=\"theme\"\r\n       [ddsTheme]=\"themeConfig\"\r\n       class=\"dds-custom-control {{customClass}}\">\r\n  <input [name]=\"groupName\"\r\n         [disabled]=\"disabled\"\r\n         [attr.value]=\"value\"\r\n         [checked]=\"checked\"\r\n         role=\"radio\"\r\n         [attr.aria-checked]=\"checked\"\r\n         [attr.aria-disabled]=\"disabled\"\r\n         [attr.aria-required]=\"isRequiredGroup || isFormControlRequired\"\r\n         [attr.aria-invalid]=\"isErrorGroup\"\r\n         [required]=\"isRequiredGroup\"\r\n         [ddsFocusCheck]=\"true\"\r\n         ddsFocus\r\n         class=\"dds-custom-control__field dds-custom-control__field_radio\"\r\n         type=\"radio\"\r\n         (click)=\"selectItem()\"\r\n         (keydown)=\"handleKeydown($event);preventEvent($event)\"\r\n         (keyup)=\"preventEvent($event)\"\r\n         #radio />\r\n  <span class=\"dds-custom-control__icon dds-custom-control__icon_radio\"></span>\r\n\r\n  @if (label) {\r\n    <span class=\"dds-custom-control__text\">\r\n      {{ label }}\r\n    </span>\r\n  }\r\n</label>"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: RadioGroupComponent
  }, {
    type: i0.ElementRef
  }, {
    type: i2.DdsConfigService
  }], {
    disabled: [{
      type: HostBinding,
      args: [`class.${AdditionalClass.disabled}`]
    }, {
      type: Input
    }],
    radio: [{
      type: ViewChild,
      args: ['radio']
    }],
    focusDir: [{
      type: ViewChild,
      args: [FocusHandlerDirective]
    }],
    label: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    stateChanged: [{
      type: Output
    }]
  });
})();
class RadioModule {
  static {
    this.ɵfac = function RadioModule_Factory(t) {
      return new (t || RadioModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: RadioModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [RadioComponent, RadioGroupComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [RadioComponent, RadioGroupComponent],
      exports: [RadioComponent, RadioGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { RadioComponent, RadioGroupComponent, RadioModule };
