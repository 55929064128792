import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class JwtService {

    decodeToken(tokenType?: string) : decodeToken {
        let tokenStr = this.getMsalToken(tokenType);
      
        return JSON.parse(atob(tokenStr.split('.')[1]));
    }

    hasToken(tokenType?: string) {
      return this.getMsalToken(tokenType);
    }

    private getMsalToken(tokenType = 'idtoken') {
        let msalToken = "";
        Object.entries(localStorage).forEach(([key, value]) => {
          if (key.includes(`login.windows.net-${tokenType}`)) {
            const msalData = JSON.parse(value);
            msalToken = msalData?.secret;
          }
        });
        return msalToken;
    }
}

export interface decodeToken {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  oid: string;
  preferred_username: string;
  roles: string[];
}