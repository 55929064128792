import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import moment from 'moment';
import { HomeService } from '../../home.service';
import { DashboardCard } from '../../../../shared/model/model';

@Component({
	selector: 'app-cards',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './cards.component.html',
	styleUrl: './cards.component.sass'
})
export class CardsComponent implements OnInit {
	DSTCard!: DashboardCard
	DSTCardLoading: boolean = true
	MLCardLoading = true;
	MLCard!: DashboardCard
	constructor(
		private homeService: HomeService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.getDSTCardData()
		this.getMLCardData()
	}

	getDSTCardData(): void {
		this.homeService.getDSTCardData().subscribe({
			next: (res: DashboardCard) => this.DSTCard = res,
			error: (e: any) => this.DSTCardLoading = false,
			complete: () => this.DSTCardLoading = false,
		})
	}

	getMLCardData(): void {
		this.homeService.getMLCardData().subscribe({
			next: (res: DashboardCard) => this.MLCard = res,
			error: (e: any) => this.MLCardLoading = false,
			complete: () => this.MLCardLoading = false
		})
	}

	navigateTo(link: string): void {
		this.router.navigate([link]);
	}
}
