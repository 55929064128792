import { Component, EventEmitter, Output } from '@angular/core';
import { SpinnerComponent as DDSSpinnerComponent } from '@usitsdasdesign/dds-ng/spinner';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [DDSSpinnerComponent],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.sass'
})
export class SpinnerComponent {
  @Output() onStop: EventEmitter<any> = new EventEmitter<any>();

  stop(): void {
    this.onStop.emit();
  }
}
