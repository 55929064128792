import { Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { AuditLogComponent } from './views/audit-log/audit-log.component';
import { GeneralSettingsComponent } from './views/general-settings/general-settings.component';
import { MarketLibraryComponent } from './views/market-library/market-library.component';
import { SkillTaxonomyComponent} from './views/skill-taxonomy/skill-taxonomy.component';
import {PendingChangesComponent} from './views/pending-changes/pending-changes.component';
import {UserManagementComponent} from './views/user-management/user-management.component';
import {SkillsComponent} from './views/skills/skills.component';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { roleAuthGuard } from './shared/guards/role-auth.guard';

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        
    },
    {
        path:'skill-taxonomy',
        component:SkillTaxonomyComponent,
        canActivate: [roleAuthGuard],
    },
    {
        path:'market-library',
        component:MarketLibraryComponent,
        canActivate: [roleAuthGuard],
    },
    {
        path:'pending-changes',
        component:PendingChangesComponent,
        canActivate: [roleAuthGuard],
    },
    {
        path:'audit-log',
        component: AuditLogComponent,
        canActivate: [roleAuthGuard],
    },
    {
        path:'user-management',
        component:UserManagementComponent,
        canActivate: [roleAuthGuard],
   },
    {
        path:'skills', 
        component:SkillsComponent,
        canActivate: [roleAuthGuard],
    },
    {
        path:'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path:'general-settings',
        component:GeneralSettingsComponent,
        canActivate: [roleAuthGuard],
    }

];
