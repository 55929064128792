import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizeInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();
      const msalToken = this.getMsalToken();
      if (msalToken) {
        let headers = req.clone({
          //headers: req.headers.set('Authorization', 'Bearer ' + msalToken),
        }).headers;
        return next.handle(req.clone({ headers }));
      }
      return next.handle(req);
  }
  
  getMsalToken() {
    let msalToken = null;
    Object.entries(localStorage).forEach(([key, value]) => {
      if (key.includes('login.windows.net-idtoken')) {
        const msalData = JSON.parse(value);
        msalToken = msalData?.secret;
      }
    });
    return msalToken;
  }
}