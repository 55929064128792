import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpClient,
  HttpHeaders
} from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MsalService } from '@azure/msal-angular';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { Themes } from '@usitsdasdesign/dds-ng/shared';
export interface IResponseMessage{
  title:string;
  body:string; 
}

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

  options: ToastOptions = {
    title: '',
    message: '',
    action: { title: 'Undo', actionFunc: ($event: Event) => console.log('Some Undo logic.') },
    lifeTime: 3000,
    position: 'top-center',
    closeBtnIcon: 'dds-icon_close',
    isCloseIcon: false,
    customClass: '',
    isError: true,
    isLoading: false,
    limit: 5,
    theme: Themes.green
  };

  constructor(
    private msalService: MsalService,
    private toastService: ToastService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.handleReq(req, next);
  }

  handleReq(req: HttpRequest<any>, next: HttpHandler) {
    if(!req.headers.has('InterceptorSkipHeader')) {
      let headers: HttpHeaders = req.clone({
        headers: req.headers
          .set("Content-Type", "application/json")
          .set("Accept", "application/json")
      }).headers;
      return this.returnReq(next, req.clone({ headers }));
    }

    let headers_clone = req.headers.delete('InterceptorSkipHeader')
    return this.returnReq(next, req.clone({
      headers: headers_clone
    }));
  }

  returnReq(next: any, changedReq: any): Observable<HttpEvent<any>> {
    return next.handle(changedReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this._handleSuccessResponse(event, changedReq.method);
          }
        },
        (err: HttpErrorResponse) => {
          this._handleErrorResponse(err);
        }
      )
    );
  }

  _handleSuccessResponse(response: any, httpMethod:string) {
    if (httpMethod === 'GET' || !response.body) return;

    const resp = response.body;

    //Check if the response is an IResponseMessage until messaging from backend is standarized. The first case will be the standard.
    let isIResponseMessage = resp.message && typeof resp.message.title == 'string' && typeof resp.message.body == 'string';
    
    if (isIResponseMessage) {
      this.options.title = resp.message.title;
      this.options.message = resp.message.body;
      this.options.isError = false;
      this.options.action!.title = 'x';
      this.options.lifeTime = 5000;
      this.toastService.createToast(this.options);
    } else if (resp.message && resp.message.includes("-")) {
      const messageRe = resp.message.split("-");
      this.options.title = messageRe[0];
      this.options.message = messageRe[1];
      this.options.isError = false;
      this.options.lifeTime = 5000;
      this.options.action!.title = 'x';
      this.toastService.createToast(this.options);
    }

    return resp;
  }

  async _handleErrorResponse(errorResponse: any) {
    this.options.isError = true;
    switch (errorResponse.status) {
      //CHange 400 to default.
      case 400:
        if(errorResponse.error) {
          this.options.title = 'An Error has occured';
          this.options.message = 'Please, review the data entered';
          this.options.lifeTime = 3000;
          this.options.action!.title = 'x';
          this.toastService.createToast(this.options);
        }
        break;

      case 401:
      case 403:
        if (errorResponse.status === 401 || errorResponse.status === 403) {
          if (this.isTokenExpired()) {
            // token expired
            console.log("token expired");
            await this.msalService.instance.loginRedirect();
          } else {
            // token valid
            console.log("is not token expired")
          }
        }
        break;
        case 500: 
          this.options.title = 'An Error has occured';
          this.options.message = 'Please contact your administrator';
          this.options.isError = true;
          
          this.toastService.createToast(this.options);
          break;
      case 0: this.options.title = 'No connection';
        this.options.message = 'The server cannot be reached';
        this.options.isError = true;
        this.options.lifeTime = 3000;
        this.options.action!.title = 'x';
        this.toastService.createToast(this.options);
        break
    }

    return errorResponse;
  }

   isTokenExpired() {
    const expiry = (JSON.parse(atob(this.getMsalToken().split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  private getMsalToken() {
    let msalToken = "";
    Object.entries(localStorage).forEach(([key, value]) => {
      if (key.includes('login.windows.net-idtoken')) {
        const msalData = JSON.parse(value);
        msalToken = msalData?.secret;
      }
    });
    return msalToken;
}
}
