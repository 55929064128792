<div class="dds-row">
  <div class="dds-col">
    <div class="section-title">
      Pending Changes
      <dds-button [routerLink]="['pending-changes']" theme="dark" size="sm" role="button" ariaLabel="See More">See
        More</dds-button>
    </div>
    <app-table [paginated]="false" [sorting]="false" #table [columns]="columns" [isLoading]="isLoading"
      [totalSize]="totalSize" (load)="getLatest()">
      @if ( latest.length === 0) {
      <tr>
        <td [attr.colspan]="columns.length" class="dds-empty-state-custom">
          No pending changes found
        </td>
      </tr>
      }
      @for (latest of latest; track $index) {
      <tr class="dds-data-table__row">
        @for (column of columns; track column.name) {
        <td [class]=" column.name === 'request' ? ' dds-data-table__cell data-table-request-cell' : 'dds-data-table__cell'">
          {{ latest[column.name] }}
        </td>
        }
      </tr>
      }
    </app-table>
  </div>
</div>
