import { Component, inject, Input, OnInit } from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { PopupService } from '../../../shared/services/popup.service';
import { TextareaComponent } from '@usitsdasdesign/dds-ng/textarea';
import { ExtThemes, ButtonKind } from '@usitsdasdesign/dds-ng/shared';

type ButtonOptions = {
  theme: ExtThemes | string;
  kind: ButtonKind | string;
  icon: string;
  text: string;
}

export type ModalCommentButtons = { cancel: Partial<ButtonOptions> | string, accept: Partial<ButtonOptions> | string };

@Component({
  selector: 'app-modal-comment',
  standalone: true,
  imports: [ButtonComponent, TextareaComponent],
  templateUrl: './modal-comment.component.html',
  styleUrl: './modal-comment.component.sass'
})
export class ModalCommentComponent implements OnInit {
  private popups: PopupService = inject(PopupService);
  @Input() public message: string = '';
  @Input() public placeholder?: string;
  @Input() public buttons?: ModalCommentButtons;
  @Input() public isOptional: boolean = false;

  public cancel: ButtonOptions = { theme: 'green', kind: 'silent', icon: 'dds-icon_close__l__stroke', text: 'Cancel' };
  public accept: ButtonOptions = { theme: 'green', kind: 'primary-loud', icon: 'dds-icon_send__l__stroke', text: 'Accept' };

  public comment: string = '';

  ngOnInit(): void {
    if (!this.buttons) return;

    if (typeof this.buttons.cancel === 'string')
      this.cancel.text = this.buttons.cancel;
    else
      this.cancel = { ...this.cancel, ...this.buttons.cancel };

    if (typeof this.buttons.accept === 'string')
      this.accept.text = this.buttons.accept;
    else
      this.accept = { ...this.accept, ...this.buttons.accept };
  }

  close(data?: any): void {
    this.popups.modals.close(data);
  }
}
