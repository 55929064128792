import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@usitsdasdesign/dds-ng/core/theme';
import { ThemeMapEnum, ThemeModule } from '@usitsdasdesign/dds-ng/core/theme';
import { Size } from '@usitsdasdesign/dds-ng/shared';
const _c0 = ["*"];
const _c1 = (a0, a1) => ({
  "dds-empty-state_vertical": a0,
  "dds-empty-state_inverse": a1
});
function EmptyStateComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1, "Your image here");
    i0.ɵɵelementEnd();
  }
}
function EmptyStateComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("src", ctx_r0.imageSrc, i0.ɵɵsanitizeUrl);
  }
}
function EmptyStateComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h4", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.title);
  }
}
function EmptyStateComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.description);
  }
}
const themeClassesMap = {
  [ThemeMapEnum.Size]: {
    [Size.lg]: 'dds-empty-state_lg',
    [Size.md]: 'dds-empty-state_md'
  }
};
class EmptyStateComponent {
  constructor() {
    this.themeConfig = themeClassesMap;
  }
  static {
    this.ɵfac = function EmptyStateComponent_Factory(t) {
      return new (t || EmptyStateComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EmptyStateComponent,
      selectors: [["dds-empty-state"]],
      inputs: {
        size: "size",
        customClass: "customClass",
        title: "title",
        description: "description",
        imageSrc: "imageSrc",
        isVertical: "isVertical",
        isInverse: "isInverse"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 9,
      vars: 13,
      consts: [[3, "ddsTheme", "size", "ngClass"], [1, "dds-empty-state__wrap"], [1, "dds-empty-state__image"], ["alt", "Empty state", 3, "src"], [1, "dds-empty-state__content"], [1, "dds-empty-state__title"], [1, "dds-empty-state__text"]],
      template: function EmptyStateComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2);
          i0.ɵɵtemplate(3, EmptyStateComponent_Conditional_3_Template, 2, 0, "span")(4, EmptyStateComponent_Conditional_4_Template, 1, 1, "img", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div", 4);
          i0.ɵɵtemplate(6, EmptyStateComponent_Conditional_6_Template, 2, 1, "h4", 5)(7, EmptyStateComponent_Conditional_7_Template, 2, 1, "p", 6);
          i0.ɵɵprojection(8);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-empty-state ", ctx.customClass, "");
          i0.ɵɵproperty("ddsTheme", ctx.themeConfig)("size", ctx.size)("ngClass", i0.ɵɵpureFunction2(10, _c1, ctx.isVertical, ctx.isInverse));
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(!ctx.imageSrc ? 3 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.imageSrc ? 4 : -1);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.title ? 6 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.description ? 7 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgClass, ThemeModule, i2.ThemeDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmptyStateComponent, [{
    type: Component,
    args: [{
      selector: 'dds-empty-state',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [CommonModule, ThemeModule],
      template: "<div [ddsTheme]=\"themeConfig\"\r\n     [size]=\"size\"\r\n     [ngClass]=\"{\r\n     'dds-empty-state_vertical': isVertical,\r\n     'dds-empty-state_inverse': isInverse\r\n     }\"\r\n     class=\"dds-empty-state {{customClass}}\">\r\n  <div class=\"dds-empty-state__wrap\">\r\n    <div class=\"dds-empty-state__image\">\r\n      @if (!imageSrc) {<span>Your image here</span>}\r\n      @if (imageSrc) {\r\n        <img [src]=\"imageSrc\"\r\n             alt=\"Empty state\">\r\n      }\r\n    </div>\r\n    <div class=\"dds-empty-state__content\">\r\n      @if (title) {\r\n        <h4 class=\"dds-empty-state__title\">{{title}}</h4>}\r\n      @if (description) {\r\n        <p class=\"dds-empty-state__text\">{{description}}</p>}\r\n      <ng-content></ng-content>\r\n    </div>\r\n  </div>\r\n</div>"
    }]
  }], null, {
    size: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    description: [{
      type: Input
    }],
    imageSrc: [{
      type: Input
    }],
    isVertical: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }]
  });
})();
class EmptyStateModule {
  static {
    this.ɵfac = function EmptyStateModule_Factory(t) {
      return new (t || EmptyStateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: EmptyStateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [EmptyStateComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmptyStateModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [EmptyStateComponent],
      exports: [EmptyStateComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { EmptyStateComponent, EmptyStateModule };
