import { Component, Input } from'@angular/core';
import { User } from'../../../shared/model/user';
import { UserService } from'../../../shared/services/user.service';
import { PopupService } from'../../../shared/services/popup.service';
import { firstValueFrom } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-modify-modal',
    standalone: true,
    imports: [FormsModule],
    templateUrl: './modify-modal.component.html',
    styleUrls: ['./modify-modal.component.sass']
})
export class ModifyUserComponent {
  @Input() public user: User = new User;
  
  public availableRoles: { id: number, name: string }[] = [
    { id: 1, name: 'Admin Approver' },
    { id: 2, name: 'Admin User' }
  ];
 
  constructor(
    private userService: UserService,
    private popups: PopupService
  ) {}
  
  async save(): Promise<void> {
    const spinner = this.popups.spinner();
    try {
      await firstValueFrom(this.userService.updateUserRole(this.user.id, this.user.role_id));
      this.popups.modals.close(true);
    } finally {
      spinner.stop();
    }
  }
  
  closeModal() {
    this.popups.modals.close();
  }
}
