<div class="form-wrapper">
  <h3>Skill Information</h3>
  <hr />
  @if(formPatched==true){
  <form [formGroup]="pendingChangeForm">
    <dds-input class="dds-col" formControlName="pchName" id="pchName" label="Name" labelPosition="internal"
      placeholder="Enter skill name" [maxLength]="50" [isRequired]="true" [isError]="
                pendingChangeForm.controls['pchName'].invalid &&
                (pendingChangeForm.controls['pchName'].dirty ||
                pendingChangeForm.controls['pchName'].touched)" [errorState]="'focusOut'" 
               errorMessage="{{skillNameValdationError}}" ariaLabel="Name" description="{{checkingSkillName}}"
               descriptionType="text" [maxLength]="50">
    </dds-input>
    <div class="two-fields">
      @if(fullPCData.skillId){
      <dds-input class="dds-col" id="skillID" label="Skill ID" labelPosition="internal" ariaLabel="Skill ID"
        value="{{fullPCData.skillId}}" [disabled]="true">
      </dds-input>
      }
      @if(fullPCData.skillLightCastId){
      <dds-input class="dds-col" id="skillLightCastId" label="LightCast ID" labelPosition="internal"
        ariaLabel="LightCast ID" value="{{fullPCData.skillLightCastId}}" [disabled]="true" [class.margin]="fullPCData.skillId">
      </dds-input>
      }
    </div>

    <dds-textarea class="dds-col" formControlName="pchDescription" id="pchDescription" label="Description"
      labelPosition="internal" ariaLabel="Skill Description" [minHeight]="150" placeholder="Enter skill description"
      [isError]="pendingChangeForm.controls['pchDescription'].invalid && (pendingChangeForm.controls['pchDescription'].dirty || pendingChangeForm.controls['pchDescription'].touched)"
      [errorState]="'focusOut'" [isRequired]="true" errorMessage="Is required"></dds-textarea>

    <div class="two-fields">
      <dds-input class="dds-col" formControlName="pchLink" id="pchLink" label="Description Link"
        labelPosition="internal" placeholder="Enter skill description link" [isError]="
                                            pendingChangeForm.controls['pchLink'].invalid &&
                                            (pendingChangeForm.controls['pchLink'].dirty ||
                                            pendingChangeForm.controls['pchLink'].touched)
                            " [errorState]="'focusOut'" errorMessage="http://domain.ext or https://domain.ext"
        ariaLabel="Description Link">
      </dds-input>


      @if(pendingChangeForm.controls['pchLink'].value && !pendingChangeForm.controls['pchLink'].invalid
      && pendingChangeForm.controls['pchLink'].value.includes('http')){
      <div class="dds-col-1 link-icon-container">
        <a href="{{ pendingChangeForm.controls['pchLink'].value }}" target="_blank"
          aria-label="click to navigate to description link">
          <span class="link-icon dds-icon dds-icon_external_link__l__stroke"></span>
        </a>
      </div>
      }

    </div>

    <dds-select class="dds-col" formControlName="pchCategoryId" [isRequired]="true" [errorMessage]="'Is required'"
      [isError]="
              pendingChangeForm.controls['pchCategoryId'].invalid &&
              (pendingChangeForm.controls['pchCategoryId'].dirty ||
              pendingChangeForm.controls['pchCategoryId'].touched)
            " [errorState]="'focusOut'" errorMessage="Is required" [list]="categories" [stickerMaxHeight]="250"
      [stickerIsDynamic]="true" label="Select Category" placeholder="Select category" labelPosition="internal"
      (valueChanged)="onCategoryChanges($event)" ariaLabel="Select Category">
    </dds-select>

    <dds-select class="dds-col" formControlName="pchSubCategoryId" [isRequired]="true" [errorMessage]="'Is required'"
      [isError]="
              pendingChangeForm.controls['pchSubCategoryId'].invalid &&
              (pendingChangeForm.controls['pchSubCategoryId'].dirty ||
              pendingChangeForm.controls['pchSubCategoryId'].touched)
            " [errorState]="'focusOut'" errorMessage="Is required" [list]="filteredSubCategories"
      [stickerMaxHeight]="250" [stickerIsDynamic]="true" label="Select Sub-Category" placeholder="Select sub-category"
      labelPosition="internal" ariaLabel="Select Sub-Category">
    </dds-select>

    <div class="two-fields">
      <dds-input class="dds-col" id="catalog" label="Catalog" labelPosition="internal" ariaLabel="Catalog"
        value="{{fullPCData.sourceCatalog}}" [disabled]="true">
      </dds-input>
      <dds-input class="dds-col margin" id="dsTstatus" label="DST Status" labelPosition="internal" ariaLabel="DST Status"
        value="{{fullPCData.dsTstatus}}" [disabled]="true">
      </dds-input>
    </div>

    <dds-select class="dds-col" formControlName="pchTypeId"
      [isError]="(pendingChangeForm.controls['pchTypeId'].invalid && (pendingChangeForm.controls['pchTypeId'].dirty || pendingChangeForm.controls['pchTypeId'].touched))"
      [isRequired]="true" [errorState]="'focusOut'" errorMessage="Is required" [list]="skillTypesList" label="Type"
      placeholder="Select skill type." labelPosition="internal" [stickerMaxHeight]="250" [stickerIsDynamic]="true"
      ariaLabel="Select skill type">
    </dds-select>


    
  <app-tag-input #skillTags formControlName="pchTags" [options]="availableTags"
    label="Skill Tags (Type to search or create)" labelPosition="internal" placeholder=""
    errorMessage="Please enter a valid tag"
    (newTag)="onNewTag($event)" />

  </form>
}
  <h3>Additional Information</h3>
  <hr />
  @if(fullPCData.pchRequestor){
  <dds-input class="dds-col" id="changeRequestor" label="Requestor" labelPosition="internal" ariaLabel="Requestor"
    value="{{fullPCData.pchRequestor}}" [disabled]="true">
  </dds-input>
  }

  <dds-textarea class="dds-col" id="uuserComment" [disabled]="true" label="User Comment" labelPosition="internal"
    ariaLabel="User Comment" [minHeight]="150" value="{{fullPCData.skillRequestDescription}}"></dds-textarea>
  @if (isApprover) {
  <div class="footer-buttons">
    <dds-button theme="dark" kind="primary-loud" role="onSubmit" ariaLabel="Decline
                Changes" icon="dds-icon_delete__l__stroke" [disabled]="pendingChangeForm.invalid"
      (clicked)="onDecline()">
      {{buttonsLabels.decline}}

    </dds-button>
    <dds-button theme="dark" kind="primary-loud" role="onSubmit" ariaLabel="Approve
                Changes" icon="dds-icon_send__l__stroke" [disabled]="pendingChangeForm.invalid"
      (clicked)="onSaveAndApprove()">
      {{buttonsLabels.approve}}
    </dds-button>
  </div>
  }
  
