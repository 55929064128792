import * as i0 from '@angular/core';
import { Injectable, LOCALE_ID, Pipe, Inject, Optional, SkipSelf, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const EN = {
  DATEPICKER: {
    RESPONSIVE: {
      APPLY_BTN_TEXT: 'Apply'
    },
    A11Y: {
      CALENDAR_BTN_ARIA_LABEL: 'Choose date',
      CALENDAR_POPUP_ARIA_LABEL: 'To select specific date use navigation arrows',
      PREV_YEAR_BTN_ARIA_LABEL: 'Previous year',
      NEXT_YEAR_BTN_ARIA_LABEL: 'Next year',
      YEAR_VALUE_ARIA_LABEL: 'year'
    }
  },
  DATERANGEPICKER: {
    RESPONSIVE: {
      APPLY_BTN_TEXT: 'Apply'
    },
    A11Y: {
      CALENDAR_BTN_ARIA_LABEL: 'Choose date',
      CALENDAR_POPUP_ARIA_LABEL: 'To select specific date use navigation arrows',
      CALENDAR_START_ARIA_LABEL: 'Choose start year, month',
      CALENDAR_END_ARIA_LABEL: 'Choose end year, month'
    }
  },
  CONTEXT_MENU: {
    A11Y: {
      CLOSE_BTN_ARIA_LABEL: 'Close'
    }
  },
  TIMEPICKER: {
    A11Y: {
      ARROW_DOWN_ARIA_LABEL: 'Arrow Down',
      ARROW_UP_ARIA_LABEL: 'Arrow Up',
      OPEN_POPUP_ARIA_LABEL: 'Choose time',
      POPUP_ARIA_LABEL: 'Choose hour, minutes'
    },
    RESPONSIVE: {
      APPLY_BTN_TEXT: 'Apply'
    }
  },
  BREADCRUMBS: {
    A11Y: {
      ARIA_LABEL: 'breadcrumbs',
      COLLAPSED_BTN_ARIA_LABEL: 'Dropdown'
    }
  },
  COLORPICKER: {
    CUSTOM_COLOR_PLACEHOLDER_TEXT: 'Enter color',
    HEX_TEXT: 'Hex',
    NO_COLOR_TEXT: 'No color',
    AUTOMATIC_TEXT: 'Automatic',
    RECENT_COLOR_TEXT: 'Recent custom colors',
    APPLE_BTN_TEXT: 'Apply',
    A11Y: {
      ARIA_LABEL_CUSTOM_COLOR_FIELD: 'Custom color field',
      ARIA_LABEL_COLOR_BTN: 'Select color',
      ARIA_LABEL_ENTER_COLOR: 'Enter color button',
      ARIA_LABEL_NO_COLOR: 'No color',
      ARIA_LABEL_APPLY_BTN: 'Apply color',
      INVALID_COLOR_TEXT: 'Invalid color',
      VALID_COLOR_TEXT: 'Entered valid color'
    }
  },
  FILTER: {
    SORTING_TITLE: 'Sorting',
    SORTING_ASCENDING_TEXT: 'Sort A–Z',
    SORTING_DESCENDING_TEXT: 'Sort Z–A',
    FILTER_TITLE: 'Filter',
    CANCEL_BTN_TEXT: 'Cancel',
    APPLY_BTN_TEXT: 'Apply',
    CHECKBOX_LABEL: 'All'
  },
  INPUT: {
    MAX_LIMIT_TEXT: 'Characters limit {{counter}} symbols'
  },
  NUMBER_INPUT: {
    A11Y: {
      UP_ARROW_ARIA_LABEL: 'Arrow up',
      DOWN_ARROW_ARIA_LABEL: 'Arrow down'
    }
  },
  SUGGESTION_TAGS_INPUT: {
    A11Y: {
      POPUP_ARIA_LABEL: 'Context menu for Input with suggestions'
    }
  },
  SELECT: {
    A11Y: {
      POPUP_ARIA_LABEL: 'Context menu for Select"'
    }
  },
  MULTISELECT: {
    A11Y: {
      POPUP_ARIA_LABEL: 'Context menu for Multi select'
    },
    RESPONSIVE: {
      CLEAR_BTN_TEXT: 'Clear selection',
      APPLY_BTN_TEXT: 'Apply'
    }
  },
  PROGRESS: {
    A11Y: {
      ARIA_LABEL: 'Progress'
    }
  },
  PAGER: {
    A11Y: {
      INPUT_ARIA_LABEL: 'Page number'
    }
  },
  PAGINATION: {
    PREV_BTN_TEXT: 'Previous',
    NEXT_BTN_TEXT: 'Next',
    A11Y: {
      PREV_BTN_ARIA_LABEL: 'Navigate to previous page',
      NEXT_BTN_ARIA_LABEL: 'Navigate to next page',
      BTN_ARIA_LABEL: 'Page'
    },
    RESPONSIVE: {
      PAGES_TEXT: 'Page {{page}} of '
    }
  },
  RICH_TEXT_EDITOR: {
    A11Y: {
      TOOLBAR_ARIA_LABEL: 'Text Formatting',
      COLLAPSED_BTN_ARIA_LABEL: 'More actions',
      INPUT_LINK_ARIA_LABEL: 'Enter URL',
      CANCEL_LINK_LABEL: 'Cancel',
      APPLY_LINK_LABEL: 'Apply',
      EDIT_LINK_LABEL: 'Edit link',
      UNLINK_LINK_LABEL: 'Unlink',
      POPUP_ARIA_LABEL: 'Context menu for Rich Text Editor'
    }
  },
  SPINNER: {
    A11Y: {
      ARIA_LABEL: 'Loading'
    }
  },
  TAG: {
    A11Y: {
      CLOSE_BTN_ARIA_LABEL: 'Delete'
    }
  },
  SEARCH: {
    A11Y: {
      CLEAR_BTN_ARIA_LABEL: 'Clear search input'
    }
  },
  TEXTAREA: {
    MAX_LIMIT_TEXT: 'Characters limit {{counter}} symbols'
  },
  WIZARD: {
    A11Y: {
      ARIA_LABEL: 'Progress'
    }
  },
  TABS: {
    A11Y: {
      POPUP_ARIA_LABEL: 'Context menu for Tabs'
    }
  },
  UPLOADER: {
    DRAG_AND_DROP_TEXT: 'Drag and Drop here or',
    REFRESH_BTN_TEXT: 'Try again',
    ERROR_MAX_SIZE: 'Maximum upload file size',
    MAX_FILES_ERROR_TEXT: 'Maximum number of files exceeded',
    SPECIAL_SYMBOLS_ERROR_TEXT: 'Please, rename the file. The name must not contain special symbols',
    FILE_TYPE_ERROR_TEXT: 'File type is not supported',
    NO_FILE_TYPE_ERROR_TEXT: 'File has no file type',
    MAX_TOTAL_SIZE_ERROR_TEXT: 'File upload error. The total file size must not exceed {{number}} MB',
    NAME_LENGTH_ERROR_TEXT: 'Please, rename the file. The name length must not exceed {{number}} characters',
    DUPLICATE_ERROR_TEXT: 'It is impossible to upload the same file "{{filename}}"',
    A11Y: {
      DOWNLOAD_ARIA_LABEL: 'Download the file',
      REMOVE_ARIA_LABEL: 'Remove the file'
    }
  },
  HEADER: {
    A11Y: {
      HEADER_ARIA_LABEL: 'Application header navigation panel',
      ADDITIONAL_NAV_ARIA_LABEL: 'User quick links',
      BTN_NAV_OPEN_ARIA_LABEL: 'Open navigation',
      BTN_NAV_CLOSE_ARIA_LABEL: 'Close navigation',
      BTN_SEARCH_OPEN_ARIA_LABEL: 'Open search',
      BTN_ADDITIONAL_NAV_OPEN_ARIA_LABEL: 'Show all links'
    }
  },
  PROFILE: {
    A11Y: {
      CONTENT_ARIA_LABEL: 'User settings'
    }
  },
  LIST: {
    A11Y: {
      LIST_ARIA_LABEL: 'List of items',
      LIST_ITEM_BTN_ARIA_LABEL: 'List item'
    }
  },
  LISTBOX: {
    EMPTY_STATE_TEXT: 'Add item here',
    A11Y: {
      SOURCE_AREA_ARIA_LABEL: 'Area source',
      DESTINATION_AREA_ARIA_LABEL: 'Area destination',
      ADD_BTN_ARIA_LABEL: 'Add',
      REMOVE_BTN_ARIA_LABEL: 'Remove'
    }
  }
};
const merge = (target, ...objects) => {
  for (const object of objects) {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (object[key] instanceof Object) {
          if (!target[key]) {
            target[key] = {};
          }
          target[key] = merge(target[key], object[key]);
        } else {
          target[key] = object[key];
        }
      }
    }
  }
  return target;
};
class I18nService {
  constructor() {
    this.translations = new BehaviorSubject(EN);
    this.locale = new BehaviorSubject('en');
  }
  setLocale(language, strings) {
    this.locale.next(language);
    if (strings) {
      this.set(strings);
    }
  }
  getLocale() {
    return this.locale.value;
  }
  getLocaleObs() {
    return this.locale.asObservable();
  }
  getTranslation() {
    return this.translations.value;
  }
  getTranslationObs() {
    return this.translations.asObservable();
  }
  joinByValue(value, separator, string) {
    const splitedString = this.splitByValue(separator, string);
    return `${splitedString[0]} ${value} ${splitedString[1]}`;
  }
  splitByValue(separator, string) {
    return string.split(separator);
  }
  set(strings) {
    this.translations.next(merge({}, EN, strings));
  }
  static {
    this.ɵfac = function I18nService_Factory(t) {
      return new (t || I18nService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: I18nService,
      factory: I18nService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(I18nService, [{
    type: Injectable
  }], null, null);
})();
class I18nDatePipe {
  constructor(locale, i18n) {
    this.locale = locale;
    this.i18n = i18n;
    this.destroy = new Subject();
    this.i18n.getLocaleObs().pipe(takeUntil(this.destroy)).subscribe(lang => {
      this.localeByService = lang;
      this.cache = null;
    });
  }
  transform(value, format, timezone, locale) {
    this.cache = new DatePipe(this.locale).transform(value, format, timezone, locale ? locale : this.localeByService);
    return this.cache;
  }
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
  static {
    this.ɵfac = function I18nDatePipe_Factory(t) {
      return new (t || I18nDatePipe)(i0.ɵɵdirectiveInject(LOCALE_ID, 16), i0.ɵɵdirectiveInject(I18nService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "i18nDate",
      type: I18nDatePipe,
      pure: false,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(I18nDatePipe, [{
    type: Pipe,
    args: [{
      name: 'i18nDate',
      pure: false,
      standalone: true
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LOCALE_ID]
    }]
  }, {
    type: I18nService
  }], null);
})();
function I18N_SERVICE_PROVIDER_FACTORY(parentService) {
  return parentService || new I18nService();
}
const I18N_SERVICE_PROVIDER = {
  provide: I18nService,
  deps: [[new Optional(), new SkipSelf(), I18nService]],
  useFactory: I18N_SERVICE_PROVIDER_FACTORY
};
class I18nModule {
  static {
    this.ɵfac = function I18nModule_Factory(t) {
      return new (t || I18nModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: I18nModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [I18nService, I18N_SERVICE_PROVIDER]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(I18nModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [I18nDatePipe],
      exports: [I18nDatePipe],
      providers: [I18nService, I18N_SERVICE_PROVIDER]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { EN, I18N_SERVICE_PROVIDER, I18N_SERVICE_PROVIDER_FACTORY, I18nDatePipe, I18nModule, I18nService };
