import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { DataExportParams, ExportTypes } from '../model/model';
import { fileDownLoad } from '../utils/utils';
import { FileTypes } from '../enums/enums';
import { ToastService } from '@usitsdasdesign/dds-ng/toast';
import moment from 'moment';
import { getSettings } from '../utils/settingsLoader';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  settings = getSettings();
  private baseURLs: ExportTypes = {
    marketLibrary: `${this.settings.apiUrl}/Skill/Export`,
    skillTaxonomy: `${this.settings.apiUrl}/Skill/SkillTaxonomy/Export`
  }
  
  private fileNames: ExportTypes = {
    marketLibrary: 'MarketLIbrary',
    skillTaxonomy: 'SkillTaxonomy'
  }

  constructor(
    private _http: HttpClient,
    private _toastService: ToastService
  ) { }

  exportSkillTaxonomy(exportParams: DataExportParams): Observable<any> {
    return this.export(exportParams, 'skillTaxonomy');
  }
  
  exportMarketLibrary(exportParams: DataExportParams): Observable<any> {
    return this.export(exportParams, 'marketLibrary');
  }

  private export(exportParams: DataExportParams, exportType: keyof ExportTypes): Observable<any> { //csv, xlsx
    const params = new URLSearchParams();
    const headers = new HttpHeaders();
    headers.append('InterceptorSkipHeader', 'true');

    if (exportParams.PageSize)
      params.append('PageSize', exportParams.PageSize.toString());
    
    if (exportParams.PageNumber)
      params.append('PageNumber', (exportParams.PageNumber - 1).toString());
    
    if (exportParams.OrderBy) {
      params.append('OrderBy', exportParams.OrderBy);
      params.append('IsAscending', exportParams.IsAscending ? 'true' : 'false');
    }

    if (exportParams.Search)
      params.append('Search', exportParams.Search);

    if (exportParams.filters) {
      const { filters } = exportParams;
      Object.keys(filters).forEach(key => {
        if (filters[key].length)
          params.append(key, filters[key]);
      });
    }
    
    const request = `${this.baseURLs[exportType]}${exportParams.fileType.toUpperCase()}?${params.toString()}`;
    this._toastService.createToast({ title: 'Your download will be ready shortly.', message: 'Do not exit this page. This may take a few moments.', position:'top-center', isLoading:true });

    return this._http.get<any>(request, { headers:headers, responseType: 'blob' as 'json', observe: 'response' })
    .pipe(
      finalize(()=> this._toastService.closeAll()),
      tap((res)=>{
        const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');
        const suggestedFileName = res.headers.get('my-filename')?.toString();
        
        //in case the server does not return the file name, we generate one
        const auxFileExtention:any = FileTypes[res.body.type as keyof typeof FileTypes];
        const feGeneerateFileName:string = `${this.fileNames[exportType]}_${formattedDate}.${auxFileExtention}`;

        const myFileName = suggestedFileName || feGeneerateFileName;
        fileDownLoad(res.body,res.body.type, myFileName);
      })
    );
  }
}
