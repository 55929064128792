import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { SuggestionsTagsInputModule } from '@usitsdasdesign/dds-ng/suggestions-tags-input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SelectItemOptions, SelectModule } from '@usitsdasdesign/dds-ng/select';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';
import { finalize, Subscription, take, takeUntil } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';
import { DrawerComponent } from '../../../shared/components/drawer/drawer.component';
import { ToastModule } from '@usitsdasdesign/dds-ng/toast';
import { User } from '../../../shared/model/user';

@Component({
  selector: 'app-user-form',
  standalone: true,
  imports: [ ButtonModule, ReactiveFormsModule, SelectModule, SuggestionsTagsInputModule, ToastModule ],
  templateUrl: './user-form.component.html',
  styleUrl: './user-form.component.sass',
})
export class UserFormComponent implements OnInit, OnDestroy {
  private subscription = new Subscription;
  public cancelPersonListEmitter: EventEmitter<void> = new EventEmitter<void>();
  public selectRoleFormCtrl = new FormControl({ value: '', disabled: false });
  public selectUserFormCtrl = new FormControl({ value: '', disabled: false });
  
  public isPersonListLoading: boolean = false;
  public isUserSelected: boolean = false;
  public isRoleSelected: boolean = true;
  public searchList: string[] = [];
  public roleItems: SelectItemOptions[] = [];
  public userData!: Partial<User>;
  public roleCount = {admin:0,adminApprover:0};
  public initialsName!: string;

  private userService: UserService = inject(UserService);
  private drawerComponent: DrawerComponent = inject(DrawerComponent);

  async ngOnInit(): Promise<void> {
    this.userService.getUserRole().pipe(take(1))
    .subscribe((response) => response.data?.forEach((role) => this.roleItems.push({ heading: role.role_name, value: role.id })));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async changeUserValue(): Promise<void> {
    const userFormValue = this.selectUserFormCtrl.value?.trim() ?? '';
    const searchTerm = userFormValue.toLowerCase();
    const searchCriteria = searchTerm.includes('/') ? searchTerm.split('/')[1].trim() : searchTerm.trim();
    this.isUserSelected = !!userFormValue && userFormValue.includes("/");
    
    setTimeout(() => {
      this.searchList = [];
      this.cancelPersonListEmitter.emit();
    
      if (!userFormValue.length) return;
      
      this.isPersonListLoading = true;
      this.subscription = this.userService.getUserByCriteria(searchCriteria)
      .pipe(take(1), takeUntil(this.cancelPersonListEmitter), finalize(() => this.isPersonListLoading = false))
      .subscribe(response => {
        if (!userFormValue.length) return;
        response.data.forEach((user) => {
          this.userData = user;
          this.searchList = [ ...this.searchList, `${user.first_name} ${user.last_name} / ${user.email}` ];
          this.initialsName = `${user.first_name?.charAt(0)}${user.last_name?.charAt(0)}`.toUpperCase();
        });
      });
    }, 300);
  }

  public valueChangedRole(): void {
    this.userData.role_id = parseInt(this.selectRoleFormCtrl.value!)
    this.isRoleSelected = false;
  }

  public saveUser(): void {
    this.userService.saveUserRoleChange(this.userData).pipe(take(1))
    .subscribe(() => this.drawerComponent.close(true));
  }
}
