import { AfterViewInit, Component, EventEmitter, inject, Input, Output, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { PopupService, PopupComponent } from '../../services/popup.service';
import { Accessible } from '../../model/model';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.sass'
})
export class ModalComponent implements PopupComponent, AfterViewInit {
  private popups: PopupService = inject(PopupService);
  @Input() public content?: Type<unknown> | string;
  @Input() public data?: Accessible;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter<any>();

  @Input() title?: string;
  @Input() isLarge: boolean = false;
  @Input() type: 'dialog' | 'confirm' | 'alert' = 'dialog';
  @Input() isInverse: boolean = false;

  @ViewChild('content', { read: ViewContainerRef }) private contentView!: ViewContainerRef;

  ngAfterViewInit(): void {
    if (!this.content)
      throw new Error('Content not provided');

    setTimeout(() => this.title = this.title || this.type.replace(/^\w/, (c) => c.toUpperCase()));
    
    if (typeof this.content === 'string')
      this.contentView.element.nativeElement.parentElement.innerHTML = this.content;
    else {
      const componentRef = this.contentView.createComponent(this.content);
      if (!!this.data) {
        for (const key in this.data)
          (componentRef.instance as any)[key] = this.data[key];
      }
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  close() {
    this.popups.modals.close();
  }

  cancel() {
    this.popups.modals.close(false);
  }

  accept() {
    this.popups.modals.close(true);
  }
}
