<div class="dds-row">
	<div class="dds-col">
		<div class="section-title">Audit Log</div>

		<div class="search-bar">
			<dds-search #search placeholder="Search in Audit Log by Skill Name, Requestor or Processor"
				[ariaLabel]="search.placeholder" [isTrimValue]="true" size="lg"
				(valueChanged)="searchLogItems($event)"></dds-search>
		</div>

		<div class="filters">
			<dds-multi-select label="Type" ariaLabel="Type" type="counter" theme="dark" stickerWidth="auto" stickerMaxHeight="400"
				[list]="filters.lists.modificationType" [(ngModel)]="filters.filters.modificationType"
				(valueChanged)="reload()"></dds-multi-select>
			<dds-multi-select label="Decision" ariaLabel="Decision" type="counter" theme="dark" stickerWidth="auto"
				stickerMaxHeight="400" [list]="filters.lists.decision" [(ngModel)]="filters.filters.decision"
				(valueChanged)="reload()"></dds-multi-select>
			<dds-multi-select label="Requestor" ariaLabel="Requestor" type="counter" theme="dark" stickerWidth="auto"
				stickerMaxHeight="400" [list]="filters.lists.requestor" [(ngModel)]="filters.filters.requestor"
				(valueChanged)="reload()"></dds-multi-select>
			<dds-multi-select label="Processor" ariaLabel="Processor" type="counter" theme="dark" stickerWidth="auto"
				stickerMaxHeight="400" [list]="filters.lists.processor" [(ngModel)]="filters.filters.processor"
				(valueChanged)="reload()"></dds-multi-select>
      <dds-daterangepicker label="Request Date Range" ariaLabel="Request Date Range" theme="dark" [isManualInput]="false"
        stickerWidth="auto"
				stickerMaxHeight="400" [isStartDateEmitted]="false" [(ngModel)]="filters.filters.requestDate"
				(onHidden)="reload()"></dds-daterangepicker>
      <dds-daterangepicker label="Taxonomy Change Date" ariaLabel="Taxonomy Change Date" theme="dark" [isManualInput]="false"
        stickerWidth="auto"
				stickerMaxHeight="400" [isStartDateEmitted]="false" [(ngModel)]="filters.filters.taxonomyDate"
				(onHidden)="reload()"></dds-daterangepicker>

			<dds-button [disabled]="!filters.isFiltered" theme="dark" icon="dds-icon_close" role="button" ariaLabel="Clear"
				(clicked)="clearFilters()">Clear</dds-button>
		</div>

		<app-table #table [initialSort]="{ property: 'taxonomyChangeDate', descending: true }" [columns]="columns" [isLoading]="isLoading" [totalSize]="totalSize" (load)="getLogItems($event)">
			@if (logItems.length === 0) {
				<app-table-empty [colspan]="columns.length" />
			}
			@for (item of logItems; track item.id) {
				<tr class="dds-data-table__row cursor" (click)="viewSkillDetails(item.id)">
					@for (column of columns; track column.name) {
						@switch (column.dataType) {
							@case ('date') {
								<td class="dds-data-table__cell">{{ item[column.name] | date: 'MM/dd/yyyy' }}</td>
							}
							@default {
								<td class="dds-data-table__cell">{{ item[column.name] }}</td>
							}
						}
					}
				</tr>
			}
		</app-table>
	</div>
</div>
