import * as i0 from '@angular/core';
import { Directive, Optional, Self, HostBinding, EventEmitter, Component, ChangeDetectionStrategy, Input, ViewChild, ContentChild, Output, NgModule } from '@angular/core';
import * as i2 from '@usitsdasdesign/dds-ng/core/focus-handler';
import { FocusHandlerDirective, FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import * as i1 from '@angular/forms';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import { DestroyMixin, I18nMixin, FormAccessorMixin } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import * as i6 from '@usitsdasdesign/dds-ng/core/theme';
import { ThemeMapEnum, ThemeModule } from '@usitsdasdesign/dds-ng/core/theme';
import { Size, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as i2$1 from '@usitsdasdesign/dds-ng/shared/dds-config';
import * as i3 from '@usitsdasdesign/dds-ng/shared/i18n';
const _c0 = ["searchField"];
const _c1 = ["clearBtn"];
const _c2 = ["*"];
const _c3 = (a0, a1, a2) => ({
  "dds-search_disabled": a0,
  "dds-search_inverse": a1,
  "dds-search_readonly": a2
});
const _c4 = a0 => ({
  "dds-search__close_shown": a0
});
const _c5 = a0 => ({
  "dds-search__input_active": a0
});
function SearchComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function SearchComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "input", 6, 1);
    i0.ɵɵlistener("input", function SearchComponent_Conditional_2_Template_input_input_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.changeHandle($event));
    })("blur", function SearchComponent_Conditional_2_Template_input_blur_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.blur());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c5, ctx_r2.value == null ? null : ctx_r2.value.length))("placeholder", ctx_r2.placeholder)("disabled", ctx_r2.disabled)("value", ctx_r2.value || "")("readonly", ctx_r2.readonly);
    i0.ɵɵattribute("aria-label", ctx_r2.ariaLabel)("aria-disabled", ctx_r2.disabled)("role", ctx_r2.SEARCH_FIELD_ROLE);
  }
}
class SearchFieldDirective {
  constructor(ngControl, parentForm, parentFormGroup, elementRef) {
    this.ngControl = ngControl;
    this.parentForm = parentForm;
    this.parentFormGroup = parentFormGroup;
    this.elementRef = elementRef;
    this.searchField = true;
  }
  static {
    this.ɵfac = function SearchFieldDirective_Factory(t) {
      return new (t || SearchFieldDirective)(i0.ɵɵdirectiveInject(i1.NgControl, 10), i0.ɵɵdirectiveInject(i1.NgForm, 8), i0.ɵɵdirectiveInject(i1.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SearchFieldDirective,
      selectors: [["", "ddsSearch", ""]],
      hostVars: 2,
      hostBindings: function SearchFieldDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("dds-search__input", ctx.searchField);
        }
      },
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i2.FocusHandlerDirective])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SearchFieldDirective, [{
    type: Directive,
    args: [{
      selector: '[ddsSearch]',
      hostDirectives: [FocusHandlerDirective],
      standalone: true
    }]
  }], () => [{
    type: i1.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }]
  }, {
    type: i1.NgForm,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i1.FormGroupDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.ElementRef
  }], {
    searchField: [{
      type: HostBinding,
      args: [`class.dds-search__input`]
    }]
  });
})();
const DEFAULT_PLACEHOLDER = 'Search...';
const DEFAULT_DEBOUNCE_TIME = 300;
const DEFAULT_ICON = 'dds-icon_search';
const SEARCH_FIELD_ROLE = 'searchbox';
const themeClassesMap = {
  [ThemeMapEnum.Size]: {
    [Size.lg]: 'dds-search_lg',
    [Size.sm]: 'dds-search_sm'
  }
};
const SearchBase = DestroyMixin(I18nMixin(FormAccessorMixin()));
class SearchComponent extends SearchBase {
  set value(value) {
    this._value = value;
    this.checkIsEmpty();
  }
  get value() {
    return this._value;
  }
  constructor(ngControl, parentForm, parentFormGroup, cdr, ddsConfig, i18n, renderer) {
    super();
    this.ngControl = ngControl;
    this.parentForm = parentForm;
    this.parentFormGroup = parentFormGroup;
    this.cdr = cdr;
    this.ddsConfig = ddsConfig;
    this.i18n = i18n;
    this.renderer = renderer;
    this.placeholder = DEFAULT_PLACEHOLDER;
    this.debounceTime = DEFAULT_DEBOUNCE_TIME;
    this.isTrimValue = true;
    this.size = this.ddsConfig.getConfig().size;
    this.icon = DEFAULT_ICON;
    this.valueChanged = new EventEmitter();
    this.fieldCleared = new EventEmitter();
    this.SEARCH_FIELD_ROLE = SEARCH_FIELD_ROLE;
    this.themeConfig = themeClassesMap;
    this._value = '';
    this.valueSubject = new Subject();
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit() {
    this.checkIsEmpty();
    super.ngOnInit();
    this.valueSubject.pipe(debounceTime(this.debounceTime), takeUntil(this.destroy)).subscribe(value => {
      this.valueChanged.emit(value);
    });
  }
  ngOnChanges(changes) {
    if (changes?.value && changes?.value?.currentValue) {
      this.value = this.isTrimValue ? this.value.trim() : this.value;
      if (changes?.value?.currentValue.length > this.maxLength && this.maxLength) {
        this.value = this.value.slice(0, this.maxLength);
      }
      this.emittedValue = this.value;
    }
  }
  ngDoCheck() {
    if (this.ngControl && this.searchFieldExternal) {
      if (this.ngControl.disabled !== null && this.ngControl.disabled !== this.disabled) {
        this.disabled = this.ngControl.disabled;
        this.cdr.markForCheck();
      }
    }
  }
  ngAfterContentInit() {
    if (this.searchFieldExternal) {
      this.ngControl = this.searchFieldExternal.ngControl;
      this.parentForm = this.searchFieldExternal.parentForm;
      this.parentFormGroup = this.searchFieldExternal.parentFormGroup;
      this.externalSearchSetAttr();
    }
  }
  changeHandle(e) {
    if (e.target.value.length > this.maxLength && this.maxLength) {
      e.target.value = e.target.value.slice(0, this.maxLength);
    } else {
      this.value = e.target.value;
    }
    this.changeEmit(this.value);
  }
  writeValue(text) {
    if (text) {
      this.value = text;
    } else {
      this.reset(false);
    }
    this.cdr.markForCheck();
  }
  setDisabledState(disabled) {
    this.disabled = disabled;
    this.cdr.markForCheck();
  }
  clearField(event) {
    event.stopPropagation();
    this.reset();
    this.removeFocus(this.clearBtn.nativeElement);
    this.focus();
    this.fieldCleared.emit();
  }
  reset(isModelChange = true) {
    const searchField = this.searchField || this.searchFieldExternal?.elementRef;
    if (searchField) {
      this.value = searchField.nativeElement.value = '';
    }
    this.changeEmit(this.value, isModelChange);
    this.cdr.markForCheck();
  }
  focus() {
    const searchField = this.searchField || this.searchFieldExternal?.elementRef;
    searchField.nativeElement.focus();
  }
  blur() {
    this.value = this.emittedValue;
    this.onModelTouched();
  }
  setI18nTexts(translation) {
    this.clearBtnAriaLabel = translation.SEARCH.A11Y.CLEAR_BTN_ARIA_LABEL;
    this.cdr.detectChanges();
  }
  changeEmit(data, isModelChange = true) {
    data = this.isTrimValue ? data.trim() : data;
    this.emittedValue = data;
    this.valueSubject.next(data);
    if (isModelChange) this.onModelChange(data);
  }
  checkIsEmpty() {
    this.isEmptyValue = this._value && this._value.length ? false : true;
  }
  removeFocus(el) {
    el.classList.remove(AdditionalClass.keyboardFocus);
  }
  externalSearchSetAttr() {
    const externalInputEl = this.searchFieldExternal.elementRef.nativeElement;
    this.value = externalInputEl.value;
    this.renderer.setAttribute(externalInputEl, 'role', SEARCH_FIELD_ROLE);
    this.cdr.markForCheck();
    fromEvent(externalInputEl, 'input').pipe(takeUntil(this.destroy)).subscribe(e => {
      this.value = e.target.value;
      this.cdr.markForCheck();
    });
  }
  static {
    this.ɵfac = function SearchComponent_Factory(t) {
      return new (t || SearchComponent)(i0.ɵɵdirectiveInject(i1.NgControl, 10), i0.ɵɵdirectiveInject(i1.NgForm, 8), i0.ɵɵdirectiveInject(i1.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i2$1.DdsConfigService), i0.ɵɵdirectiveInject(i3.I18nService), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SearchComponent,
      selectors: [["dds-search"]],
      contentQueries: function SearchComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, SearchFieldDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.searchFieldExternal = _t.first);
        }
      },
      viewQuery: function SearchComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.searchField = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.clearBtn = _t.first);
        }
      },
      hostVars: 3,
      hostBindings: function SearchComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-is-empty", ctx.isEmptyValue);
          i0.ɵɵclassProp("dds-disabled", ctx.disabled);
        }
      },
      inputs: {
        disabled: "disabled",
        placeholder: "placeholder",
        debounceTime: "debounceTime",
        isInverse: "isInverse",
        readonly: "readonly",
        isTrimValue: "isTrimValue",
        size: "size",
        ariaLabel: "ariaLabel",
        customClass: "customClass",
        icon: "icon",
        maxLength: "maxLength",
        value: "value"
      },
      outputs: {
        valueChanged: "valueChanged",
        fieldCleared: "fieldCleared"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c2,
      decls: 7,
      vars: 19,
      consts: [["clearBtn", ""], ["searchField", ""], [3, "ngClass", "size", "ddsTheme"], ["ddsFocus", "", "type", "search", 1, "dds-search__input", 3, "ngClass", "placeholder", "disabled", "value", "readonly"], ["ddsFocus", "", "type", "button", 1, "dds-search__close", 3, "keydown.enter", "keydown.space", "click", "ngClass", "disabled"], [1, "dds-icon", "dds-icon_close"], ["ddsFocus", "", "type", "search", 1, "dds-search__input", 3, "input", "blur", "ngClass", "placeholder", "disabled", "value", "readonly"]],
      template: function SearchComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "label", 2);
          i0.ɵɵtemplate(1, SearchComponent_Conditional_1_Template, 1, 0)(2, SearchComponent_Conditional_2_Template, 2, 10, "input", 3);
          i0.ɵɵelement(3, "span");
          i0.ɵɵelementStart(4, "button", 4, 0);
          i0.ɵɵlistener("keydown.enter", function SearchComponent_Template_button_keydown_enter_4_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.clearField($event));
          })("keydown.space", function SearchComponent_Template_button_keydown_space_4_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.clearField($event));
          })("click", function SearchComponent_Template_button_click_4_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.clearField($event));
          });
          i0.ɵɵelement(6, "span", 5);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-search ", ctx.customClass, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(13, _c3, ctx.disabled, ctx.isInverse, ctx.readonly))("size", ctx.size)("ddsTheme", ctx.themeConfig);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.searchFieldExternal ? 1 : 2);
          i0.ɵɵadvance(2);
          i0.ɵɵclassMapInterpolate1("dds-search__icon dds-icon ", ctx.icon, "");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(17, _c4, ctx.value == null ? null : ctx.value.length))("disabled", ctx.readonly);
          i0.ɵɵattribute("aria-label", ctx.clearBtnAriaLabel);
        }
      },
      dependencies: [CommonModule, i4.NgClass, FocusHandlerModule, i2.FocusHandlerDirective, ThemeModule, i6.ThemeDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SearchComponent, [{
    type: Component,
    args: [{
      selector: 'dds-search',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [CommonModule, FocusHandlerModule, ThemeModule],
      template: "<label [ngClass]=\"{\r\n         'dds-search_disabled': disabled,\r\n         'dds-search_inverse': isInverse,\r\n         'dds-search_readonly': readonly\r\n       }\"\r\n       [size]=\"size\"\r\n       [ddsTheme]=\"themeConfig\"\r\n       class=\"dds-search {{customClass}}\">\r\n\r\n  @if (searchFieldExternal) {\r\n    <ng-content></ng-content>\r\n  } @else {\r\n    <input [ngClass]=\"{'dds-search__input_active': value?.length}\"\r\n           [placeholder]=\"placeholder\"\r\n           [disabled]=\"disabled\"\r\n           [value]=\"value || ''\"\r\n           [attr.aria-label]=\"ariaLabel\"\r\n           [attr.aria-disabled]=\"disabled\"\r\n           [readonly]=\"readonly\"\r\n           [attr.role]=\"SEARCH_FIELD_ROLE\"\r\n           ddsFocus\r\n           class=\"dds-search__input\"\r\n           type=\"search\"\r\n           (input)=\"changeHandle($event)\"\r\n           (blur)=\"blur()\"\r\n           #searchField />\r\n  }\r\n\r\n\r\n  <span class=\"dds-search__icon dds-icon {{icon}}\"></span>\r\n\r\n  <button class=\"dds-search__close\"\r\n          [ngClass]=\"{'dds-search__close_shown': value?.length}\"\r\n          [attr.aria-label]=\"clearBtnAriaLabel\"\r\n          ddsFocus\r\n          type=\"button\"\r\n          [disabled]=\"readonly\"\r\n          (keydown.enter)=\"clearField($event)\"\r\n          (keydown.space)=\"clearField($event)\"\r\n          (click)=\"clearField($event)\"\r\n          #clearBtn>\r\n    <span class=\"dds-icon dds-icon_close\"></span>\r\n  </button>\r\n</label>"
    }]
  }], () => [{
    type: i1.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }]
  }, {
    type: i1.NgForm,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i1.FormGroupDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i2$1.DdsConfigService
  }, {
    type: i3.I18nService
  }, {
    type: i0.Renderer2
  }], {
    disabled: [{
      type: HostBinding,
      args: [`class.${AdditionalClass.disabled}`]
    }, {
      type: Input
    }],
    isEmptyValue: [{
      type: HostBinding,
      args: [`attr.data-is-empty`]
    }],
    searchField: [{
      type: ViewChild,
      args: ['searchField']
    }],
    clearBtn: [{
      type: ViewChild,
      args: ['clearBtn']
    }],
    searchFieldExternal: [{
      type: ContentChild,
      args: [SearchFieldDirective]
    }],
    placeholder: [{
      type: Input
    }],
    debounceTime: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    isTrimValue: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    maxLength: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    valueChanged: [{
      type: Output
    }],
    fieldCleared: [{
      type: Output
    }]
  });
})();
class SearchModule {
  static {
    this.ɵfac = function SearchModule_Factory(t) {
      return new (t || SearchModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SearchModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [SearchComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SearchModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [SearchComponent, SearchFieldDirective],
      exports: [SearchComponent, SearchFieldDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SearchComponent, SearchFieldDirective, SearchModule };
