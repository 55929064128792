import * as i0 from '@angular/core';
import { EventEmitter, Injectable, Inject, NgModule } from '@angular/core';
import { DOCUMENT, CommonModule } from '@angular/common';
import { KeyCodes, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
const CLOSED_DELAY = 300;
const FOCUSED_ELEMENT_COUNT = 0;
const FOCUSED_ELEMENT = 'button';
const TABBABLE_ELEMENTS = 'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])';
class ModalRef {
  constructor() {
    this.onClosed$ = new EventEmitter();
  }
  onClosed() {
    return this.onClosed$;
  }
  emitCloseEvent(data) {
    this.onClosed$.next(data);
    this.onClosed$.complete();
  }
}
var ModalClass;
(function (ModalClass) {
  ModalClass["modal"] = "dds-modal";
  ModalClass["modalOpen"] = "dds-modal-open";
  ModalClass["modalInit"] = "dds-modal-init";
  ModalClass["modalDialog"] = "dds-modal-dialog";
})(ModalClass || (ModalClass = {}));

// @dynamic
class ModalService {
  constructor(componentFactoryResolver, appRef, injector, ngZone, rendererFactory, document) {
    this.componentFactoryResolver = componentFactoryResolver;
    this.appRef = appRef;
    this.injector = injector;
    this.ngZone = ngZone;
    this.rendererFactory = rendererFactory;
    this.document = document;
    this.onClosed = new EventEmitter();
    this.modalsList = [];
    this.focusedElementCount = FOCUSED_ELEMENT_COUNT;
    this.documentKeyUpListener = e => {
      if (e.keyCode === KeyCodes.ESC && this.enableEscForClosing) {
        e.stopPropagation();
        this.close();
      }
    };
    this.keyboardModalTrap = e => {
      const lastComponent = this.modalsList[this.modalsList.length - 1]?.component?.location?.nativeElement;
      const tabbableList = this.getKeyboardFocusableElements(lastComponent);
      const firstTabbableElement = tabbableList[0];
      const lastTabbableElement = tabbableList[tabbableList.length - 1];
      if (e.keyCode === KeyCodes.TAB || e.keyCode === KeyCodes.TAB && e.shiftKey) {
        this.render.removeClass(lastTabbableElement, AdditionalClass.keyboardFocus);
        this.render.removeClass(firstTabbableElement, AdditionalClass.keyboardFocus);
      }
      if (e.keyCode === KeyCodes.TAB && !e.shiftKey && lastTabbableElement === this.document.activeElement) {
        e.preventDefault();
        this.render.addClass(firstTabbableElement, AdditionalClass.keyboardFocus);
        firstTabbableElement.focus();
      }
      if (e.keyCode === KeyCodes.TAB && e.shiftKey && firstTabbableElement === this.document.activeElement) {
        e.preventDefault();
        this.render.addClass(lastTabbableElement, AdditionalClass.keyboardFocus);
        lastTabbableElement.focus();
      }
    };
    this.render = rendererFactory.createRenderer(null, null);
  }
  ngOnDestroy() {
    this.closeAll();
  }
  open(component, inputs, enableEscForClosing = true) {
    const modalRef = new ModalRef();
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    const domElem = componentRef.hostView.rootNodes[0];
    this.enableEscForClosing = enableEscForClosing;
    this.appRef.attachView(componentRef.hostView);
    this.render.appendChild(this.document.body, domElem);
    this.render.addClass(domElem, ModalClass.modalInit);
    this.render.addClass(domElem, ModalClass.modalOpen);
    this.render.setAttribute(domElem, 'role', 'dialog');
    this.render.setAttribute(domElem, 'aria-modal', 'true');
    domElem.tabIndex = 0;
    domElem.focus();
    if (inputs) {
      Object.keys(inputs).forEach(key => {
        componentRef.instance[key] = inputs[key];
      });
    }
    this.modalsList.push({
      component: componentRef,
      modalRef
    });
    modalRef.componentInstance = componentRef.instance;
    this.incrementElementCount();
    return modalRef;
  }
  close(data) {
    const modal = this.modalsList.pop();
    modal.modalRef.emitCloseEvent(data);
    this.remove(modal.component);
  }
  closeAll() {
    this.modalsList.forEach(modal => {
      modal.modalRef.emitCloseEvent();
      this.remove(modal.component);
    });
  }
  getKeyboardFocusableElements(element) {
    const tabbableElements = Array.from(element.querySelectorAll(TABBABLE_ELEMENTS));
    return [...tabbableElements].filter(el => !el.hasAttribute('disabled'));
  }
  remove(componentRef) {
    const domElem = componentRef.hostView.rootNodes[0];
    domElem.classList.remove(ModalClass.modalOpen);
    setTimeout(() => {
      this.render.removeChild(this.document.body, domElem);
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }, CLOSED_DELAY);
    this.modalsList = this.modalsList.filter(item => item.component !== componentRef);
    this.decrementElementCount();
    this.onClosed.emit(componentRef);
  }
  incrementElementCount() {
    if (++this.focusedElementCount === 1) {
      this.ngZone.runOutsideAngular(() => {
        const doc = this.document || document;
        doc.addEventListener('keyup', this.documentKeyUpListener);
        doc.addEventListener('keydown', this.keyboardModalTrap);
      });
    }
  }
  decrementElementCount() {
    if (! --this.focusedElementCount) {
      const doc = this.document || document;
      doc.removeEventListener('keyup', this.documentKeyUpListener);
      doc.removeEventListener('keydown', this.keyboardModalTrap);
    }
  }
  static {
    this.ɵfac = function ModalService_Factory(t) {
      return new (t || ModalService)(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ModalService,
      factory: ModalService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalService, [{
    type: Injectable
  }], () => [{
    type: i0.ComponentFactoryResolver
  }, {
    type: i0.ApplicationRef
  }, {
    type: i0.Injector
  }, {
    type: i0.NgZone
  }, {
    type: i0.RendererFactory2
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();
class ModalModule {
  static {
    this.ɵfac = function ModalModule_Factory(t) {
      return new (t || ModalModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ModalModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [ModalService],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      providers: [ModalService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ModalClass, ModalModule, ModalService };
