import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlEvent, ControlValueAccessor, FormControl, NgControl, PristineChangeEvent, ReactiveFormsModule, TouchedChangeEvent } from '@angular/forms';
import { SuggestionsTagsInputComponent } from '@usitsdasdesign/dds-ng/suggestions-tags-input';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-tag-input',
	standalone: true,
	imports: [ReactiveFormsModule, SuggestionsTagsInputComponent],
	templateUrl: './tag-input.component.html',
	styleUrl: './tag-input.component.sass'
})
export class TagInputComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
	private onChange: any;
	private onTouch: any;
	private elRef: ElementRef = inject(ElementRef);
	private subscription: Subscription = new Subscription();

	@Input() label: string = 'Label';
	@Input() labelPosition: 'internal' | 'external' = 'external';
	@Input() placeholder: string = 'Placeholder';
	@Input() errorMessage: string = 'Error';
	@Input() minTagLength: number = 3;
	@Input() options: { value: any, heading: string }[] = [];
	@Output() newTag: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('suggestionElement') suggestionElement: ElementRef | any;
	public description: string = '';
	public formControl = new FormControl<string[]>([]);

	public get searchList() {
		return this.options.map((o, i) => ({ key: `${i}`, heading: o.heading }));
	}

	constructor(@Self() @Optional() private ngControl: NgControl) {
		this.ngControl.valueAccessor = this;
	}

	// Use this method after an update on the ngControl (parent formControl) validators
	public updateValueAndValidity(): void {
		this.formControl.setValidators(this.ngControl.control?.validator ?? null);
		this.formControl.setAsyncValidators(this.ngControl.control?.asyncValidator ?? null);
		this.formControl.updateValueAndValidity();
	}

	public ngOnInit(): void {
		this.updateValueAndValidity();

		this.subscription.add(
			this.formControl.valueChanges.subscribe(headings => this.commitValues(headings)),
		);
		this.subscription.add(
			this.formControl.events.subscribe((event: ControlEvent<string[] | null>) => {
				if (event instanceof TouchedChangeEvent)
					event.touched ? this.onTouch() : this.ngControl.control?.markAsUntouched();
				else if (event instanceof PristineChangeEvent)
					event.pristine ? this.ngControl.control?.markAsPristine() : this.ngControl.control?.markAsDirty();
			})
		);
		this.subscription.add(
			this.ngControl.control?.events.subscribe((event: ControlEvent<any>) => {
				if (event instanceof TouchedChangeEvent)
					event.touched ? this.formControl.markAsTouched() : this.formControl.markAsUntouched();
				else if (event instanceof PristineChangeEvent)
					event.pristine ? this.formControl.markAsPristine() : this.formControl.markAsDirty();
			})
		);
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public ngAfterViewInit(): void {
		const inputElement: HTMLInputElement | null = this.elRef?.nativeElement.querySelector('input.dds-suggestions-tags-input__field');
		if (!inputElement) return;

		inputElement.oninput = () => {
			const value = inputElement.value;
			const match = this.options.find(o => o.heading.toLowerCase() === value.toLowerCase().trim());
			setTimeout(() =>
				this.description = !value || value.length < this.minTagLength ? '' : match ?
					`Press enter to select '${match.heading}'` :
					`Press enter to create '${value}' as a new entry`
			);
		};

		// Remove last tag when backspace is pressed and input is empty
		inputElement.onbeforeinput = (event: InputEvent) => {
			if (event.inputType != 'deleteContentBackward' || inputElement.value) return;
			const headings = this.formControl.value;
			if (!headings?.length) return;
			this.commitValues(headings.slice(0, -1));
		};
	}

	private commitValues(headings: string[] | null): void {
		const { values, newTag } = this.getValuesFromHeadings(headings);
		this.onChange(values);
		this.onTouch();
		this.writeValue(values);
		if (!!newTag)
			this.newTag.emit(newTag);
	}

	private getValuesFromHeadings(headings: string[] | null): { values: any[], newTag: string | undefined } {
		const lowerCaseHeadings = headings?.map(h => h.toLowerCase().trim()) ?? [];
		const values = lowerCaseHeadings.map(h => this.options.find(o => o.heading.toLowerCase() === h)?.value).filter(v => v !== undefined);
		const newTags = headings?.filter(h => h.length >= this.minTagLength && !this.options.some(o => o.heading.toLowerCase() === h.toLowerCase().trim())) ?? [];
		return { values, newTag: newTags[0] };
	}

	// ControlValueAccessor methods
	// These methods are used to connect the formControl with the parent form
	public writeValue(val: any): void {
		if (!Array.isArray(val)) return;
		const headings = val.map(v => this.options.find(o => o.value === v)?.heading).filter(h => h !== undefined) as string[];
		this.formControl.setValue(headings, { emitEvent: false });
		this.description = '';
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		isDisabled ? this.formControl.disable() : this.formControl.enable();
	}
  public isEscapeHideContext() {
    if (this.suggestionElement && this.suggestionElement.contextMenuShown) {
      this.suggestionElement.contextMenuShown = false;
    }
  }
}
