import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckboxComponent } from '@usitsdasdesign/dds-ng/checkbox';
import { ItemsPerPageComponent } from '@usitsdasdesign/dds-ng/items-per-page';
import { PaginationComponent } from '@usitsdasdesign/dds-ng/pagination';
import { Column, SortOptions } from '@usitsdasdesign/dds-ng/shared';

export type TableLoadEvent = { page: number; pageSize: number; sortingState: SortOptions; };
export type TableRow = { tableRowSelected?: boolean; };

@Component({
	selector: 'app-table',
	standalone: true,
  imports: [PaginationComponent, ItemsPerPageComponent, CheckboxComponent, CommonModule],
	templateUrl: './table.component.html',
	styleUrl: './table.component.sass'
})
export class TableComponent implements OnInit {
	@Input() public columns: Column[] = [];
	@Input() public isLoading: boolean = false;
	@Input() public totalSize: number = 0;
	@Input() public initialSort?: SortOptions | string | false;
	@Input() public selectable: boolean = false;
	@Input() public paginated: boolean = true;
	@Input() public sorting: boolean = true;
	@Output() public load: EventEmitter<TableLoadEvent> = new EventEmitter<TableLoadEvent>();
	@Output() public onSelectAll: EventEmitter<boolean> = new EventEmitter<boolean>();

	public page: number = 1;
	public pageSize: number = 10;
	public isAllSelected: boolean = false;
	public selectedCount: number = 0;
	public sortingState: SortOptions = {};

	public get pageNumberInSection(): number {
		return this.totalSize === 0 ? 0 : 5;
	};

	public get itemsPerPageTitle(): string {
		return `${!this.totalSize ? 0 : this.pageSize * (this.page - 1) + 1} to ${Math.min(this.pageSize * this.page, this.totalSize)} of ${this.totalSize ?? 0}`;
	}

	public get totalPages(): number {
		return Math.ceil(this.totalSize / this.pageSize);
	}

	public ngOnInit(): void {
		if (!this.columns?.length)
			throw new Error('Columns are required');

		if (this.initialSort === false) {
			this.sortingState = {};
		}
		else if (typeof this.initialSort === 'string') {
			const column = this.columns.find(c => c.name === this.initialSort);
			this.sortingState = column && column.sortable ? { property: column.name, descending: false, dataType: column.dataType } : {};
		}
		else {
			this.sortingState = this.initialSort || {
				property: this.columns[0].name,
				descending: false,
				dataType: this.columns[0].dataType
			};
		}

		this.reload();
	};

	public sortColumn(column: Column, event: Event): void {
		event.preventDefault();

		if (this.sortingState && this.sortingState.property === column.name) {
			this.sortingState = { ...this.sortingState, descending: !this.sortingState.descending };
		} else {
			this.sortingState = {
				property: column.name,
				descending: false,
				dataType: column.dataType,
			};
		}

		this.reload();
	}

	public reload({ page, pageSize, sortingState }: { page?: number; pageSize?: number; sortingState?: SortOptions; } = {}): void {
		if (page !== undefined) this.page = page;
		if (pageSize !== undefined) this.pageSize = pageSize;
		if (sortingState !== undefined) this.sortingState = sortingState;
		setTimeout(() => this.load.emit({ page: this.page, pageSize: this.pageSize, sortingState: this.sortingState }));
		this.resetSelected();
		this.scrollTop();
	}

	public toggleRow(rowData: TableRow, data: TableRow[]): void {
		rowData.tableRowSelected = !rowData.tableRowSelected;
		this.checkSelectedRow(data);
	}

	public selectAllRows(status: boolean, data: TableRow[]): void {
		data.forEach(item => (item.tableRowSelected = status));
		this.checkSelectedRow(data);
	}

	private checkSelectedRow(data: TableRow[]): void {
		this.selectedCount = data.filter(row => row.tableRowSelected).length;
		this.isAllSelected = data.length === this.selectedCount;
	}

	private resetSelected() {
		this.isAllSelected = false;
		this.selectedCount = 0;
	}

	scrollTop() {
		const scrolleable = document.getElementsByClassName('scrollable-content')[0]
		scrolleable?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}

}
