import { Component, Input } from '@angular/core';
import { masterSkillTechnologies, masterSkillTypes } from '../../../../shared/enums/enums';
import { FullAuditLogData } from '../../../../shared/model/full-audit-log-data';
import { PendingChangeItemComponent } from '../../../pending-changes/widgets/pending-change-item/pending-change-item.component';

@Component({
	selector: 'app-skill-changes-details-audit-log',
	standalone: true,
	imports: [PendingChangeItemComponent],
	templateUrl: './skill-changes-details.component.html',
	styleUrl: './skill-changes-details.component.sass'
})
export class SkillChangesDetailsComponent {

	@Input() fullPCData!: FullAuditLogData;
	@Input() availableTags: any = []
	@Input() availableCategories: any = []
	@Input() availableSubCategories: any = []
	changesList: any = []
	addedTags: any = []
	removedTags: any = []

	ngOnInit() {
		this.populateChangesList()

	}

	populateChangesList() {
		if (this.fullPCData.skillName !== this.fullPCData.pchName) this.changesList.push({ label: 'Name', originalValue: this.fullPCData.skillName, pcValue: this.fullPCData.pchName })
		if (this.fullPCData.skillCategoryId !== this.fullPCData.pchCategoryId) this.changesList.push({ label: 'Category', originalValue: this.fullPCData.skillCategoryId, pcValue: this.fullPCData.pchCategoryId })
		if (this.fullPCData.skillSubCategoryId !== this.fullPCData.pchSubCategoryId) this.changesList.push({ label: 'Sub Category', originalValue: this.fullPCData.skillSubCategoryId, pcValue: this.fullPCData.pchSubCategoryId })
		if (this.fullPCData.skillTypeId !== this.fullPCData.pchTypeId) this.changesList.push({ label: 'Type', originalValue: this.fullPCData.skillTypeId, pcValue: this.fullPCData.pchTypeId })
		if (this.fullPCData.skillTechnologyId !== this.fullPCData.pchTechnologyId) this.changesList.push({ label: 'Technology', originalValue: this.fullPCData.skillTechnologyId, pcValue: this.fullPCData.pchTechnologyId })
		if (this.fullPCData.skillDescription !== this.fullPCData.pchDescription) {
			this.changesList.push({ label: 'Description', originalValue: this.fullPCData.skillDescription, pcValue: this.fullPCData.pchDescription, textShortener: true })
		}
		if (this.fullPCData.skillLink !== this.fullPCData.pchLink) this.changesList.push({ label: 'Description URL', originalValue: this.fullPCData.skillLink, pcValue: this.fullPCData.pchLink })
		if (this.fullPCData.skillTags !== this.fullPCData.pchTags) {
			this.changesList.push({ label: 'Tags', originalValue: this.fullPCData.skillTags, pcValue: this.fullPCData.pchTags })
			this.processTagsChangeLog()
		}
		this.replaceTechnologyIds()
		this.replceTypeIds()
		this.replaceCategoriesIds()
		this.replaceSubCategoriesIds()

	}


	processTagsChangeLog() {
		//get added tags..
		if (!this.fullPCData.skillTags) {
			this.availableTags.find((tag: any) => {
				if (this.fullPCData.pchTags?.includes(tag.value)) {
					this.addedTags.push(tag.heading)
				}
			})
		} else {
			this.fullPCData.pchTags?.forEach((pchTagId: number) => {
				if (!this.fullPCData.skillTags?.includes(pchTagId)) {
					this.availableTags.find((tag: any) => {
						if (tag.value == pchTagId) {
							this.addedTags.push(tag.heading)
						}
					})
				}
			})
		}

		//get removed tags
		if (!this.fullPCData.pchTags) {
			this.availableTags.find((tag: any) => {
				if (this.fullPCData.skillTags?.includes(tag.value)) {
					this.removedTags.push(tag.heading)
				}
			})
		} else {
			this.fullPCData.skillTags?.forEach((tagId: number) => {
				if (!this.fullPCData.pchTags?.includes(tagId)) {
					this.availableTags.find((tag: any) => {
						if (tag.value == tagId) {
							this.removedTags.push(tag.heading)
						}
					})
				}
			})
		}
	}

	replaceTechnologyIds() {
		this.changesList.forEach((change: any) => {
			if (change.label === 'Technology') {
				change.originalValue = masterSkillTechnologies[change.originalValue]
				change.pcValue = masterSkillTechnologies[change.pcValue]
			}
		})
	}

	replceTypeIds() {
		this.changesList.forEach((change: any) => {
			if (change.label === 'Type') {
				change.originalValue = masterSkillTypes[change.originalValue]
				change.pcValue = masterSkillTypes[change.pcValue]
			}
		})
	}

	replaceCategoriesIds() {
		this.changesList.forEach((change: any) => {
			if (change.label === 'Category') {
				change.originalValue = this.availableCategories.find((category: any) => category.value === change.originalValue)?.heading
				change.pcValue = this.availableCategories.find((category: any) => category.value === change.pcValue)?.heading
			}
		})
	}

	replaceSubCategoriesIds() {
		this.changesList.forEach((change: any) => {
			if (change.label === 'Sub Category') {
				change.originalValue = this.availableSubCategories.find((subCategory: any) => subCategory.value === change.originalValue)?.heading
				change.pcValue = this.availableSubCategories.find((subCategory: any) => subCategory.value === change.pcValue)?.heading
			}
		})
	}


}
