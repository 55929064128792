<table class="dds-data-table dds-data-table_fixed-header" [class.dds-data-table_striped]="!selectable">
	<thead class="dds-data-table__header">
		<tr>
			@if (selectable) {
				<th class="dds-data-table__header-cell">
					<dds-checkbox theme="dark" [isIndeterminate]="!!selectedCount && !isAllSelected" [disabled]="isLoading"
						[value]="isAllSelected" ariaLabel="select all rows" (stateChanged)="onSelectAll.emit($event)"></dds-checkbox>
				</th>
			}
			@for (column of columns; track column.name) {
				@if (!column.sortable || !sorting) {
					<th class="dds-data-table__header-cell">
						<div class="dds-data-table__content">
							<span>{{column.header || column.name}}</span>
						</div>
					</th>
				} @else {
					<th ddsFocus tabindex="0" class="dds-data-table__header-cell dds-data-table__header-cell_sorting"
						[class.dds-data-table__header-cell_sorted]="sortingState.property === column.name"
						[attr.aria-sort]="(sortingState.property === column.name) ? (sortingState.descending ? 'descending' : 'ascending'): 'none'"
						(click)="sortColumn(column, $event)" (keydown.space)="sortColumn(column, $event)"
						(keydown.enter)="sortColumn(column, $event)"
            [ngStyle]="{ width: column.width }">

						<div class="dds-data-table__content">
							<span>{{column.header || column.name}}</span>
							@if (sortingState.property === column.name) {
								<span class="dds-icon dds-data-table__icon" aria-label="sorting button"
									[class.dds-icon_sort-down]="sortingState.descending"
									[class.dds-icon_sort-up]="!sortingState.descending">
								</span>
							}
						</div>
					</th>
				}
			}
		</tr>
	</thead>

	<tbody class="dds-data-table__body">
		@if (isLoading) {
			@for (_ of [].constructor(pageSize); track $index) {
				<tr class="dds-data-table__row">
					@if (selectable) {
						<td class="dds-data-table__cell selectable-header">
							<div class="skeleton">&nbsp;</div>
						</td>
					}

					@for (column of columns; track column.name) {
						<td class="dds-data-table__cell">
							<div class="skeleton">&nbsp;</div>
						</td>
					}
				</tr>
			}
		} @else {
			<ng-content></ng-content>
		}
	</tbody>
</table>

@if (paginated) {
	<div class="pagination">
		<dds-pagination [pageLength]="totalPages" theme="dark" [pageNumberInSection]="pageNumberInSection" ariaLabel="pagination" [value]="page"
			(pageChanged)="reload({ page: $event })"></dds-pagination>
		<dds-items-per-page [title]="itemsPerPageTitle" [list]="[10, 20, 50, 100]" [value]="pageSize"
			(valueChanged)="reload({ page: 1, pageSize: $event })"></dds-items-per-page>
	</div>
}
