import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

//1:Admin User, 2:Admin App, 3:General User

const routesByRole:any = {
	'/': ['1', '2', '3'],
	'/skill-taxonomy': ['1', '2'],
	'/market-library': ['1', '2'],
	'/pending-changes': ['1', '2'],
	'/audit-log': ['1'],
	'/user-management': ['1'],
	'/skills': ['1', '2'],
	'/general-settings': [],
}

export const roleAuthGuard: CanActivateFn = (route, state) => {
	let role = localStorage.getItem('role');
	let urlSegment = '/' + route.url
	return routesByRole[urlSegment].includes(role) ? true : inject(Router).navigateByUrl('');
};






