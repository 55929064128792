
import { Component, inject } from '@angular/core';
import { CardModule } from '@usitsdasdesign/dds-ng/cards';
import { CardsComponent } from './widgets/cards/cards.component';
import { PendChangesTableComponent } from "./widgets/pend-changes-table/pend-changes-table.component";
import { LastModTableComponent } from "./widgets/last-mod-table/last-mod-table.component";
import { PopupService } from '../../shared/services/popup.service';

@Component({
  selector: 'app-home',
  standalone: true,
  providers:[],
  imports: [CardModule, CardsComponent, PendChangesTableComponent, LastModTableComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.sass'
})
export class HomeComponent {
}
