export class User {
	id: number;
	first_name: string;
	last_name: string;
	middle_name: string;
	email: string;
	role_id: number;
  job_Title: string;
  profile_pic: string;
	role_description: string;
	processed_by: number;
	processed_by_name: string;
	processed_date: Date;

	constructor(data: Partial<User> = {}) {
		this.id = data.id ?? 0;
		this.first_name = data.first_name ?? '';
		this.middle_name = data.middle_name ?? '';
		this.last_name = data.last_name ?? '';
		this.email = data.email ?? '';
		this.role_id = data.role_id ?? 0;
    this.job_Title = data.job_Title ?? '';
    this.profile_pic = data.profile_pic ?? '';
		this.role_description = data.role_description ?? '';
		this.processed_by = data.processed_by ?? 0;
		this.processed_by_name = data.processed_by_name ?? '';
		this.processed_date = new Date(data.processed_date ?? '');
	}
}
