import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
class HighlightPipe {
  transform(text, search) {
    const regex = new RegExp(`(?![^<]*>)${search}`, 'gi');
    text = text?.toString();
    return search && text ? text.replace(regex, match => `<span class="dds-highlight">${match}</span>`) : text;
  }
  static {
    this.ɵfac = function HighlightPipe_Factory(t) {
      return new (t || HighlightPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "highlight",
      type: HighlightPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HighlightPipe, [{
    type: Pipe,
    args: [{
      name: 'highlight',
      standalone: true
    }]
  }], null, null);
})();
class HighlightModule {
  static {
    this.ɵfac = function HighlightModule_Factory(t) {
      return new (t || HighlightModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: HighlightModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HighlightModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [HighlightPipe],
      exports: [HighlightPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { HighlightModule, HighlightPipe };
