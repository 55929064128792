import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, EventEmitter, Optional, Self, HostBinding, ViewChild, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { coerceBooleanProperty } from '@usitsdasdesign/dds-ng/core/utils';
import { DDS_FORM_CONTROL_GROUP, Themes, DDS_FORM_CONTROL, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
import { FormAccessorMixin, ErrorStateMixin } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import * as i5 from '@usitsdasdesign/dds-ng/core/focus-handler';
import { FocusHandlerDirective, FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import * as i6 from '@usitsdasdesign/dds-ng/core/theme';
import { ThemeMapEnum, ThemeModule } from '@usitsdasdesign/dds-ng/core/theme';
import * as i1$1 from '@angular/forms';
import * as i3 from '@usitsdasdesign/dds-ng/shared/dds-config';
const _c0 = ["*"];
const _c1 = a0 => ({
  "dds-custom-control_inverse": a0
});
function CheckboxGroupComponent_Conditional_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 1);
  }
}
function CheckboxGroupComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 0);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, CheckboxGroupComponent_Conditional_0_Conditional_2_Template, 1, 0, "span", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, ctx_r0.isInverse));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("", ctx_r0.title, " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.isRequired ? 2 : -1);
  }
}
const _c2 = ["checkboxField"];
const _c3 = [[["", "label", ""]]];
const _c4 = ["[label]"];
const _c5 = (a0, a1, a2, a3, a4) => ({
  "dds-custom-control_checked": a0,
  "dds-custom-control_indeterminate": a1,
  "dds-custom-control_disabled": a2,
  "dds-custom-control_danger": a3,
  "dds-custom-control_inverse": a4
});
const _c6 = a0 => ({
  "dds-custom-control__field_indeterminate": a0
});
const _c7 = (a0, a1) => ({
  "dds-icon_check": a0,
  "dds-icon_minus": a1
});
function CheckboxComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 5);
  }
}
class CheckboxGroupComponent {
  constructor() {
    this.isRequired = false;
    this.checkboxItems = [];
  }
  ngOnChanges(changes) {
    if (changes.isRequired) {
      this.isRequired = coerceBooleanProperty(changes.isRequired?.currentValue);
      this.setCheckboxesRequired(this.isRequired);
    }
    if (changes.isInverse) {
      this.setItemsInverse(changes.isInverse?.currentValue);
    }
  }
  ngAfterContentInit() {
    this.setCheckboxesRequired(this.isRequired);
    this.setItemsInverse(this.isInverse);
  }
  addCheckboxItem(checkbox) {
    this.checkboxItems.push(checkbox);
  }
  setCheckboxesRequired(isRequired) {
    this.checkboxItems.forEach(checkbox => {
      checkbox.isRequiredGroup = isRequired;
      checkbox.cdr.detectChanges();
    });
  }
  setItemsInverse(isInverse) {
    this.checkboxItems.forEach(checkbox => {
      checkbox.setInverse(isInverse);
    });
  }
  static {
    this.ɵfac = function CheckboxGroupComponent_Factory(t) {
      return new (t || CheckboxGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CheckboxGroupComponent,
      selectors: [["dds-checkbox-group"]],
      hostAttrs: ["role", "group"],
      inputs: {
        title: "title",
        isInverse: "isInverse",
        isRequired: "isRequired"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: DDS_FORM_CONTROL_GROUP,
        useExisting: CheckboxGroupComponent
      }]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 1,
      consts: [[1, "dds-custom-control__title", 3, "ngClass"], [1, "dds-required"]],
      template: function CheckboxGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, CheckboxGroupComponent_Conditional_0_Template, 3, 5, "span", 0);
          i0.ɵɵprojection(1);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.title ? 0 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxGroupComponent, [{
    type: Component,
    args: [{
      selector: 'dds-checkbox-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        role: 'group'
      },
      providers: [{
        provide: DDS_FORM_CONTROL_GROUP,
        useExisting: CheckboxGroupComponent
      }],
      standalone: true,
      imports: [CommonModule],
      template: "@if (title) {\r\n  <span [ngClass]=\"{\r\n        'dds-custom-control_inverse': isInverse\r\n  }\"\r\n        class=\"dds-custom-control__title\">{{title}}\r\n    @if (isRequired) {\r\n      <span class=\"dds-required\"></span>\r\n    }\r\n  </span>\r\n}\r\n<ng-content></ng-content>"
    }]
  }], null, {
    title: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    isRequired: [{
      type: Input
    }]
  });
})();
const themeClassesMap = {
  [ThemeMapEnum.Themes]: {
    [Themes.blue]: 'dds-custom-control_blue',
    [Themes.green]: 'dds-custom-control_green'
  }
};
const ARIA_CHECKED_MIXED = 'mixed';
const CheckboxBase = FormAccessorMixin(ErrorStateMixin());
class CheckboxComponent extends CheckboxBase {
  constructor(ngControl, parentForm, parentFormGroup, checkboxGroup, cdr, el, ddsConfig) {
    super();
    this.ngControl = ngControl;
    this.parentForm = parentForm;
    this.parentFormGroup = parentFormGroup;
    this.checkboxGroup = checkboxGroup;
    this.cdr = cdr;
    this.el = el;
    this.ddsConfig = ddsConfig;
    this.errorState = this.ddsConfig.getConfig().errorState;
    this.isRequired = false;
    this.theme = this.ddsConfig.getConfig().theme;
    this.stateChanged = new EventEmitter();
    this.themeConfig = themeClassesMap;
    this.isRequiredGroup = false;
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit() {
    super.ngOnInit();
    this.checkboxGroup?.addCheckboxItem(this);
  }
  ngOnChanges(changes) {
    if (changes.isRequired) {
      this.isRequired = coerceBooleanProperty(changes.isRequired.currentValue);
    }
    super.ngOnChanges(changes);
  }
  changeHandle(el) {
    if (this.isIndeterminate) {
      this.isIndeterminate = false;
      if (el) el.checked = false;
      this.value = false;
    } else {
      this.value = el ? el.checked : !this.value;
    }
    this.onModelChange(this.value);
    this.onModelTouched();
    this.stateChanged.emit(this.value);
    this.checkError();
  }
  writeValue(checked) {
    this.value = checked;
    if (checked === null && this.firstModelCheck) {
      this.isIndeterminate = false;
    }
    this.firstModelCheck = true;
    super.writeValue(checked);
    this.cdr.markForCheck();
  }
  setDisabledState(disabled) {
    this.disabled = disabled;
    this.cdr.markForCheck();
  }
  setInverse(isInverse) {
    this.isInverse = isInverse;
    this.cdr.detectChanges();
  }
  reset() {
    this.value = this.isIndeterminate = false;
    this.onModelChange(null);
    this.stateChanged.emit(null);
    this.checkError();
    this.cdr.markForCheck();
  }
  getCheckedValue() {
    return this.isIndeterminate ? ARIA_CHECKED_MIXED : !!this.value;
  }
  static {
    this.ɵfac = function CheckboxComponent_Factory(t) {
      return new (t || CheckboxComponent)(i0.ɵɵdirectiveInject(i1$1.NgControl, 10), i0.ɵɵdirectiveInject(i1$1.NgForm, 8), i0.ɵɵdirectiveInject(i1$1.FormGroupDirective, 8), i0.ɵɵdirectiveInject(CheckboxGroupComponent, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i3.DdsConfigService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CheckboxComponent,
      selectors: [["dds-checkbox"]],
      viewQuery: function CheckboxComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c2, 5);
          i0.ɵɵviewQuery(FocusHandlerDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.checkboxField = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.focusDir = _t.first);
        }
      },
      hostAttrs: [1, "dds-inline-flex"],
      hostVars: 2,
      hostBindings: function CheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("dds-disabled", ctx.disabled);
        }
      },
      inputs: {
        disabled: "disabled",
        value: "value",
        isError: "isError",
        isInverse: "isInverse",
        isIndeterminate: "isIndeterminate",
        errorState: "errorState",
        label: "label",
        ariaLabel: "ariaLabel",
        customClass: "customClass",
        isRequired: "isRequired",
        theme: "theme"
      },
      outputs: {
        stateChanged: "stateChanged"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: DDS_FORM_CONTROL,
        useExisting: CheckboxComponent
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c4,
      decls: 8,
      vars: 30,
      consts: [["checkboxField", ""], [3, "ngClass", "theme", "ddsTheme"], ["ddsFocus", "", "type", "checkbox", "data-qa", "checkbox", 1, "dds-custom-control__field", "dds-custom-control__field_checkbox", 3, "change", "ngClass", "disabled", "checked", "required"], [1, "dds-custom-control__icon", "dds-custom-control__icon_checkbox", "dds-icon", 3, "ngClass"], [1, "dds-custom-control__text"], [1, "dds-required"]],
      template: function CheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c3);
          i0.ɵɵelementStart(0, "label", 1)(1, "input", 2, 0);
          i0.ɵɵlistener("change", function CheckboxComponent_Template_input_change_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.changeHandle($event.target));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelement(3, "span", 3);
          i0.ɵɵelementStart(4, "span", 4);
          i0.ɵɵtext(5);
          i0.ɵɵprojection(6);
          i0.ɵɵtemplate(7, CheckboxComponent_Conditional_7_Template, 1, 0, "span", 5);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-custom-control ", ctx.customClass, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction5(19, _c5, ctx.value && !ctx.isIndeterminate, ctx.isIndeterminate, ctx.disabled, ctx._isError, ctx.isInverse))("theme", ctx.theme)("ddsTheme", ctx.themeConfig);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(25, _c6, ctx.isIndeterminate))("disabled", ctx.disabled)("checked", ctx.value && !ctx.isIndeterminate)("required", ctx.isRequired || ctx.isRequiredGroup);
          i0.ɵɵattribute("value", ctx.label)("aria-label", ctx.ariaLabel || ctx.label)("aria-disabled", ctx.disabled)("aria-checked", ctx.getCheckedValue())("aria-required", ctx.isRequired || ctx.isRequiredGroup || ctx._isFormControlRequired)("aria-invalid", ctx._isError);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(27, _c7, ctx.value && !ctx.isIndeterminate, ctx.isIndeterminate));
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" ", ctx.label, " ");
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.isRequired ? 7 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgClass, FocusHandlerModule, i5.FocusHandlerDirective, ThemeModule, i6.ThemeDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'dds-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: AdditionalClass.inlineFlex
      },
      providers: [{
        provide: DDS_FORM_CONTROL,
        useExisting: CheckboxComponent
      }],
      standalone: true,
      imports: [CommonModule, FocusHandlerModule, ThemeModule],
      template: "<label [ngClass]=\"{\r\n        'dds-custom-control_checked': value && !isIndeterminate,\r\n        'dds-custom-control_indeterminate': isIndeterminate,\r\n        'dds-custom-control_disabled': disabled,\r\n        'dds-custom-control_danger': _isError,\r\n        'dds-custom-control_inverse': isInverse\r\n       }\"\r\n       [theme]=\"theme\"\r\n       [ddsTheme]=\"themeConfig\"\r\n       class=\"dds-custom-control {{customClass}}\">\r\n  <input [ngClass]=\"{\r\n          'dds-custom-control__field_indeterminate': isIndeterminate\r\n         }\"\r\n         [disabled]=\"disabled\"\r\n         [checked]=\"value && !isIndeterminate\"\r\n         [attr.value]=\"label\"\r\n         ddsFocus\r\n         class=\"dds-custom-control__field dds-custom-control__field_checkbox\"\r\n         type=\"checkbox\"\r\n         data-qa=\"checkbox\"\r\n         [attr.aria-label]=\"ariaLabel || label\"\r\n         [attr.aria-disabled]=\"disabled\"\r\n         [attr.aria-checked]=\"getCheckedValue()\"\r\n         [attr.aria-required]=\"isRequired || isRequiredGroup || _isFormControlRequired\"\r\n         [attr.aria-invalid]=\"_isError\"\r\n         [required]=\"isRequired || isRequiredGroup\"\r\n         (change)=\"changeHandle($event.target)\"\r\n         #checkboxField />\r\n\r\n  <span [ngClass]=\"{\r\n          'dds-icon_check': value && !isIndeterminate,\r\n          'dds-icon_minus': isIndeterminate\r\n        }\"\r\n        class=\"dds-custom-control__icon dds-custom-control__icon_checkbox dds-icon\"></span>\r\n\r\n  <span class=\"dds-custom-control__text\">\r\n    {{ label }}\r\n\r\n    <ng-content select=\"[label]\"></ng-content>\r\n\r\n    @if (isRequired) {\r\n      <span class=\"dds-required\"></span>\r\n    }\r\n  </span>\r\n\r\n</label>"
    }]
  }], () => [{
    type: i1$1.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }]
  }, {
    type: i1$1.NgForm,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i1$1.FormGroupDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: CheckboxGroupComponent,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i3.DdsConfigService
  }], {
    disabled: [{
      type: HostBinding,
      args: [`class.${AdditionalClass.disabled}`]
    }, {
      type: Input
    }],
    checkboxField: [{
      type: ViewChild,
      args: ['checkboxField']
    }],
    focusDir: [{
      type: ViewChild,
      args: [FocusHandlerDirective]
    }],
    value: [{
      type: Input
    }],
    isError: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    isIndeterminate: [{
      type: Input
    }],
    errorState: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    isRequired: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    stateChanged: [{
      type: Output
    }]
  });
})();
class CheckboxModule {
  static {
    this.ɵfac = function CheckboxModule_Factory(t) {
      return new (t || CheckboxModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CheckboxModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CheckboxComponent, CheckboxGroupComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CheckboxComponent, CheckboxGroupComponent],
      exports: [CheckboxComponent, CheckboxGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CheckboxComponent, CheckboxGroupComponent, CheckboxModule };
