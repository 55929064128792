import { Component, ElementRef, inject, OnInit, Renderer2 } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { SideBarItem } from '../../model/side-bar';


@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.sass'
})
export class SideBarComponent implements OnInit{
  private router: Router = inject(Router);
  private renderer: Renderer2 = inject(Renderer2);
  private elRef: ElementRef = inject(ElementRef);
  
  public collapsed: boolean = false;
  private userRole:string = ''
  
  roles = [
            {
              roleId:'1',
              roleName:'Admin App'
            },
            {
              roleId:'2',
              roleName:'Admin User'
            },
            {
              roleId:'3',
              roleName:'General User'
            }
          ]
  
  menuOptions: SideBarItem[] =  [
    {
      label:'Dashboard',
      route:'/',
      forRole:['1','2','3'],
      isActive:false
    },
    {
      label: 'Deloitte Skills Taxonomy',
      route:'/skill-taxonomy',
      forRole:['1','2'],
      isActive:false
    },
    {
      label:'Market Library',
      route:'/market-library',
      forRole:['1','2'],
      isActive:false
    },
    {
      label:'Pending Changes',
      route:'/pending-changes',
      forRole:['1','2'],
      isActive:false
    },
    {
      label:'Audit Log',
      route:'/audit-log',
      forRole:['1'],
      isActive:false
    },
    {
      label:'User Management',
      route:'/user-management',
      forRole:['1'],
      isActive:false
    }
  ]

  filteredMenuOptions: SideBarItem[] = []
  
  constructor() { 
    //localStorage.setItem('role','2') //set the role to test the filterMenuOptionsForRole function
  }
  
  ngOnInit(): void {
    this.filterMenuOptionsForRole();
  }

  ngAfterViewInit(): void {
    this.setElementAttribute();
  }

  toggle(){
    this.collapsed = !this.collapsed;
    this.setElementAttribute();
  }

  setElementAttribute() {
    if (this.collapsed)
      this.renderer.setAttribute(this.elRef.nativeElement, 'class', 'collapsed');
    else
      this.renderer.removeAttribute(this.elRef.nativeElement, 'class');
  }

  filterMenuOptionsForRole():void{
    this.userRole=localStorage.getItem('role') ||''
    this.menuOptions.forEach((item:SideBarItem) => {
      if(item.forRole.includes(this.userRole)){
        this.filteredMenuOptions.push(item)
      }
    })
  }
 
  routeIsActive(routePath: string) {
    return this.router.url === routePath;
 }
}
