import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, HostBinding, Input, NgModule } from '@angular/core';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import { DestroyMixin, I18nMixin } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import * as i4 from '@usitsdasdesign/dds-ng/core/theme';
import { ThemeMapEnum, ThemeModule } from '@usitsdasdesign/dds-ng/core/theme';
import { SpinnerThemes, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
import * as i1 from '@usitsdasdesign/dds-ng/shared/i18n';
import { I18nModule } from '@usitsdasdesign/dds-ng/shared/i18n';
import * as i2 from '@usitsdasdesign/dds-ng/shared/dds-config';
const _c0 = (a0, a1) => ({
  "width": a0,
  "height": a1
});
const _c1 = a0 => ({
  "dds-spinner_inverse": a0
});
const THICKNESS = '4px';
const themeClassesMap = {
  [ThemeMapEnum.Themes]: {
    [SpinnerThemes.green]: 'dds-spinner_green',
    [SpinnerThemes.dark]: 'dds-spinner_dark',
    [SpinnerThemes.blue]: 'dds-spinner_blue',
    [SpinnerThemes.danger]: 'dds-spinner_danger',
    [SpinnerThemes.gray]: 'dds-spinner_gray'
  }
};
const SpinnerBase = DestroyMixin(I18nMixin());
class SpinnerComponent extends SpinnerBase {
  constructor(cdr, i18n, ddsConfig) {
    super();
    this.cdr = cdr;
    this.i18n = i18n;
    this.ddsConfig = ddsConfig;
    this.class = AdditionalClass.flex;
    this.theme = this.ddsConfig.getConfig().theme;
    this.thickness = THICKNESS;
    this.themeConfig = themeClassesMap;
  }
  setI18nTexts(translation) {
    this.ariaLabel = translation.SPINNER.A11Y.ARIA_LABEL;
    this.cdr.detectChanges();
  }
  static {
    this.ɵfac = function SpinnerComponent_Factory(t) {
      return new (t || SpinnerComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.I18nService), i0.ɵɵdirectiveInject(i2.DdsConfigService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SpinnerComponent,
      selectors: [["dds-spinner"]],
      hostVars: 2,
      hostBindings: function SpinnerComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.class);
        }
      },
      inputs: {
        theme: "theme",
        thickness: "thickness",
        size: "size",
        customClass: "customClass",
        isInverse: "isInverse"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 15,
      consts: [["data-qa", "spinner", "aria-busy", "true", "aria-live", "polite", "role", "alert", 3, "ngStyle", "theme", "ddsTheme", "ngClass"], ["viewBox", "25 25 50 50", 1, "dds-spinner__circle"], ["cx", "50", "cy", "50", "r", "20", "fill", "none", "stroke-miterlimit", "10", 1, "dds-spinner__line"]],
      template: function SpinnerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(1, "svg", 1);
          i0.ɵɵelement(2, "circle", 2);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-spinner ", ctx.customClass, "");
          i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(10, _c0, ctx.size, ctx.size))("theme", ctx.theme)("ddsTheme", ctx.themeConfig)("ngClass", i0.ɵɵpureFunction1(13, _c1, ctx.isInverse));
          i0.ɵɵattribute("aria-label", ctx.ariaLabel);
          i0.ɵɵadvance(2);
          i0.ɵɵstyleProp("stroke-width", ctx.thickness);
        }
      },
      dependencies: [CommonModule, i3.NgClass, i3.NgStyle, ThemeModule, i4.ThemeDirective, I18nModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerComponent, [{
    type: Component,
    args: [{
      selector: 'dds-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      standalone: true,
      imports: [CommonModule, ThemeModule, I18nModule],
      template: "<div [ngStyle]=\"{'width': size, 'height': size}\"\r\n     [theme]=\"theme\"\r\n     [ddsTheme]=\"themeConfig\"\r\n     [ngClass]=\"{'dds-spinner_inverse': isInverse}\"\r\n     data-qa=\"spinner\"\r\n     aria-busy=\"true\"\r\n     aria-live=\"polite\"\r\n     [attr.aria-label]=\"ariaLabel\"\r\n     role=\"alert\"\r\n     class=\"dds-spinner {{customClass}}\">\r\n  <svg class=\"dds-spinner__circle\"\r\n       viewBox=\"25 25 50 50\">\r\n    <circle [style.stroke-width]=\"thickness\"\r\n            class=\"dds-spinner__line\"\r\n            cx=\"50\"\r\n            cy=\"50\"\r\n            r=\"20\"\r\n            fill=\"none\"\r\n            stroke-miterlimit=\"10\"></circle>\r\n  </svg>\r\n</div>"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i1.I18nService
  }, {
    type: i2.DdsConfigService
  }], {
    class: [{
      type: HostBinding,
      args: ['class']
    }],
    theme: [{
      type: Input
    }],
    thickness: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }]
  });
})();
class SpinnerModule {
  static {
    this.ɵfac = function SpinnerModule_Factory(t) {
      return new (t || SpinnerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SpinnerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [SpinnerComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [SpinnerComponent],
      exports: [SpinnerComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SpinnerComponent, SpinnerModule };
