import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, HostBinding, Input, EventEmitter, Optional, Self, ViewChild, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { coerceBooleanProperty } from '@usitsdasdesign/dds-ng/core/utils';
import { DDS_FORM_CONTROL_GROUP, Themes, SpinnerThemes, DDS_FORM_CONTROL, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
import { FormAccessorMixin, ErrorStateMixin } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import * as i5 from '@usitsdasdesign/dds-ng/core/focus-handler';
import { FocusHandlerDirective, FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import * as i7 from '@usitsdasdesign/dds-ng/core/theme';
import { ThemeMapEnum, ThemeModule } from '@usitsdasdesign/dds-ng/core/theme';
import * as i6 from '@usitsdasdesign/dds-ng/spinner';
import { SpinnerModule } from '@usitsdasdesign/dds-ng/spinner';
import * as i1$1 from '@angular/forms';
import * as i2 from '@usitsdasdesign/dds-ng/shared/dds-config';
const _c0 = ["*"];
const _c1 = a0 => ({
  "dds-custom-control_inverse": a0
});
function ToggleGroupComponent_Conditional_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 1);
  }
}
function ToggleGroupComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 0);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, ToggleGroupComponent_Conditional_0_Conditional_2_Template, 1, 0, "span", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, ctx_r0.isInverse));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("", ctx_r0.title, " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.isRequired ? 2 : -1);
  }
}
const _c2 = ["toggleInput"];
const _c3 = (a0, a1, a2, a3) => ({
  "dds-toggle_disabled": a0,
  "dds-toggle_processing-status": a1,
  "dds-toggle_inverse": a2,
  "dds-toggle_danger": a3
});
const _c4 = a0 => ({
  "dds-toggle__switcher_active": a0
});
function ToggleComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "dds-spinner", 4);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("size", ctx_r1.spinnerOptions.size)("theme", ctx_r1.spinnerOptions.theme);
  }
}
function ToggleComponent_Conditional_7_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 8);
  }
}
function ToggleComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 7);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, ToggleComponent_Conditional_7_Conditional_2_Template, 1, 0, "span", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("", ctx_r1.label, " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.isRequired ? 2 : -1);
  }
}
class ToggleGroupComponent {
  constructor() {
    this.hostRole = 'group';
    this.isRequired = false;
    this.toggleItems = [];
  }
  ngOnChanges(changes) {
    if (changes.isRequired) {
      this.isRequired = coerceBooleanProperty(changes.isRequired.currentValue);
      this.setToggleRequired(this.isRequired);
    }
    if (changes.isInverse) {
      this.setItemsInverse(changes.isInverse?.currentValue);
    }
  }
  ngAfterContentInit() {
    this.setToggleRequired(this.isRequired);
    this.setItemsInverse(this.isInverse);
  }
  addToggleItem(Toggle) {
    this.toggleItems.push(Toggle);
  }
  setToggleRequired(isRequired) {
    this.toggleItems.forEach(toggle => {
      toggle.isRequiredGroup = isRequired;
      toggle.cdr.detectChanges();
    });
  }
  setItemsInverse(isInverse) {
    this.toggleItems.forEach(toggle => {
      toggle.setInverse(isInverse);
    });
  }
  static {
    this.ɵfac = function ToggleGroupComponent_Factory(t) {
      return new (t || ToggleGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ToggleGroupComponent,
      selectors: [["dds-toggle-group"]],
      hostVars: 1,
      hostBindings: function ToggleGroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("role", ctx.hostRole);
        }
      },
      inputs: {
        title: "title",
        isInverse: "isInverse",
        isRequired: "isRequired"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: DDS_FORM_CONTROL_GROUP,
        useExisting: ToggleGroupComponent
      }]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 1,
      consts: [[1, "dds-custom-control__title", 3, "ngClass"], [1, "dds-required"]],
      template: function ToggleGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, ToggleGroupComponent_Conditional_0_Template, 3, 5, "span", 0);
          i0.ɵɵprojection(1);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.title ? 0 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToggleGroupComponent, [{
    type: Component,
    args: [{
      selector: 'dds-toggle-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: DDS_FORM_CONTROL_GROUP,
        useExisting: ToggleGroupComponent
      }],
      standalone: true,
      imports: [CommonModule],
      template: "@if (title) {\r\n  <span [ngClass]=\"{\r\n            'dds-custom-control_inverse': isInverse\r\n      }\"\r\n        class=\"dds-custom-control__title\">{{title}}\r\n    @if (isRequired) {\r\n      <span class=\"dds-required\"></span>\r\n    }\r\n  </span>\r\n}\r\n<ng-content></ng-content>"
    }]
  }], null, {
    hostRole: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    title: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    isRequired: [{
      type: Input
    }]
  });
})();
const TOGGLE_SPINNER_SIZE = '10px';
const themeClassesMap = {
  [ThemeMapEnum.Themes]: {
    [Themes.dark]: 'dds-toggle_dark',
    [Themes.blue]: 'dds-toggle_blue'
  }
};
const ToggleBase = FormAccessorMixin(ErrorStateMixin());
class ToggleComponent extends ToggleBase {
  constructor(ngControl, parentForm, parentFormGroup, cdr, el, ddsConfig, toggleGroup) {
    super();
    this.ngControl = ngControl;
    this.parentForm = parentForm;
    this.parentFormGroup = parentFormGroup;
    this.cdr = cdr;
    this.el = el;
    this.ddsConfig = ddsConfig;
    this.toggleGroup = toggleGroup;
    this.isFlex = true;
    this.theme = this.ddsConfig.getConfig().theme;
    this.isRequired = false;
    this.errorState = this.ddsConfig.getConfig().errorState;
    this.stateChanged = new EventEmitter();
    this.processingStateActivated = new EventEmitter();
    this.spinnerOptions = {
      size: TOGGLE_SPINNER_SIZE,
      theme: SpinnerThemes.gray
    };
    this.themeConfig = themeClassesMap;
    this.isRequiredGroup = false;
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit() {
    super.ngOnInit();
    this.toggleGroup?.addToggleItem(this);
  }
  ngOnChanges(changes) {
    if (changes.isRequired) {
      this.isRequired = coerceBooleanProperty(changes.isRequired.currentValue);
    }
    super.ngOnChanges(changes);
  }
  toggleState(success = false) {
    if (this.isProcessingStatusActive) {
      if (!this.processingStatus) {
        this.processingStatus = true;
        this.processingStateActivated.emit(!this.value);
      } else {
        if (success) {
          this.changeHandle();
        }
        this.processingStatus = false;
        this.cdr.markForCheck();
      }
    } else {
      this.changeHandle();
    }
    this.checkError();
    this.onModelTouched();
  }
  changeHandle() {
    this.value = !this.value;
    this.onModelChange(this.value);
    this.stateChanged.emit(this.value);
    this.cdr.markForCheck();
  }
  writeValue(active) {
    this.value = active;
    super.writeValue(active);
    this.cdr.markForCheck();
  }
  setDisabledState(disabled) {
    this.disabled = disabled;
    this.cdr.markForCheck();
  }
  setInverse(isInverse) {
    this.isInverse = isInverse;
    this.cdr.detectChanges();
  }
  reset() {
    this.value = false;
    this.checkError();
    this.onModelChange(null);
    this.stateChanged.emit(null);
    this.cdr.markForCheck();
  }
  static {
    this.ɵfac = function ToggleComponent_Factory(t) {
      return new (t || ToggleComponent)(i0.ɵɵdirectiveInject(i1$1.NgControl, 10), i0.ɵɵdirectiveInject(i1$1.NgForm, 8), i0.ɵɵdirectiveInject(i1$1.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.DdsConfigService), i0.ɵɵdirectiveInject(ToggleGroupComponent, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ToggleComponent,
      selectors: [["dds-toggle"]],
      viewQuery: function ToggleComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c2, 5);
          i0.ɵɵviewQuery(FocusHandlerDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.toggleInput = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.focusDir = _t.first);
        }
      },
      hostVars: 4,
      hostBindings: function ToggleComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("dds-flex", ctx.isFlex)("dds-disabled", ctx.disabled);
        }
      },
      inputs: {
        disabled: "disabled",
        label: "label",
        ariaLabel: "ariaLabel",
        customClass: "customClass",
        theme: "theme",
        isInverse: "isInverse",
        isError: "isError",
        isRequired: "isRequired",
        errorState: "errorState",
        isProcessingStatusActive: "isProcessingStatusActive",
        value: "value"
      },
      outputs: {
        stateChanged: "stateChanged",
        processingStateActivated: "processingStateActivated"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: DDS_FORM_CONTROL,
        useExisting: ToggleComponent
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 8,
      vars: 24,
      consts: [["toggleInput", ""], [3, "ngClass", "theme", "ddsTheme"], ["role", "switch", "ddsFocus", "", "type", "checkbox", 1, "dds-toggle__input", 3, "change", "keydown.enter", "checked", "value", "disabled", "required"], [1, "dds-toggle__switcher", 3, "ngClass"], [1, "dds-toggle__spinner", 3, "size", "theme"], [1, "dds-toggle__circle"], [1, "dds-toggle__line"], [1, "dds-toggle__title"], [1, "dds-required"]],
      template: function ToggleComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "label", 1)(1, "input", 2, 0);
          i0.ɵɵlistener("change", function ToggleComponent_Template_input_change_1_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.toggleState());
          })("keydown.enter", function ToggleComponent_Template_input_keydown_enter_1_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.toggleState());
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵtemplate(4, ToggleComponent_Conditional_4_Template, 1, 2, "dds-spinner", 4);
          i0.ɵɵelement(5, "span", 5)(6, "span", 6);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(7, ToggleComponent_Conditional_7_Template, 3, 2, "span", 7);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-toggle ", ctx.customClass, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction4(17, _c3, ctx.disabled, ctx.processingStatus, ctx.isInverse, ctx._isError))("theme", ctx.theme)("ddsTheme", ctx.themeConfig);
          i0.ɵɵadvance();
          i0.ɵɵproperty("checked", ctx.value)("value", ctx.label)("disabled", ctx.disabled)("required", ctx.isRequired || ctx.isRequiredGroup);
          i0.ɵɵattribute("aria-disabled", ctx.disabled)("aria-required", ctx.isRequired || ctx.isRequiredGroup || ctx._isFormControlRequired)("aria-invalid", ctx._isError)("aria-label", ctx.ariaLabel || ctx.label);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(22, _c4, ctx.value));
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.processingStatus ? 4 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.label ? 7 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgClass, FocusHandlerModule, i5.FocusHandlerDirective, SpinnerModule, i6.SpinnerComponent, ThemeModule, i7.ThemeDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToggleComponent, [{
    type: Component,
    args: [{
      selector: 'dds-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: DDS_FORM_CONTROL,
        useExisting: ToggleComponent
      }],
      standalone: true,
      imports: [CommonModule, FocusHandlerModule, SpinnerModule, ThemeModule],
      template: "<label [ngClass]=\"{\r\n         'dds-toggle_disabled': disabled,\r\n         'dds-toggle_processing-status': processingStatus,\r\n         'dds-toggle_inverse': isInverse,\r\n         'dds-toggle_danger': _isError\r\n       }\"\r\n       [theme]=\"theme\"\r\n       [ddsTheme]=\"themeConfig\"\r\n       class=\"dds-toggle {{customClass}}\">\r\n  <input [checked]=\"value\"\r\n         [value]=\"label\"\r\n         [disabled]=\"disabled\"\r\n         [attr.aria-disabled]=\"disabled\"\r\n         [attr.aria-required]=\"isRequired || isRequiredGroup || _isFormControlRequired\"\r\n         [attr.aria-invalid]=\"_isError\"\r\n         [attr.aria-label]=\"ariaLabel || label\"\r\n         [required]=\"isRequired || isRequiredGroup\"\r\n         role=\"switch\"\r\n         ddsFocus\r\n         class=\"dds-toggle__input\"\r\n         type=\"checkbox\"\r\n         (change)=\"toggleState()\"\r\n         (keydown.enter)=\"toggleState()\"\r\n         #toggleInput />\r\n  <div [ngClass]=\"{\r\n         'dds-toggle__switcher_active': value\r\n       }\"\r\n       class=\"dds-toggle__switcher\">\r\n    @if (processingStatus) {\r\n      <dds-spinner [size]=\"spinnerOptions.size\"\r\n                   [theme]=\"spinnerOptions.theme\"\r\n                   class=\"dds-toggle__spinner\"></dds-spinner>\r\n    }\r\n    <span class=\"dds-toggle__circle\"></span>\r\n    <span class=\"dds-toggle__line\"></span>\r\n  </div>\r\n\r\n  @if (label) {\r\n    <span class=\"dds-toggle__title\">{{ label }}\r\n      @if (isRequired) {\r\n        <span class=\"dds-required\"></span>\r\n      }\r\n    </span>\r\n  }\r\n</label>"
    }]
  }], () => [{
    type: i1$1.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }]
  }, {
    type: i1$1.NgForm,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i1$1.FormGroupDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i2.DdsConfigService
  }, {
    type: ToggleGroupComponent,
    decorators: [{
      type: Optional
    }]
  }], {
    isFlex: [{
      type: HostBinding,
      args: [`class.${AdditionalClass.flex}`]
    }],
    disabled: [{
      type: HostBinding,
      args: [`class.${AdditionalClass.disabled}`]
    }, {
      type: Input
    }],
    toggleInput: [{
      type: ViewChild,
      args: ['toggleInput']
    }],
    focusDir: [{
      type: ViewChild,
      args: [FocusHandlerDirective]
    }],
    label: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    isError: [{
      type: Input
    }],
    isRequired: [{
      type: Input
    }],
    errorState: [{
      type: Input
    }],
    isProcessingStatusActive: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    stateChanged: [{
      type: Output
    }],
    processingStateActivated: [{
      type: Output
    }]
  });
})();
class ToggleModule {
  static {
    this.ɵfac = function ToggleModule_Factory(t) {
      return new (t || ToggleModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ToggleModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [ToggleComponent, ToggleGroupComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToggleModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [ToggleComponent, ToggleGroupComponent],
      exports: [ToggleComponent, ToggleGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ToggleComponent, ToggleGroupComponent, ToggleModule };
