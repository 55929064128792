<div class="side-bar-wrapper" role="navigation">
    <div class="title">
        <span role="button" tabindex="0" (click)="toggle()" class="icon dds-btn__icon dds-icon"
        (keydown.enter)="toggle()"
            [attr.aria-label]="collapsed ? 'Open Sidebar' : 'Close Sidebar'"
            [class.dds-icon_arrow-right]="collapsed"
            [class.dds-icon_arrow-left]="!collapsed"></span>
    </div>
    @for (item of filteredMenuOptions; track item.label){
        <a class="menu-item" [class.active]="routeIsActive(item.route)" [routerLink]="item.route" [ariaLabel]="item.label">{{item.label}}</a>
    }
</div>
