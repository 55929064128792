<div class="skill-changes-wrapper">
	<h3>Skill Changes</h3>
	<hr />
  @if(fullPCData.requestTypeId === 3 || fullPCData.requestTypeId === 4 ){
    @for(item of changesList; track item.label){
    @if(item.label != 'Tags'){
    <app-pending-change-item [Item]="item"></app-pending-change-item>
    }@else if(addedTags.length > 0 || removedTags.length > 0){
    <label class="tags-title">
      TAGs
    </label>
    @if(addedTags.length > 0){
    <p class="tags-collection-title">NEW</p>
    <div class="tags-container">
      @for(tag of addedTags; track tag){
      <span class="pseudo-tag new">{{tag}}</span>
      }
    </div>
    }
    @if(removedTags.length > 0){
    <p class="tags-collection-title">REMOVED</p>
    <div class="tags-container">
      @for(tag of removedTags; track tag){
      <span class="pseudo-tag">{{tag}}</span>
      }
    </div>
    }
    }
    }
  }

</div>
