import { Component, Input } from '@angular/core';
import { FullAuditLogData } from '../../../../shared/model/full-audit-log-data';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-audit-log-information',
	standalone: true,
  imports: [CommonModule],
	templateUrl: './audit-log-information.component.html',
	styleUrl: './audit-log-information.component.sass'
})
export class AuditLogInformationComponent {
  showFullRequestorText: boolean = false;
  showFullProcessorText: boolean = false;
  isLongText: boolean = false;
  @Input() auditInformation!: FullAuditLogData;

  showMoreVisible(value1: string) {
    if (value1) {
      let charCount = value1.length;
      this.isLongText = charCount > 150;
      if (this.isLongText) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toggleText(value: string): void {
    value === 'Requestor' ? this.showFullRequestorText = !this.showFullRequestorText : this.showFullProcessorText = !this.showFullProcessorText;
  }

}

