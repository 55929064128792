import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { ModalModule } from '@usitsdasdesign/dds-ng/modal';
import { MsalApplicationModule } from './msal-application.module';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { Observable, of, tap } from 'rxjs';
import { AuthorizeInterceptor } from './shared/interceptors/token.interceptor';
import { HttpAuthInterceptor } from './shared/interceptors/http-auth.interceptor';
import { MsalService } from '@azure/msal-angular';
import { ToastService } from '@usitsdasdesign/dds-ng/toast';
import { JwtService } from './shared/services/jwt.service';

export function initializeApp(http: HttpClient, jwtService: JwtService) {
  //`${environment.apiUrl}/User/GetRole?userMail=gsalbucci@deloitte.com`
  // let hasToken: any = jwtService.hasToken();
  // if(hasToken) {
  //   return (): Observable<any> => http.get(`${environment.apiUrl}/User/GetRole?userMail=${jwtService.decodeToken().preferred_username}`)
  //     //role id y userID setearla en el local storage.

  //     .pipe(tap((data:any) =>
  //       {
  //         localStorage.setItem('role', data[0].roleId);
  //         localStorage.setItem('userId', data[0].userId);
  //         localStorage.setItem('roleName', data[0].rol);
  //         localStorage.setItem('name', jwtService.decodeToken().name);
  //       }));
  // } else {
  return (): Observable<any> => of(true)
  //}
}

export const appConfig: ApplicationConfig = {
  providers: [
    ToastService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    importProvidersFrom(CommonModule, BrowserModule, ModalModule, MsalApplicationModule.forRoot()),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [HttpClient, JwtService]
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthorizeInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpAuthInterceptor,
    //   multi: true,
    //   deps: [MsalService, ToastService]
    // }
  ]
};
