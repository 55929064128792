import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, HostBinding, Input, EventEmitter, ViewChild, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@usitsdasdesign/dds-ng/core/focus-handler';
import { FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import * as i4 from '@usitsdasdesign/dds-ng/core/theme';
import { ThemeMapEnum, ThemeModule } from '@usitsdasdesign/dds-ng/core/theme';
import { Size, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
import * as i1 from '@usitsdasdesign/dds-ng/shared/dds-config';
const _c0 = ["*"];
const _c1 = a0 => ({
  "dds-accordion_inverse": a0
});
const _c2 = ["accordionBtnRef"];
const _c3 = [[["", "header", ""]], [["", "content", ""]]];
const _c4 = ["[header]", "[content]"];
const _c5 = a0 => ({
  "dds-accordion__item_open": a0
});
const _c6 = a0 => ({
  "dds-accordion__header_disabled": a0
});
var AccordionClass;
(function (AccordionClass) {
  AccordionClass["wrap"] = "dds-accordion-wrap";
})(AccordionClass || (AccordionClass = {}));
const themeClassesMap = {
  [ThemeMapEnum.Size]: {
    [Size.lg]: 'dds-accordion__item_lg',
    [Size.sm]: 'dds-accordion__item_sm'
  }
};
const DEFAULT_ICON = 'dds-icon_arrow-down';
class AccordionComponent {
  constructor(ddsConfig) {
    this.ddsConfig = ddsConfig;
    this.size = this.ddsConfig.getConfig().size;
    this.accordionItems = [];
  }
  ngOnChanges(changes) {
    const changesArr = Object.keys(changes);
    changesArr.forEach(change => {
      this.updateItemsOption(change, changes[change].currentValue);
    });
  }
  addAccordionItem(accordionItem) {
    this.accordionItems.push(accordionItem);
  }
  removeAccordionItem(accordionItem) {
    const index = this.accordionItems.indexOf(accordionItem);
    if (index !== -1) {
      this.accordionItems.splice(index, 1);
    }
  }
  closeAccordionItem(openAccordionItem) {
    this.accordionItems.forEach(accordionItem => {
      if (accordionItem !== openAccordionItem) {
        accordionItem.isOpen = false;
        accordionItem.cdr.detectChanges();
      }
    });
  }
  closeAll() {
    this.accordionItems.forEach(accordionItem => {
      accordionItem.isOpen = false;
      accordionItem.itemChanged.emit(accordionItem);
      accordionItem.cdr.detectChanges();
    });
  }
  updateItemsOption(option, value) {
    this.accordionItems.forEach(accordionItem => {
      accordionItem[option] = value;
      accordionItem.cdr.detectChanges();
    });
  }
  static {
    this.ɵfac = function AccordionComponent_Factory(t) {
      return new (t || AccordionComponent)(i0.ɵɵdirectiveInject(i1.DdsConfigService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AccordionComponent,
      selectors: [["dds-accordion"]],
      hostVars: 2,
      hostBindings: function AccordionComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("dds-disabled", ctx.disabled);
        }
      },
      inputs: {
        disabled: "disabled",
        size: "size",
        customClass: "customClass",
        isOpen: "isOpen",
        isMulti: "isMulti",
        isInverse: "isInverse"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 6,
      consts: [["role", "list", 3, "ngClass"]],
      template: function AccordionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-accordion ", ctx.customClass, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(4, _c1, ctx.isInverse));
        }
      },
      dependencies: [CommonModule, i2.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionComponent, [{
    type: Component,
    args: [{
      selector: 'dds-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [CommonModule],
      template: "<div class=\"dds-accordion {{customClass}}\"\r\n     [ngClass]=\"{\r\n        'dds-accordion_inverse': isInverse\r\n      }\"\r\n     role=\"list\">\r\n  <ng-content></ng-content>\r\n</div>"
    }]
  }], () => [{
    type: i1.DdsConfigService
  }], {
    disabled: [{
      type: HostBinding,
      args: [`class.${AdditionalClass.disabled}`]
    }, {
      type: Input
    }],
    size: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    isOpen: [{
      type: Input
    }],
    isMulti: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }]
  });
})();
class AccordionItemComponent {
  constructor(cdr, accordion) {
    this.cdr = cdr;
    this.accordion = accordion;
    this.class = AccordionClass.wrap;
    this.icon = DEFAULT_ICON;
    this.itemChanged = new EventEmitter();
    this.themeConfig = themeClassesMap;
  }
  ngOnInit() {
    this.accordion.addAccordionItem(this);
    this.setValueFromParent();
  }
  ngOnDestroy() {
    this.accordion.removeAccordionItem(this);
  }
  selectAccordionItem() {
    if (!this.isMulti) {
      this.accordion.closeAccordionItem(this);
    }
    this.isOpen = !this.isOpen;
    this.itemChanged.emit(this);
  }
  setValueFromParent() {
    this.disabled ||= this.accordion.disabled;
    this.size ||= this.accordion.size;
    this.isOpen ||= this.accordion.isOpen;
    this.isMulti ||= this.accordion.isMulti;
    this.isInverse ||= this.accordion.isInverse;
    this.cdr.detectChanges();
  }
  static {
    this.ɵfac = function AccordionItemComponent_Factory(t) {
      return new (t || AccordionItemComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(AccordionComponent));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AccordionItemComponent,
      selectors: [["dds-accordion-item"]],
      viewQuery: function AccordionItemComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c2, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.accordionBtnRef = _t.first);
        }
      },
      hostVars: 4,
      hostBindings: function AccordionItemComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.class);
          i0.ɵɵclassProp("dds-disabled", ctx.disabled);
        }
      },
      inputs: {
        disabled: "disabled",
        size: "size",
        customClass: "customClass",
        icon: "icon",
        isOpen: "isOpen",
        isMulti: "isMulti"
      },
      outputs: {
        itemChanged: "itemChanged"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c4,
      decls: 8,
      vars: 17,
      consts: [["accordionBtnRef", ""], ["role", "listitem", 3, "ngClass", "size", "ddsTheme"], ["ddsFocus", "", 1, "dds-accordion__header", 3, "click", "disabled", "ngClass"], [1, "dds-accordion__header-wrap"], ["role", "presentation", "action", ""], ["role", "region", 1, "dds-accordion__body"]],
      template: function AccordionItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c3);
          i0.ɵɵelementStart(0, "div", 1)(1, "button", 2, 0);
          i0.ɵɵlistener("click", function AccordionItemComponent_Template_button_click_1_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.selectAccordionItem());
          });
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(5, "span", 4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(6, "div", 5);
          i0.ɵɵprojection(7, 1);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-accordion__item ", ctx.customClass, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(13, _c5, ctx.isOpen))("size", ctx.size)("ddsTheme", ctx.themeConfig);
          i0.ɵɵadvance();
          i0.ɵɵproperty("disabled", ctx.disabled)("ngClass", i0.ɵɵpureFunction1(15, _c6, ctx.disabled));
          i0.ɵɵattribute("aria-expanded", ctx.isOpen)("aria-disabled", ctx.disabled);
          i0.ɵɵadvance(4);
          i0.ɵɵclassMapInterpolate1("dds-accordion__icon dds-icon ", ctx.icon, "");
        }
      },
      dependencies: [CommonModule, i2.NgClass, FocusHandlerModule, i3.FocusHandlerDirective, ThemeModule, i4.ThemeDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionItemComponent, [{
    type: Component,
    args: [{
      selector: 'dds-accordion-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [CommonModule, FocusHandlerModule, ThemeModule],
      template: "<div [ngClass]=\"{\r\n      'dds-accordion__item_open': isOpen\r\n     }\"\r\n     [size]=\"size\"\r\n     [ddsTheme]=\"themeConfig\"\r\n     class=\"dds-accordion__item {{customClass}}\"\r\n     role=\"listitem\">\r\n\r\n  <button [attr.aria-expanded]=\"isOpen\"\r\n          [attr.aria-disabled]=\"disabled\"\r\n          [disabled]=\"disabled\"\r\n          [ngClass]=\"{\r\n            'dds-accordion__header_disabled': disabled\r\n          }\"\r\n          ddsFocus\r\n          class=\"dds-accordion__header\"\r\n          (click)=\"selectAccordionItem()\"\r\n          #accordionBtnRef>\r\n    <div class=\"dds-accordion__header-wrap\">\r\n      <ng-content select=\"[header]\"></ng-content>\r\n    </div>\r\n    <span role=\"presentation\"\r\n          action\r\n          class=\"dds-accordion__icon dds-icon {{icon}}\"></span>\r\n  </button>\r\n\r\n  <div class=\"dds-accordion__body\"\r\n       role=\"region\">\r\n    <ng-content select=\"[content]\"></ng-content>\r\n  </div>\r\n\r\n</div>"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: AccordionComponent
  }], {
    class: [{
      type: HostBinding,
      args: ['class']
    }],
    disabled: [{
      type: HostBinding,
      args: [`class.${AdditionalClass.disabled}`]
    }, {
      type: Input
    }],
    accordionBtnRef: [{
      type: ViewChild,
      args: ['accordionBtnRef']
    }],
    size: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    isOpen: [{
      type: Input
    }],
    isMulti: [{
      type: Input
    }],
    itemChanged: [{
      type: Output
    }]
  });
})();
class AccordionModule {
  static {
    this.ɵfac = function AccordionModule_Factory(t) {
      return new (t || AccordionModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AccordionModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [AccordionComponent, AccordionItemComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccordionModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [AccordionComponent, AccordionItemComponent],
      exports: [AccordionComponent, AccordionItemComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AccordionClass, AccordionComponent, AccordionItemComponent, AccordionModule };
