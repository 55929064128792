import { Component } from '@angular/core';
import { FooterOptions } from '@usitsdasdesign/dds-ng/footer';
import { FooterModule } from '@usitsdasdesign/dds-ng/footer';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [FooterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.sass'
})
export class FooterComponent {
  public footerOptions: FooterOptions = {
    customClass: '',
    ariaLabel: '',
    role: '',
    isInverse: false,
    
  };
}
