import * as i0 from '@angular/core';
import { Injectable, Inject, Directive, Input, HostListener, NgModule } from '@angular/core';
import { KeyCodes, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
import { fromEvent, Subject } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

// @dynamic
class FocusHandlerService {
  constructor(document) {
    this.document = document;
    this.keyup$ = fromEvent(this.document, 'keyup').pipe(share());
    this.keydown$ = fromEvent(this.document, 'keydown').pipe(share());
    this.mousedown$ = fromEvent(this.document, 'mousedown').pipe(share());
  }
  static {
    this.ɵfac = function FocusHandlerService_Factory(t) {
      return new (t || FocusHandlerService)(i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FocusHandlerService,
      factory: FocusHandlerService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FocusHandlerService, [{
    type: Injectable
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();
class FocusHandlerDirective {
  onFocus(target) {
    if (this.isPrevKeyboardEvent) this.checkIfElementIsTarget(target);
  }
  constructor(renderer, ngZone, el, focusHandlerService) {
    this.renderer = renderer;
    this.ngZone = ngZone;
    this.el = el;
    this.focusHandlerService = focusHandlerService;
    this.destroy = new Subject();
    this.isPrevKeyboardEvent = false;
  }
  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.focusHandlerService.keyup$.pipe(takeUntil(this.destroy)).subscribe(e => this.focus(e));
      this.focusHandlerService.keydown$.pipe(takeUntil(this.destroy)).subscribe(e => {
        this.isPrevKeyboardEvent = true;
        if (e.keyCode === KeyCodes.TAB) this.removeFocusedClass();
        // for radio buttons
        if (this.focusCheck && (e.keyCode === KeyCodes.ARROW_UP || e.keyCode === KeyCodes.ARROW_DOWN || e.keyCode === KeyCodes.ARROW_RIGHT || e.keyCode === KeyCodes.ARROW_LEFT)) this.removeFocusedClass();
      });
      this.focusHandlerService.mousedown$.pipe(takeUntil(this.destroy)).subscribe(e => {
        if (!this.isFakeMousedownFromScreenReader(e)) {
          this.isPrevKeyboardEvent = false;
          this.removeFocusOnMousedown();
        }
      });
    });
  }
  ngOnChanges(changes) {
    if (changes.disabled) {
      this.disabledDir(changes.disabled.currentValue);
    }
  }
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
  isFakeMousedownFromScreenReader(event) {
    return event.offsetX === 0 && event.offsetY === 0;
  }
  disabledDir(status) {
    this.el.nativeElement.tabIndex = status ? -1 : 0;
    this.el.nativeElement.disabled = status;
  }
  focus(e) {
    if (this.focusCheck) {
      if (e.keyCode === KeyCodes.TAB || this.el.nativeElement.focus) {
        this.checkIfElementIsTarget(e.target);
      }
    } else {
      if (e.keyCode === KeyCodes.TAB) {
        this.checkIfElementIsTarget(e.target);
      }
    }
  }
  checkIfElementIsTarget(target) {
    if (this.disabled) return;
    if (this.el.nativeElement === target) {
      this.addFocusedClass();
    }
    return this.el.nativeElement === target;
  }
  addFocusedClass() {
    if (this.focusedEl) {
      this.renderer.addClass(this.focusedEl, AdditionalClass.keyboardFocus);
    } else {
      this.renderer.addClass(this.el.nativeElement, AdditionalClass.keyboardFocus);
    }
  }
  removeFocusedClass() {
    if (this.focusedEl) {
      this.renderer.removeClass(this.focusedEl, AdditionalClass.keyboardFocus);
    } else {
      this.renderer.removeClass(this.el.nativeElement, AdditionalClass.keyboardFocus);
    }
  }
  removeFocusOnMousedown() {
    this.removeFocusedClass();
  }
  static {
    this.ɵfac = function FocusHandlerDirective_Factory(t) {
      return new (t || FocusHandlerDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(FocusHandlerService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FocusHandlerDirective,
      selectors: [["", "ddsFocus", ""]],
      hostBindings: function FocusHandlerDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("focus", function FocusHandlerDirective_focus_HostBindingHandler($event) {
            return ctx.onFocus($event.target);
          });
        }
      },
      inputs: {
        focusedEl: [0, "ddsFocus", "focusedEl"],
        focusCheck: [0, "ddsFocusCheck", "focusCheck"],
        disabled: "disabled"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([FocusHandlerService]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FocusHandlerDirective, [{
    type: Directive,
    args: [{
      selector: '[ddsFocus]',
      standalone: true,
      providers: [FocusHandlerService]
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.NgZone
  }, {
    type: i0.ElementRef
  }, {
    type: FocusHandlerService
  }], {
    focusedEl: [{
      type: Input,
      args: ['ddsFocus']
    }],
    focusCheck: [{
      type: Input,
      args: ['ddsFocusCheck']
    }],
    disabled: [{
      type: Input
    }],
    onFocus: [{
      type: HostListener,
      args: ['focus', ['$event.target']]
    }]
  });
})();
class FocusHandlerModule {
  static {
    this.ɵfac = function FocusHandlerModule_Factory(t) {
      return new (t || FocusHandlerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FocusHandlerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [FocusHandlerService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FocusHandlerModule, [{
    type: NgModule,
    args: [{
      imports: [FocusHandlerDirective],
      exports: [FocusHandlerDirective],
      providers: [FocusHandlerService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FocusHandlerDirective, FocusHandlerModule, FocusHandlerService };
