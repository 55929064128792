<div class="dds-row">
  <div class="dds-col">
    <h2>Role Management</h2>
  </div>
</div>
<div class="dds-row">
  <div class="dds-col">
    <div class="information-text">
      <p><i class="dds-icon dds-icon_info__s__stroke"></i>For optimal management we suggest maintaining a maximum of 3 Admin Approvers and 10 Admin Users.</p>
    </div>
    <label class="search-label">
      <b>Search Person</b>
    </label>
    <div><br></div>
    <dds-suggestions-tags-input #personInput [formControl]="selectUserFormCtrl"
                            ariaLabel="Type a person name"
                            placeholder="Type a person name"
                            stickerMaxHeight="200"
                            [searchList]="searchList"
                            (valueChanged)="changeUserValue()"
                            [isLoading]="isPersonListLoading"
                            >
    </dds-suggestions-tags-input>
    @if (!searchList.length && !!personInput.value.length) {
      <p>No results match your search criteria</p>
    }
  </div>
</div>

@if (isUserSelected) {
  <div class="dds-row user-card-info">
    @if (userData.profile_pic && userData.profile_pic.length>0) {
      <div class="dds-col-2 custom_img_profile">
        <img [src]="userData.profile_pic" class="avatar" alt="User profile image">
      </div>
    }@else {
      <div class="dds-col-2 custom_img_profile custom_img_profile_text">
        <h1 class="profile_img_text">{{initialsName}}</h1>
      </div>
    }

    <div class="dds-col-5">
      <div class="dds-row">
        <div class="dds-col custom-col">
          <p class="p_margin_bottom"> <b>Name</b></p>
          <p class="w-wrap_custom p_margin_top" > {{userData.first_name}} {{userData.last_name}} </p>
          <p class="p_margin_bottom"> <b>Email</b> </p>
          <p class="w-wrap_custom p_margin_top">{{userData.email}}</p>
        </div>
      </div>
    </div>
    <div class="dds-col-5">
      <div class="dds-row">
        <div class="dds-col custom-col">
          <p  class="p_margin_bottom"> <b>Job title</b></p>
            <p class="w-wrap_custom p_margin_top"> {{userData.job_Title || 'No data found.'}}</p>
          <p class="p_w_margin"> <b>Role</b></p>
          <dds-select [formControl]="selectRoleFormCtrl"
          customClass="role_custom p_margin_top"
          [list]="roleItems"
              (valueChanged)="valueChangedRole()">
          </dds-select>
        </div>
      </div>
    </div>
  </div>
  <div class="dds-row margin-button">
    <div class="dds-col">
      <div class="button-align">
        <dds-button (click)="saveUser()" theme="dark" role="button" icon=" dds-icon_add_user__l__stroke" ariaLabel="Add New"
          [disabled]="isRoleSelected">Add New</dds-button>
      </div>
    </div>
  </div>
}



