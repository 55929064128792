import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Devices, Size, ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { forwardRef } from '@angular/core';

function DestroyMixin(Base = class {
}) {
    return class extends Base {
        constructor(...args) {
            super(...args);
            this.destroy = new Subject();
        }
        ngOnDestroy() {
            this.destroy.next();
            this.destroy.complete();
        }
    };
}

function I18nMixin(Base = class {
}) {
    return class extends Base {
        constructor(...args) {
            super(...args);
        }
        ngOnInit() {
            if (super.ngOnInit)
                super.ngOnInit();
            this.getI18n();
        }
        getI18n() {
            this.i18n
                .getTranslationObs()
                .pipe(takeUntil(this.destroy))
                .subscribe((data) => this.setI18nTexts(data));
        }
        setI18nTexts(translation) { }
    };
}

function ResponsivenessMixin(Base = class {
}) {
    return class extends Base {
        constructor(...args) {
            super(...args);
        }
        ngOnInit() {
            if (super.ngOnInit)
                super.ngOnInit();
            this._size = this.size;
            this.checkResponsiveness();
        }
        checkResponsiveness() {
            if (this.isResponsive) {
                this.checkDevice(this.resizeService.getDevice());
                this.resizeService.device$.pipe(takeUntil(this.destroy)).subscribe((currentDevice) => this.checkDevice(currentDevice));
            }
        }
        checkDevice(device) {
            this.size = device === Devices.desktop ? this._size : Size.lg;
            this.smallDevice = device !== Devices.desktop;
            this.cdr.detectChanges();
        }
    };
}

function FormAccessorMixin(Base = class {
}) {
    return class extends Base {
        setDisabledState(_) { }
        writeValue() { }
        constructor(...args) {
            super(...args);
            this.onModelTouched = () => { };
            this.onModelChange = () => { };
        }
        registerOnChange(fn) {
            this.onModelChange = fn;
        }
        registerOnTouched(fn) {
            this.onModelTouched = fn;
        }
    };
}
function valueAccessor(components) {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => components),
        multi: true,
    };
}

function ErrorStateMixin(Base = class {
}) {
    return class extends Base {
        constructor(...args) {
            super(...args);
            this._isFormControlRequired = false;
            this.isError$ = new Subject();
        }
        ngDoCheck() {
            if (super.ngDoCheck)
                super.ngDoCheck();
            if (this.ngControl) {
                this.updateFormErrorState(this.errorState);
            }
        }
        ngOnInit() {
            if (super.ngOnInit)
                super.ngOnInit();
            if (this.errorState === ErrorState.default)
                this.checkError();
        }
        ngOnChanges(changes) {
            if (changes.isError && !changes.isError.firstChange)
                this.checkError();
        }
        writeValue() {
            if (this.errorState === ErrorState.default)
                this.checkError();
        }
        updateFormErrorState(errorState) {
            const oldState = this._isError;
            const parent = this.parentFormGroup || this.parentForm;
            const control = this.ngControl ? this.ngControl.control : null;
            let newState;
            this._isFormControlRequired = !!control?.errors?.required;
            if (errorState === ErrorState.focusOut) {
                newState = this.isErrorStateOnFocus(control, parent);
            }
            else {
                newState = this.isErrorStateDefault(control);
            }
            if (newState !== oldState) {
                this._isError = newState;
                this.isError$.next(newState);
                this.cdr.detectChanges();
            }
        }
        checkError() {
            if (this.isError !== undefined) {
                this._isError = this.isError;
                this.isError$.next(this.isError);
            }
            this.cdr.detectChanges();
        }
        isErrorStateDefault(control) {
            return !!(control && control.invalid);
        }
        isErrorStateOnFocus(control, form) {
            const isSubmitted = form && form.submitted;
            return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        }
    };
}

/**
 * Generated bundle index. Do not edit.
 */

export { DestroyMixin, ErrorStateMixin, FormAccessorMixin, I18nMixin, ResponsivenessMixin, valueAccessor };

