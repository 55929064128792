<dds-suggestions-tags-input
  #suggestionElement
	[formControl]="formControl"
	[ariaLabel]="label"
	[label]="label"
	[labelPosition]="labelPosition"
	[placeholder]="placeholder"
	[description]="description"
	[searchList]="searchList"
	[minSearchSymbols]="0"
	[stickerMaxHeight]="250"
	[stickerIsDynamic]="true"
	[errorMessage]="errorMessage"
	errorState="focusOut"
	(keydown.escape)="isEscapeHideContext()"
/>
