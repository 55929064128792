import { EventEmitter, inject, Injectable, signal } from '@angular/core';
import { PendingChanges } from '../../shared/model/pending-changes';
import { Observable } from 'rxjs';
import { SortOptions } from '@usitsdasdesign/dds-ng/shared';
import { HttpClient } from '@angular/common/http';
import { PendingChangesParams, PendingChangesType } from './pending-changes.filters';
import { PaginatedData } from '../../shared/model/model';
import { getSettings } from '../../shared/utils/settingsLoader';

@Injectable({
  providedIn: 'root',
})
export class PendingChangesService {
  private http: HttpClient = inject(HttpClient);
  settings = getSettings();


  public getSkills(page: number, pageSize: number, sort: SortOptions, query?: string, filters: PendingChangesParams = {}): Observable<PaginatedData<PendingChanges>> {
    const params = new URLSearchParams();
    params.append('PageNumber', (page - 1).toString());
    params.append('PageSize', pageSize.toString());

    if (sort.property?.length) {
      params.append('OrderBy', sort.property);
      params.append('IsAscending', sort.descending ? 'false' : 'true');
    }

    if (query?.length) {
      params.append('Search', query);
    }

    Object.keys(filters).forEach(key => {
      if (filters[key].length)
        params.append(key, filters[key]);
    });

    return this.http.get<PaginatedData<PendingChanges>>(`${this.settings.apiUrl}/PendingChanges?${params.toString()}`);
  }

  public getFilters(): Observable<PendingChangesType<{ id: number; description: string, parentId: number }>> {
    return this.http.get<PendingChangesType<{ id: number; description: string, parentId: number }>>(`${this.settings.apiUrl}/Skill/Filters`);
  }

  public bulkApprove(pendingChangeIdValues: number[], comment: string): Observable<void> {
    const requestorId = parseInt(localStorage.getItem('userId') || '0');
    return this.http.put<void>(`${this.settings.apiUrl}/PendingChanges/BulkApprove`, { pendingChangeIdValues, comment, requestorId });
  }

  public bulkDecline(skillIds: number[], requestorMessage: string, requestTypeId?: number, skillLightCastId?: string, skillName?: string): Observable<void> {
  //RequestTypeId: IS ONLY NEEDED when declining a single pending change, not for bulk decline
    //FOR BULK DECLINE, requestTypeId MUST NOT BE PASSED// 1:Add 2:Remove 3:Consolidation 4:Edit
    //Attributes lcId (LightCast Id) and skillName are only needed for decline of a single pending change. It's needed for building the repsonse message

    const requestorInitiatiorId = parseInt(localStorage.getItem('userId') || '0');
    return this.http.put<void>(`${this.settings.apiUrl}/PendingChanges/declinePendingChanges`, { skillIds, requestorMessage, requestorInitiatiorId, requestTypeId, skillLightCastId, skillName });
  }

  public getPendingChangeDetails(id: number): Observable<any> {
    return this.http.get<any>(`${this.settings.apiUrl}/PendingChanges/${id}`);
  }

  public editApprovePendingChange(data: any): Observable<any> {
    return this.http.post<any>(`${this.settings.apiUrl}/PendingChanges`, data);
  }

  public onApproveOrDeclineEnded = new EventEmitter<boolean>();






}
