import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MasterSkillTag } from '../model/masterSkill';
import { getSettings } from '../utils/settingsLoader';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  settings = getSettings();

  constructor(
    private http: HttpClient
  ) { }

  getMasterSkillsTags():Observable<any>{
    let url = `${this.settings.apiUrl}/tag`
    return this.http.get<{id:number, tagName:string}[]>(url).pipe(
      map((data:any)=>{
        return data.map((tag:any)=>({
          value:tag.id,
          heading: tag.tagName.trim()
        }))
      }
    ))
  }


  addNew(tagName: string): Observable<any> {
    let url = `${this.settings.apiUrl}/Tag/Add?tagName=` + tagName
    return this.http.put(url, {}).pipe(
      map((data: any) => {
        return data[0].id
      })
    )
  }

}
