
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, inject, Input, Output, Renderer2, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { PopupComponent } from '../../services/popup.service';
import { Accessible } from '../../model/model';

export type DrawerOptions = {
	closeOnOutsideClick?: boolean;
  closeOnEsc?: boolean;
  data?: Accessible;
}

@Component({
  selector: 'app-drawer',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './drawer.component.html',
  styleUrl: './drawer.component.sass'
})
export class DrawerComponent implements PopupComponent, AfterViewInit {
  @Input() public content?: Type<unknown>;
  @Input() public options: DrawerOptions = { closeOnEsc: true, closeOnOutsideClick: true };
  @Input() public data?: Accessible;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('content', { read: ViewContainerRef }) private contentView!: ViewContainerRef;
  @ViewChild('backdrop') private backdrop!: ElementRef<HTMLDivElement>;
  private renderer: Renderer2 = inject(Renderer2);

  public collapsed: boolean = true;
  private onClosedValue: any;

  ngAfterViewInit(): void {
    if (!this.content)
      throw new Error('Content not provided');

    const componentRef = this.contentView.createComponent(this.content);
    if (!!this.data) {
      for (const key in this.data)
        (componentRef.instance as any)[key] = this.data[key];
    }
    componentRef.changeDetectorRef.detectChanges();
    //this.renderer.setStyle(this.backdrop.nativeElement, 'display', 'block');
    setTimeout(() => this.collapsed = false);
  }

  @HostListener('document:keydown.escape', [])
  closeOnEsc(): void {
    if (this.options.closeOnEsc)
      this.close();
  }

  public close(value?: any): void {
    this.onClosedValue = value;
    this.collapsed = true;
    this.onClosed.emit(this.onClosedValue);
  }

  onBackdropClick(event: MouseEvent) {
    if (this.options.closeOnOutsideClick && event.target === event.currentTarget) {
      this.close();
    }
  }
}