
<p [innerHTML]="message"></p>

<dds-textarea [placeholder]="(placeholder ?? 'Add a Comment here.') + (isOptional ? '' : '*')" [minHeight]="100" (valueChanged)="comment = $event"></dds-textarea>
          
<div class="dds-modal__content-footer">
	<div class="dds-modal__footer-content dds-modal__footer-content_right">
		<dds-button class="dds-modal__footer-item" [theme]="cancel.theme" [kind]="cancel.kind" [icon]="cancel.icon" (clicked)="close(false)" (keyboardClicked)="close(false)" [ariaLabel]="cancel.text">{{ cancel.text }}</dds-button>
		<dds-button class="dds-modal__footer-item" [disabled]="!comment.length && !isOptional" [theme]="accept.theme" [kind]="accept.kind" [icon]="accept.icon" (clicked)="close(this.comment)" (keyboardClicked)="close(this.comment)" [ariaLabel]="accept.text">{{ accept.text }}</dds-button>
	</div>
</div>
