import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { loadSettings } from './app/shared/utils/settingsLoader';
import Settings from './app/shared/model/settings';


loadSettings().then((settings: Settings) => {
  if (settings.script) {
    const script = document.createElement('script');
    script.src = settings.script;
    script.async = true;
    document.head.appendChild(script);
  }

  bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

});