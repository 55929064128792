import { Component, Input, OnInit } from '@angular/core';
import { masterSkillTechnologies, masterSkillTypes } from '../../../../shared/enums/enums';
import { FullAuditLogData } from '../../../../shared/model/full-audit-log-data';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-skill-information',
	standalone: true,
  imports: [CommonModule],
	templateUrl: './skill-information.component.html',
	styleUrl: './skill-information.component.sass'
})
export class SkillInformationComponent implements OnInit {

	@Input() skillInformation!: FullAuditLogData;
	@Input() availableTags: any = []
	@Input() availableCategories: any = []
	@Input() availableSubCategories: any = []
	public category: string | undefined;
	public subCategory: string | undefined;
	public technology: string | undefined;
	public skillType: string | undefined;
	public tags: any[] = [];
  showFullText: boolean = false;
  isLongText: boolean = false;
	ngOnInit(): void {
		this.replaceIds();
	}


	replaceIds() {
		this.category = this.availableCategories.find((category: any) => category.value === this.skillInformation.pchCategoryId)?.heading;
		this.subCategory = this.availableSubCategories.find((subCategory: any) => subCategory.value === this.skillInformation.pchSubCategoryId)?.heading;
		this.technology = masterSkillTechnologies[this.skillInformation.pchTechnologyId];
    this.skillType = masterSkillTypes[this.skillInformation.pchTypeId];
		this.getTagInformation();
	}

	private getTagInformation(): void {
    if (this.skillInformation.pchTags) {
			this.availableTags.find((tag: any) => {
        if (this.skillInformation.pchTags?.includes(tag.value)) {
					this.tags.push(tag.heading)
				}
			})
		}
	}
  showMoreVisible(value1: string) {
    if (value1) {
      let charCount = value1.length;
      this.isLongText = charCount > 150;
      if (this.isLongText) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toggleText(): void {
    this.showFullText = !this.showFullText;
  }
}
