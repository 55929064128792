export class Skill {
	skillid: number;
	skillname: string;
	skilltype: string;
	category: string;
	subcategory: string;
	skillstatus: boolean | null;
	skillstatuslabel: string;
	lightCastId?: string;
	skillDescription: string;
	technology?: string;
	tags: string[];

	constructor(data: Partial<Skill>) {
		this.skillid = data.skillid ?? 0;
		this.skillname = data.skillname ?? '';
		this.skilltype = data.skilltype ?? '';
		this.category = data.category ?? '';
		this.subcategory = data.subcategory ?? '';
		this.skillstatus = data.skillstatus ?? null;
		this.skillstatuslabel = data.skillstatuslabel ?? '';
		this.lightCastId = data.lightCastId ?? '';
		this.skillDescription = data.skillDescription ?? '';
		this.technology = data.technology ?? '';
		this.tags = data.tags ?? [];
	}
}
