@if((Item.originalValue && Item.pcValue)&&(Item.originalValue !==Item.pcValue)){
<label class="label-item">
	{{Item.label}}
</label>
<div class="change-description" [ngClass]="{'long-text':isLongText}">
	<span class="old-value" [ngClass]="{'truncated':!showFullText}">
		{{Item.originalValue}}
	</span>
	@if(!isLongText){
	&nbsp;to&nbsp;
	}
	<span class="pending-change" [ngClass]="{'truncated':!showFullText}">
		{{Item.pcValue}}
	</span>
	@if(showMoreVisible(Item.originalValue, Item.pcValue)){
	<a class="show-more" (click)="toggleText()">{{ showFullText ? 'Show Less' : 'Show More' }}</a>
	}
</div>
}


@if((Item.originalValue=='' || Item.originalValue==null)&& Item.pcValue){
<label class="label-item">
	NEW! {{Item.label}}
</label>
<p class="change-description">
	{{Item.pcValue}}
</p>

}


@if(Item.originalValue && !Item.pcValue){
<label class="label-item">
	REMOVED! {{Item.label}}
</label>
<p class="change-description">
	{{Item.originalValue}}
</p>
}
