<div class="dds-row">
  <div class="dds-col">
    <div class="section-title">
      Last Modifications
      @if (isApprover) {
        <dds-button [routerLink]="['audit-log']" theme="dark" size="sm" role="button" ariaLabel="See More">See
          More</dds-button>
      }
    </div>
    <div class="table-conteiner">
      <app-table [initialSort]="{ property: 'requestDate', descending: true }" [paginated]="false" [sorting]="false"
        #table [columns]="columns" [isLoading]="isLoading" [totalSize]="totalSize" (load)="getModifications($event)">
        @if (latest.length === 0) {
        <tr>
          <td [attr.colspan]="columns.length" class="dds-empty-state-custom">
            No modification found
          </td>
        </tr>
        }
        @for (latest of latest; track latest.id) {
        <tr class="dds-data-table__row">
          @for (column of columns; track column.name) {
          @switch (column.dataType) {
          @case ('date') {
          <td class="dds-data-table__cell">{{ latest[column.name] | date: 'MM/dd/yyyy' }}</td>
          }
          @default {
          <td class="dds-data-table__cell">{{ latest[column.name] }}</td>
          }
          }
          }
        </tr>
        }
      </app-table>
    </div>
  </div>
</div>
