import { Component, ElementRef, inject, Input, OnInit } from '@angular/core';
import { EmptyStateComponent } from '@usitsdasdesign/dds-ng/empty-state';

@Component({
  selector: 'app-table-empty',
  standalone: true,
  imports: [EmptyStateComponent],
  templateUrl: './table-empty.component.html',
  styleUrl: './table-empty.component.sass'
})
export class TableEmptyComponent implements OnInit {
  private elRef: ElementRef = inject(ElementRef);
  @Input() public colspan: number = 1;

  public ngOnInit(): void {
    this.elRef.nativeElement.classList.add('dds-data-table__row');
  }
}
