import Settings from "../model/settings";


var _settings: Settings;

export const loadSettings = (): Promise<Settings> => {
  const time = new Date().getTime();

  return fetch(`../../../../settings.json`)
    .then((response) => response.json())
    .then((settings: Settings) => (_settings = settings));
};

export const getSettings = (): Settings => ({ ..._settings });
