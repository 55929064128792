import { Accessible } from "../../shared/model/model";

enum filterKeyMap { categories = 'FilterCategory', subCategories = 'FilterSubCategory', dmtStates = 'FilterDMTStatus', skillTypes = 'FilterSkillType', technologies = 'FilterTechnology' }

export type SkillFiltersType<T> = Accessible & { categories: T[]; dmtStates: T[]; skillTypes: T[]; subCategories: T[]; technologies: T[]; }
export type SkillFiltersValueType<T> = Accessible & { categories: T; dmtStates: T[]; skillTypes: T[]; subCategories: T[]; technologies: T[]; }
export type SkillFiltersParams = Accessible & { FilterCategory?: string[]; FilterSubCategory?: string[]; FilterDMTStatus?: string[]; FilterSkillType?: string[]; FilterTechnology?: string[]; }

export class SkillFilters {
	private store: SkillFiltersType<{ id: number; description: string, parentId?: number }>;

	public lists: SkillFiltersType<string>;
	
	public filters: Partial<SkillFiltersValueType<string>> = {};

	public get isFiltered(): boolean {
		return Object.keys(this.filters).map(key => this.filters[key]).flat().length > 0;
	}

	public get computedLists(): { subCategories: string[] } {
		return {
			subCategories: this.filters.categories?.[0] ? this.toList(this.store.subCategories.filter(sc => sc.parentId == this.forParams().FilterCategory?.[0])) : this.lists.subCategories
		};
	}

	public clear(name?: keyof SkillFiltersType<string>): void {
		if (name == 'categories') {
			delete this.filters.categories;
			this.clear('subCategories');
			return;
		}
		if (name) {
			this.filters[name] = [];
			return;
		}
		delete this.filters.categories;
		Object.keys(this.filters).forEach(key => this.filters[key] = []);
	}

	public forParams(): SkillFiltersParams {
		if (this.filters.subCategories?.length && !this.filters.categories?.length) {
			const parentId = this.store.subCategories.find(sc => sc.description == this.filters.subCategories?.[0])?.parentId;
			this.filters.categories = this.store.categories.filter(c => c.id == parentId).map(c => c.description)?.[0];
		}
		const params: SkillFiltersParams = {};

		Object.keys(this.filters).forEach(key => {
			const name = filterKeyMap[key as keyof typeof filterKeyMap];
			
			params[name] = this.store[key]
			.filter(({ description }: {description: string}) => this.filters[key].includes(description))
			.map(({ id, description }: {id: number, description: string}) => {
				switch (key) {
					case 'categories': case 'subCategories': case 'dmtStates':
						return `${id ?? 'NULL'}`;
					default:
						return description;
				}
			});
			
			// make unique
			params[name] = params[name]?.filter((id: string, index: number) => params[name]?.indexOf(id) === index);
		});

		return params;
	}

	constructor(data: SkillFiltersType<{ id: number; description: string }> = { categories: [], dmtStates: [], skillTypes: [], subCategories: [], technologies: [] }) {
		this.store = {
			categories: data.categories ?? [],
			dmtStates: data.dmtStates ?? [],
			skillTypes: data.skillTypes ?? [],
			subCategories: data.subCategories ?? [],
			technologies: data.technologies ?? []
		};
		this.lists = {
			categories: this.toList(this.store.categories),
			dmtStates: this.toList(this.store.dmtStates),
			skillTypes: this.toList(this.store.skillTypes),
			subCategories: this.toList(this.store.subCategories),
			technologies: this.toList(this.store.technologies)
		};
	}

	private toList(items: { id: number, description: string }[]): string[] {
		return items
		.filter(item => item.description.length > 0 && item.description !== 'NULL')
		.map(item => item.description)
		.sort((a, b) => a.localeCompare(b));
	}
}
