import { MimeTypes} from "../enums/enums"


export const fileDownLoad = (
    fileContent: BlobPart,
    mimeType:MimeTypes,
    fileName:string
)=>{
    const blob = new Blob([fileContent], { type: mimeType });
    const downloadTemplateEl = document.createElement('a');
    const urlLink = URL.createObjectURL(blob);
    downloadTemplateEl.setAttribute('href', urlLink);
    downloadTemplateEl.setAttribute('download', fileName);
    const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false
    });
    downloadTemplateEl.dispatchEvent(clickEvent);
    downloadTemplateEl.remove();
    
}

