import { Component, inject } from '@angular/core';
import { PopupService } from '../../shared/services/popup.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-skills',
  standalone: true,
  imports: [],
  templateUrl: './skills.component.html',
  styleUrl: './skills.component.sass'
})
export class SkillsComponent {
  private popups: PopupService = inject(PopupService);

  openAlert(): void {
    this.popups.alert('Changes has been saved!');
  }
  
  async openConfirm(): Promise<void> {
    const confirm = this.popups.confirm('Are you sure?');
    const result = await firstValueFrom(confirm.onClosed);
    this.popups.alert(`You ${result === undefined ? 'closed' : result ? 'accepted' : 'canceled'} the dialog`, { title: 'Response' });
  }
}
