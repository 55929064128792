import * as i0 from '@angular/core';
import { Injectable, Optional, NgModule, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Themes, Size, LabelPosition, PositionState, ErrorState, DEFAULT_DATE_FORMAT } from '@usitsdasdesign/dds-ng/shared';
import { BehaviorSubject } from 'rxjs';
class DdsConfig {}
class DdsConfigService {
  constructor(config) {
    this.config = {
      theme: Themes.green,
      size: Size.md,
      isResponsive: false,
      labelPosition: LabelPosition.external,
      stickerPosition: PositionState.bottomLeft,
      stickerIndent: 0,
      errorState: ErrorState.default,
      dateFormat: DEFAULT_DATE_FORMAT
    };
    this.configSubject = new BehaviorSubject(this.config);
    if (config) {
      this.setConfig(config);
    }
  }
  setConfig(config) {
    this.config = {
      ...this.config,
      ...config
    };
    this.configSubject.next(this.config);
  }
  getConfig() {
    return this.config;
  }
  getConfigObs() {
    return this.configSubject.asObservable();
  }
  static {
    this.ɵfac = function DdsConfigService_Factory(t) {
      return new (t || DdsConfigService)(i0.ɵɵinject(DdsConfig, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DdsConfigService,
      factory: DdsConfigService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DdsConfigService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: DdsConfig,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
class DdsConfigModule {
  constructor(parentModule) {
    if (parentModule) {
      throw new Error('DdsConfigModule is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot(config) {
    return {
      ngModule: DdsConfigModule,
      providers: [{
        provide: DdsConfig,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function DdsConfigModule_Factory(t) {
      return new (t || DdsConfigModule)(i0.ɵɵinject(DdsConfigModule, 12));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: DdsConfigModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DdsConfigModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule]
    }]
  }], () => [{
    type: DdsConfigModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DdsConfig, DdsConfigModule, DdsConfigService };
