import { PositionState, KeyCodes, PopupClass } from '@usitsdasdesign/dds-ng/shared';
import { DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
const HALF_DIVIDER = 2;
const WINDOW_TOP_LIMIT = 0;
const INDENT = 8;
const SHIFT = 0;
const POPUP_ELEMENT_COUNT = 0;
const POPUP_Z_INDEX = 1001;
const BACKDROP_Z_INDEX = 1000;
function top({
  elRect,
  popupRect,
  beakRect,
  indent
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthTopBottomPos(elRect, popupRect);
  const positionCoords = {
    left: elRect.left + window.scrollX + elRect.width / HALF_DIVIDER - popupWidth / HALF_DIVIDER,
    top: elRect.top + window.scrollY - popupRect.height - indent,
    maxWidth: popupWidth
  };
  if (beakRect) {
    beakPositionCoords = {
      left: popupWidth / HALF_DIVIDER - beakRect.width / HALF_DIVIDER
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.top
  };
}
function topLeft({
  elRect,
  popupRect,
  beakRect,
  indent,
  shift
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthTBLeftPos(elRect, popupRect);
  const positionCoords = {
    left: elRect.left + window.scrollX + shift,
    top: elRect.top + window.scrollY - popupRect.height - indent,
    maxWidth: popupWidth
  };
  if (beakRect) {
    const beakPopupLeft = beakRect.width;
    const beakContentCenter = elRect.width / HALF_DIVIDER - beakRect.width / HALF_DIVIDER - shift;
    beakPositionCoords = {
      left: elRect.width > popupWidth ? beakPopupLeft : beakContentCenter
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.topLeft
  };
}
function topRight({
  elRect,
  popupRect,
  beakRect,
  indent,
  shift
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthTBRightPos(elRect, popupRect);
  const positionCoords = {
    left: elRect.left + window.scrollX + elRect.width - popupWidth + shift,
    top: elRect.top + window.scrollY - popupRect.height - indent,
    maxWidth: popupWidth
  };
  if (beakRect) {
    const beakPopupRight = popupWidth - beakRect.width * HALF_DIVIDER;
    const beakContentCenter = popupWidth - elRect.width / HALF_DIVIDER - beakRect.width / HALF_DIVIDER - shift;
    beakPositionCoords = {
      left: elRect.width > popupWidth ? beakPopupRight : beakContentCenter
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.topRight
  };
}
function bottom({
  elRect,
  popupRect,
  beakRect,
  indent
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthTopBottomPos(elRect, popupRect);
  const positionCoords = {
    left: elRect.left + window.scrollX + elRect.width / HALF_DIVIDER - popupWidth / HALF_DIVIDER,
    top: elRect.top + elRect.height + window.scrollY + indent,
    maxWidth: popupWidth
  };
  if (beakRect) {
    beakPositionCoords = {
      left: popupWidth / HALF_DIVIDER - beakRect.width / HALF_DIVIDER
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.bottom
  };
}
function bottomLeft({
  elRect,
  popupRect,
  beakRect,
  indent,
  shift
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthTBLeftPos(elRect, popupRect);
  const positionCoords = {
    left: elRect.left + window.scrollX + shift,
    top: elRect.top + elRect.height + window.scrollY + indent,
    maxWidth: popupWidth
  };
  if (beakRect) {
    const beakPopupLeft = beakRect.width;
    const beakContentCenter = elRect.width / HALF_DIVIDER - beakRect.width / HALF_DIVIDER - shift;
    beakPositionCoords = {
      left: elRect.width > popupWidth ? beakPopupLeft : beakContentCenter
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.bottomLeft
  };
}
function bottomRight({
  elRect,
  popupRect,
  beakRect,
  indent,
  shift
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthTBRightPos(elRect, popupRect);
  const positionCoords = {
    left: elRect.left + window.scrollX + elRect.width - popupWidth + shift,
    top: elRect.top + elRect.height + window.scrollY + indent,
    maxWidth: popupWidth
  };
  if (beakRect) {
    const beakPopupRight = popupWidth - beakRect.width * HALF_DIVIDER;
    const beakContentCenter = popupWidth - elRect.width / HALF_DIVIDER - beakRect.width / HALF_DIVIDER - shift;
    beakPositionCoords = {
      left: elRect.width > popupWidth ? beakPopupRight : beakContentCenter
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.bottomRight
  };
}
function left({
  elRect,
  popupRect,
  beakRect,
  indent
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthLeftPos(elRect, popupRect, indent);
  const positionCoords = {
    left: elRect.left + window.scrollX - popupWidth - indent,
    top: elRect.top + window.scrollY + elRect.height / HALF_DIVIDER - popupRect.height / HALF_DIVIDER,
    maxWidth: popupWidth
  };
  if (beakRect) {
    beakPositionCoords = {
      top: popupRect.height / HALF_DIVIDER - beakRect.height / HALF_DIVIDER
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.left
  };
}
function leftTop({
  elRect,
  popupRect,
  beakRect,
  indent,
  shift
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthLeftPos(elRect, popupRect, indent);
  const positionCoords = {
    left: elRect.left + window.scrollX - popupWidth - indent,
    top: elRect.top + window.scrollY + shift,
    maxWidth: popupWidth
  };
  if (beakRect) {
    const beakPopupTop = beakRect.height;
    const beakContentCenter = elRect.height / HALF_DIVIDER - beakRect.height / HALF_DIVIDER - shift;
    beakPositionCoords = {
      top: elRect.height > popupRect.height ? beakPopupTop : beakContentCenter
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.leftTop
  };
}
function leftBottom({
  elRect,
  popupRect,
  beakRect,
  indent,
  shift
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthLeftPos(elRect, popupRect, indent);
  const positionCoords = {
    left: elRect.left + window.scrollX - popupWidth - indent,
    top: elRect.top + window.scrollY + elRect.height - popupRect.height + shift,
    maxWidth: popupWidth
  };
  if (beakRect) {
    const beakPopupBottom = popupRect.height - beakRect.height * HALF_DIVIDER;
    const beakContentCenter = popupRect.height - elRect.height / HALF_DIVIDER - beakRect.height / HALF_DIVIDER - shift;
    beakPositionCoords = {
      top: elRect.height > popupRect.height ? beakPopupBottom : beakContentCenter
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.leftBottom
  };
}
function right({
  elRect,
  popupRect,
  beakRect,
  indent
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthRightPos(elRect, popupRect, indent);
  const positionCoords = {
    left: elRect.left + window.scrollX + elRect.width + indent,
    top: elRect.top + window.scrollY + elRect.height / HALF_DIVIDER - popupRect.height / HALF_DIVIDER,
    maxWidth: popupWidth
  };
  if (beakRect) {
    beakPositionCoords = {
      left: -beakRect.width,
      top: popupRect.height / HALF_DIVIDER - beakRect.height / HALF_DIVIDER
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.right
  };
}
function rightTop({
  elRect,
  popupRect,
  beakRect,
  indent,
  shift
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthRightPos(elRect, popupRect, indent);
  const positionCoords = {
    left: elRect.left + window.scrollX + elRect.width + indent,
    top: elRect.top + window.scrollY + shift,
    maxWidth: popupWidth
  };
  if (beakRect) {
    const beakPopupTop = beakRect.height;
    const beakContentCenter = elRect.height / HALF_DIVIDER - beakRect.height / HALF_DIVIDER - shift;
    beakPositionCoords = {
      left: -beakRect.width,
      top: elRect.height > popupRect.height ? beakPopupTop : beakContentCenter
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.rightTop
  };
}
function rightBottom({
  elRect,
  popupRect,
  beakRect,
  indent,
  shift
}) {
  let beakPositionCoords;
  const popupWidth = getPopupWidthRightPos(elRect, popupRect, indent);
  const positionCoords = {
    left: elRect.left + window.scrollX + elRect.width + indent,
    top: elRect.top + window.scrollY + elRect.height - popupRect.height + shift,
    maxWidth: popupWidth
  };
  if (beakRect) {
    const beakPopupBottom = popupRect.height - beakRect.height * HALF_DIVIDER;
    const beakContentCenter = popupRect.height - elRect.height / HALF_DIVIDER - beakRect.height / HALF_DIVIDER - shift;
    beakPositionCoords = {
      left: -beakRect.width,
      top: elRect.height > popupRect.height ? beakPopupBottom : beakContentCenter
    };
  }
  return {
    popupCoords: positionCoords,
    beakCoords: beakPositionCoords,
    beakPosition: PositionState.rightBottom
  };
}
function addPosition(el, position, setMaxWidth = false) {
  for (const prop in position) {
    if (prop === 'maxWidth' && !setMaxWidth) return;
    if (position.hasOwnProperty(prop) && el.style) {
      el.style[prop] = `${position[prop]}px`;
    }
  }
}
function getPopupWidthTopBottomPos(elRect, popupRect) {
  const leftEmptyPlace = elRect.left + elRect.width / HALF_DIVIDER;
  const rightEmptyPlace = window.innerWidth - leftEmptyPlace;
  const smallerPlace = leftEmptyPlace < rightEmptyPlace ? leftEmptyPlace : rightEmptyPlace;
  const emptyPlace = smallerPlace * 2;
  const popupWidth = popupRect.width > emptyPlace ? emptyPlace : popupRect.width;
  return popupWidth;
}
function getPopupWidthTBLeftPos(elRect, popupRect) {
  const emptyPlace = window.innerWidth - elRect.left + elRect.width;
  const popupWidth = popupRect.width > emptyPlace ? emptyPlace : popupRect.width;
  return popupWidth;
}
function getPopupWidthTBRightPos(elRect, popupRect) {
  const emptyPlace = elRect.left + elRect.width;
  const popupWidth = popupRect.width > emptyPlace ? emptyPlace : popupRect.width;
  return popupWidth;
}
function getPopupWidthLeftPos(elRect, popupRect, indent) {
  const emptyPlace = elRect.left - indent;
  const popupWidth = popupRect.width > emptyPlace ? emptyPlace : popupRect.width;
  return popupWidth;
}
function getPopupWidthRightPos(elRect, popupRect, indent) {
  const emptyPlace = window.innerWidth - elRect.left + elRect.width - indent;
  const popupWidth = popupRect.width > emptyPlace ? emptyPlace : popupRect.width;
  return popupWidth;
}
function checkWindowLimits(config) {
  const elRect = config.elRect;
  const stickerWidth = config.popupRect.width;
  const stickerHeight = config.popupRect.height;
  const indent = config.indent;
  return {
    topLimit: elRect.top - stickerHeight - indent < WINDOW_TOP_LIMIT,
    topHalfLimit: elRect.top + elRect.height / HALF_DIVIDER - stickerHeight / HALF_DIVIDER < WINDOW_TOP_LIMIT,
    topCornerLimit: elRect.top + elRect.height - stickerHeight < WINDOW_TOP_LIMIT,
    bottomLimit: window.innerHeight < elRect.top + elRect.height + stickerHeight + indent,
    bottomHalfLimit: window.innerHeight < elRect.top + elRect.height / HALF_DIVIDER + stickerHeight / HALF_DIVIDER,
    bottomCornerLimit: window.innerHeight < elRect.top + stickerHeight,
    leftLimit: elRect.left < stickerWidth + indent,
    leftHalfLimit: elRect.left < stickerWidth / HALF_DIVIDER - elRect.width / HALF_DIVIDER,
    leftCornerLimit: window.innerWidth < elRect.left + stickerWidth,
    rightLimit: window.innerWidth < elRect.right + stickerWidth + indent,
    rightHalfLimit: window.innerWidth < elRect.right + stickerWidth / HALF_DIVIDER - elRect.width / HALF_DIVIDER,
    rightCornerLimit: elRect.left < stickerWidth - elRect.width
  };
}
function topPosCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.topLimit && limits.leftHalfLimit:
        return topLeft(config);
      case !limits.topLimit && limits.rightHalfLimit:
        return topRight(config);
      case limits.topLimit && limits.leftHalfLimit:
        return bottomLeft(config);
      case limits.topLimit && limits.rightHalfLimit:
        return bottomRight(config);
      case limits.topLimit:
        return bottom(config);
      default:
        return top(config);
    }
  } else {
    return top(config);
  }
}
function topLeftPosCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.topLimit && limits.leftCornerLimit:
        return topRight(config);
      case limits.topLimit && limits.leftCornerLimit:
        return bottomRight(config);
      case limits.topLimit:
        return bottomLeft(config);
      default:
        return topLeft(config);
    }
  } else {
    return topLeft(config);
  }
}
function topRightCondtion(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.topLimit && limits.rightCornerLimit:
        return topLeft(config);
      case limits.topLimit && limits.rightCornerLimit:
        return bottomLeft(config);
      case limits.topLimit:
        return bottomRight(config);
      default:
        return topRight(config);
    }
  } else {
    return topRight(config);
  }
}
function bottomPosCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.bottomLimit && limits.leftHalfLimit:
        return bottomLeft(config);
      case !limits.bottomLimit && limits.rightHalfLimit:
        return bottomRight(config);
      case limits.bottomLimit && limits.leftHalfLimit:
        return topLeft(config);
      case limits.bottomLimit && limits.rightHalfLimit:
        return topRight(config);
      case limits.bottomLimit && !limits.topLimit:
        return top(config);
      default:
        return bottom(config);
    }
  } else {
    return bottom(config);
  }
}
function bottomLeftPosCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.bottomLimit && !limits.leftCornerLimit:
        return bottomLeft(config);
      case !limits.bottomLimit && limits.leftCornerLimit:
        return bottomRight(config);
      case limits.bottomLimit && !limits.topLimit && !limits.leftCornerLimit:
        return topLeft(config);
      case limits.bottomLimit && !limits.topLimit && limits.leftCornerLimit:
        return topRight(config);
      default:
        return bottomLeft(config);
    }
  } else {
    return bottomLeft(config);
  }
}
function bottomRightCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.bottomLimit && !limits.rightCornerLimit:
        return bottomRight(config);
      case !limits.bottomLimit && limits.rightCornerLimit:
        return bottomLeft(config);
      case limits.bottomLimit && !limits.topLimit && !limits.rightCornerLimit:
        return topRight(config);
      case limits.bottomLimit && !limits.topLimit && limits.rightCornerLimit:
        return topLeft(config);
      default:
        return bottomRight(config);
    }
  } else {
    return bottomRight(config);
  }
}
function leftPosCondtion(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.bottomLimit && !limits.leftLimit && limits.topLimit:
        return leftTop(config);
      case !limits.topLimit && !limits.leftLimit && limits.bottomLimit:
        return leftBottom(config);
      case !limits.rightLimit && limits.topLimit:
        return rightTop(config);
      case !limits.topLimit && !limits.rightLimit && limits.bottomLimit:
        return rightBottom(config);
      case limits.leftLimit && !limits.bottomLimit:
        return bottomLeft(config);
      case limits.rightLimit && !limits.bottomLimit:
        return bottomRight(config);
      case limits.leftLimit && limits.bottomLimit && !limits.topLimit:
        return topLeft(config);
      case limits.rightLimit && limits.bottomLimit && !limits.topLimit:
        return topRight(config);
      default:
        return left(config);
    }
  } else {
    return left(config);
  }
}
function leftTopPosCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.leftLimit && !limits.bottomLimit:
        return leftTop(config);
      case !limits.leftLimit && !limits.topLimit:
        return leftBottom(config);
      case !limits.rightLimit && !limits.bottomLimit:
        return rightTop(config);
      case !limits.rightLimit && !limits.topLimit:
        return rightBottom(config);
      case !limits.topLimit && !limits.rightLimit:
        return topLeft(config);
      case !limits.topLimit && !limits.leftLimit:
        return topRight(config);
      default:
        return leftTop(config);
    }
  } else {
    return leftTop(config);
  }
}
function leftBottomPosCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.leftLimit && !limits.topLimit:
        return leftBottom(config);
      case !limits.leftLimit && !limits.bottomLimit:
        return leftTop(config);
      case !limits.rightLimit && !limits.bottomLimit:
        return rightTop(config);
      case !limits.rightLimit && !limits.topLimit:
        return rightBottom(config);
      case !limits.topLimit && !limits.rightLimit:
        return topLeft(config);
      case !limits.topLimit && !limits.leftLimit:
        return topRight(config);
      case !limits.rightLimit:
        return bottomLeft(config);
      case !limits.leftLimit:
        return bottomRight(config);
      case limits.topLimit:
        return bottom(config);
      default:
        return leftBottom(config);
    }
  } else {
    return leftBottom(config);
  }
}
function rightPosCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.bottomLimit && !limits.topLimit && !limits.rightLimit:
        return right(config);
      case !limits.bottomLimit && !limits.rightLimit && limits.topLimit:
        return rightTop(config);
      case !limits.topLimit && !limits.rightLimit && limits.bottomLimit:
        return rightBottom(config);
      case !limits.bottomLimit && !limits.leftLimit && limits.topLimit:
        return leftTop(config);
      case !limits.topLimit && !limits.leftLimit && limits.bottomLimit:
        return leftBottom(config);
      case limits.leftLimit && !limits.bottomLimit:
        return bottomLeft(config);
      case limits.rightLimit && !limits.bottomLimit:
        return bottomRight(config);
      case limits.leftLimit && limits.bottomLimit && !limits.topLimit:
        return topLeft(config);
      case limits.rightLimit && limits.bottomLimit && !limits.topLimit:
        return topRight(config);
      default:
        return right(config);
    }
  } else {
    return right(config);
  }
}
function rightTopPosCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.rightLimit && !limits.bottomLimit:
        return rightTop(config);
      case !limits.rightLimit && !limits.topLimit:
        return rightBottom(config);
      case !limits.leftLimit && !limits.bottomLimit:
        return leftTop(config);
      case !limits.leftLimit && !limits.topLimit:
        return leftBottom(config);
      case !limits.topLimit && !limits.rightLimit:
        return topLeft(config);
      case !limits.topLimit && !limits.leftLimit:
        return topRight(config);
      default:
        return rightTop(config);
    }
  } else {
    return rightTop(config);
  }
}
function rightBottomPosCondition(config) {
  const limits = checkWindowLimits(config);
  if (config.isDynamic) {
    switch (true) {
      case !limits.rightLimit && !limits.topLimit:
        return rightBottom(config);
      case !limits.rightLimit:
        return rightTop(config);
      case !limits.leftLimit && !limits.topLimit:
        return leftBottom(config);
      case !limits.leftLimit && !limits.bottomLimit:
        return leftTop(config);
      case !limits.topLimit && !limits.leftLimit:
        return topRight(config);
      case !limits.leftLimit:
        return bottomRight(config);
      case limits.topLimit:
        return bottom(config);
      default:
        return rightBottom(config);
    }
  } else {
    return rightBottom(config);
  }
}
function checkPosition(position, config) {
  switch (position) {
    case PositionState.top:
      return topPosCondition(config);
    case PositionState.topLeft:
      return topLeftPosCondition(config);
    case PositionState.topRight:
      return topRightCondtion(config);
    case PositionState.bottom:
      return bottomPosCondition(config);
    case PositionState.bottomLeft:
      return bottomLeftPosCondition(config);
    case PositionState.bottomRight:
      return bottomRightCondition(config);
    case PositionState.left:
      return leftPosCondtion(config);
    case PositionState.leftTop:
      return leftTopPosCondition(config);
    case PositionState.leftBottom:
      return leftBottomPosCondition(config);
    case PositionState.right:
      return rightPosCondition(config);
    case PositionState.rightTop:
      return rightTopPosCondition(config);
    case PositionState.rightBottom:
      return rightBottomPosCondition(config);
    default:
      return topPosCondition(config);
  }
}
var BeakClass;
(function (BeakClass) {
  BeakClass["beak"] = "beak";
  BeakClass["top"] = "beak_top";
  BeakClass["bottom"] = "beak_bottom";
  BeakClass["left"] = "beak_left";
  BeakClass["right"] = "beak_right";
})(BeakClass || (BeakClass = {}));
var InvokeType;
(function (InvokeType) {
  InvokeType["click"] = "click";
  InvokeType["hover"] = "hover";
})(InvokeType || (InvokeType = {}));

// @dynamic
class PopupService {
  constructor(resolver, appRef, injector, ngZone, rendererFactory, document) {
    this.resolver = resolver;
    this.appRef = appRef;
    this.injector = injector;
    this.ngZone = ngZone;
    this.rendererFactory = rendererFactory;
    this.document = document;
    this.closedPopup = new Subject();
    this.popupList = [];
    this.popupElementCount = POPUP_ELEMENT_COUNT;
    this.documentKeydownListener = e => {
      if (e.keyCode === KeyCodes.ESC) {
        this.close(e);
      }
    };
    this.render = rendererFactory.createRenderer(null, null);
  }
  open(component, content, inputs, invokeType = InvokeType.click, hasBackdrop = true, stickerBackdropClass = '') {
    const factory = this.resolver.resolveComponentFactory(component);
    const [viewRef, ngContent] = this.resolveNgContent(content);
    const componentRef = ngContent ? factory.create(this.injector, ngContent) : factory.create(this.injector);
    const domElem = componentRef.location.nativeElement;
    if (inputs) {
      Object.keys(inputs).forEach(key => {
        componentRef.instance[key] = inputs[key];
      });
    }
    this.render.appendChild(this.document.body, domElem);
    componentRef.hostView.detectChanges();
    if (invokeType === InvokeType.click && hasBackdrop) {
      const backdrop = this.render.createElement('div');
      const backdropListener = this.render.listen(backdrop, 'click', e => this.close(e));
      this.render.addClass(backdrop, PopupClass.popupBackdrop);
      if (stickerBackdropClass) this.render.addClass(backdrop, stickerBackdropClass);
      this.render.appendChild(this.document.body, backdrop);
      this.popupList.push({
        component: componentRef,
        componentViewRef: viewRef,
        backdrop: backdrop,
        backdropListener: backdropListener
      });
    } else {
      this.popupList.push({
        component: componentRef,
        componentViewRef: viewRef
      });
    }
    this.incrementElementCount(domElem);
    this.setOrderPosition();
    return domElem;
  }
  close(event) {
    if (event) {
      event.stopPropagation();
    }
    const popupComponent = this.popupList.pop();
    if (popupComponent) {
      const popupEl = popupComponent.component.location.nativeElement;
      this.appRef.detachView(popupComponent.component.hostView);
      if (popupComponent.componentViewRef) {
        popupComponent.componentViewRef.destroy();
      }
      popupComponent.component.destroy();
      if (popupComponent.backdrop && popupComponent.backdropListener) {
        this.render.removeChild(this.document.body, popupComponent.backdrop);
        popupComponent.backdropListener();
      }
      this.decrementElementCount(popupEl);
      this.closedPopup.next(popupComponent.component.location.nativeElement);
    }
  }
  resolveNgContent(content) {
    if (!content) {
      return [null, null];
    }
    const viewRef = content.createEmbeddedView(null);
    this.appRef.attachView(viewRef);
    return [viewRef, [viewRef.rootNodes]];
  }
  incrementElementCount(popupEl) {
    if (++this.popupElementCount === 1) {
      this.ngZone.runOutsideAngular(() => {
        popupEl.addEventListener('keyup', this.documentKeydownListener);
      });
    }
  }
  decrementElementCount(popupEl) {
    if (! --this.popupElementCount) {
      popupEl.removeEventListener('keyup', this.documentKeydownListener);
    }
  }
  setOrderPosition() {
    this.popupList.forEach((popup, index) => {
      const backdropEl = popup.backdrop;
      const popupEl = popup.component.instance['el'].nativeElement;
      if (backdropEl) backdropEl.style.zIndex = `${BACKDROP_Z_INDEX + index}`;
      if (popupEl) popupEl.style.zIndex = `${POPUP_Z_INDEX + index}`;
    });
  }
  static {
    this.ɵfac = function PopupService_Factory(t) {
      return new (t || PopupService)(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PopupService,
      factory: PopupService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopupService, [{
    type: Injectable
  }], () => [{
    type: i0.ComponentFactoryResolver
  }, {
    type: i0.ApplicationRef
  }, {
    type: i0.Injector
  }, {
    type: i0.NgZone
  }, {
    type: i0.RendererFactory2
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BACKDROP_Z_INDEX, BeakClass, HALF_DIVIDER, INDENT, InvokeType, POPUP_ELEMENT_COUNT, POPUP_Z_INDEX, PopupService, SHIFT, WINDOW_TOP_LIMIT, addPosition, bottom, bottomLeft, bottomLeftPosCondition, bottomPosCondition, bottomRight, bottomRightCondition, checkPosition, checkWindowLimits, left, leftBottom, leftBottomPosCondition, leftPosCondtion, leftTop, leftTopPosCondition, right, rightBottom, rightBottomPosCondition, rightPosCondition, rightTop, rightTopPosCondition, top, topLeft, topLeftPosCondition, topPosCondition, topRight, topRightCondtion };
