import * as i0 from '@angular/core';
import { Injectable, Directive, Input, NgModule } from '@angular/core';
import { Devices, DevicesClass } from '@usitsdasdesign/dds-ng/shared';
import { fromEvent, Subscription } from 'rxjs';
import { share, map } from 'rxjs/operators';
const TABLET_BREAKPOINT = 1024;
const MOBILE_BREAKPOINT = 575;
class ResizeHandlerService {
  constructor() {
    this.tabletBreakPoint = TABLET_BREAKPOINT;
    this.mobileBreakPoint = MOBILE_BREAKPOINT;
    this.device$ = fromEvent(window, 'resize').pipe(share(), map(() => {
      return this.getDevice();
    }));
  }
  getDevice() {
    if (window.innerWidth <= this.mobileBreakPoint) {
      return Devices.mobile;
    } else if (window.innerWidth <= this.tabletBreakPoint) {
      return Devices.tablet;
    }
    return Devices.desktop;
  }
  static {
    this.ɵfac = function ResizeHandlerService_Factory(t) {
      return new (t || ResizeHandlerService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ResizeHandlerService,
      factory: ResizeHandlerService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeHandlerService, [{
    type: Injectable
  }], () => [], null);
})();
class ResizeHandlerDirective {
  get currentDevice() {
    return this._currentDevice;
  }
  set currentDevice(device) {
    this._currentDevice = device;
    this.removeDeviceClasses();
    this.addCurrentDeviceClass(device);
  }
  constructor(renderer, el, resizeHandlerService) {
    this.renderer = renderer;
    this.el = el;
    this.resizeHandlerService = resizeHandlerService;
    this.isResponsive = true;
    this.resizeSubscription = new Subscription();
  }
  ngOnInit() {
    if (this.isResponsive) {
      this.currentDevice = this.resizeHandlerService.getDevice();
      this.resizeSubscription = this.resizeHandlerService.device$.subscribe(device => this.currentDevice = device);
    }
  }
  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }
  addCurrentDeviceClass(device) {
    this.renderer.addClass(this.el.nativeElement, DevicesClass[device]);
  }
  removeDeviceClasses() {
    for (const key in Devices) {
      if (Devices[key]) this.renderer.removeClass(this.el.nativeElement, DevicesClass[key]);
    }
  }
  static {
    this.ɵfac = function ResizeHandlerDirective_Factory(t) {
      return new (t || ResizeHandlerDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(ResizeHandlerService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ResizeHandlerDirective,
      selectors: [["", "ddsResize", ""]],
      inputs: {
        isResponsive: "isResponsive"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeHandlerDirective, [{
    type: Directive,
    args: [{
      selector: '[ddsResize]',
      standalone: true
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: ResizeHandlerService
  }], {
    isResponsive: [{
      type: Input
    }]
  });
})();
class ResizeHandlerModule {
  static {
    this.ɵfac = function ResizeHandlerModule_Factory(t) {
      return new (t || ResizeHandlerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ResizeHandlerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [ResizeHandlerService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeHandlerModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [ResizeHandlerDirective],
      exports: [ResizeHandlerDirective],
      providers: [ResizeHandlerService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ResizeHandlerDirective, ResizeHandlerModule, ResizeHandlerService };
