import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@usitsdasdesign/dds-ng/select';
import { SelectModule } from '@usitsdasdesign/dds-ng/select';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import * as i1 from '@usitsdasdesign/dds-ng/shared/dds-config';
const _c0 = (a0, a1, a2) => ({
  "dds-items-per-page_lg": a0,
  "dds-items-per-page_sm": a1,
  "dds-items-per-page_inverse": a2
});
const DEFAULT_ICON = 'dds-icon_arrow-down';
const SHIFT = 0;
const DEFAULT_HEADING_PROP = 'heading';
class ItemsPerPageComponent {
  constructor(ddsConfig, cdr) {
    this.ddsConfig = ddsConfig;
    this.cdr = cdr;
    this.title = 'Items per page';
    this.icon = DEFAULT_ICON;
    this.size = this.ddsConfig.getConfig().size;
    this.isResponsive = this.ddsConfig.getConfig().isResponsive;
    this.headingProp = DEFAULT_HEADING_PROP;
    this.stickerIndent = this.ddsConfig.getConfig().stickerIndent;
    this.stickerShift = SHIFT;
    this.stickerPosition = this.ddsConfig.getConfig().stickerPosition;
    this.stickerIsDynamic = true;
    this.list = [];
    this.valueChanged = new EventEmitter();
  }
  ngOnInit() {
    this.checkSize(this.size);
  }
  ngOnChanges(changes) {
    this.checkSize(changes.size);
  }
  changeItem(value) {
    this.valueChanged.emit(value);
  }
  checkSize(size) {
    if (size) {
      this.isSizeLg = this.size === Size.lg;
      this.isSizeSm = this.size === Size.sm;
      this.cdr.markForCheck();
    }
  }
  static {
    this.ɵfac = function ItemsPerPageComponent_Factory(t) {
      return new (t || ItemsPerPageComponent)(i0.ɵɵdirectiveInject(i1.DdsConfigService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ItemsPerPageComponent,
      selectors: [["dds-items-per-page"]],
      inputs: {
        title: "title",
        placeholder: "placeholder",
        customClass: "customClass",
        icon: "icon",
        size: "size",
        isResponsive: "isResponsive",
        headingProp: "headingProp",
        isInverse: "isInverse",
        disabled: "disabled",
        stickerWidth: "stickerWidth",
        stickerMaxHeight: "stickerMaxHeight",
        stickerCustomClass: "stickerCustomClass",
        stickerIndent: "stickerIndent",
        stickerShift: "stickerShift",
        stickerIsDisabled: "stickerIsDisabled",
        stickerPosition: "stickerPosition",
        stickerIsDynamic: "stickerIsDynamic",
        list: "list",
        value: "value"
      },
      outputs: {
        valueChanged: "valueChanged"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 27,
      consts: [[3, "ngClass"], [1, "dds-items-per-page__title"], [3, "valueChanged", "isResponsive", "isInverse", "icon", "placeholder", "ariaLabel", "size", "list", "value", "disabled", "headingProp", "stickerWidth", "stickerShift", "stickerMaxHeight", "stickerIsDisabled", "stickerPosition", "stickerIndent", "stickerCustomClass", "stickerIsDynamic"]],
      template: function ItemsPerPageComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "span", 1);
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "dds-select", 2);
          i0.ɵɵlistener("valueChanged", function ItemsPerPageComponent_Template_dds_select_valueChanged_3_listener($event) {
            return ctx.changeItem($event);
          });
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-items-per-page ", ctx.customClass, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(23, _c0, ctx.isSizeLg, ctx.isSizeSm, ctx.isInverse));
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.title);
          i0.ɵɵadvance();
          i0.ɵɵproperty("isResponsive", ctx.isResponsive)("isInverse", ctx.isInverse)("icon", ctx.icon)("placeholder", ctx.placeholder)("ariaLabel", ctx.title)("size", ctx.size)("list", ctx.list)("value", ctx.value)("disabled", ctx.disabled)("headingProp", ctx.headingProp)("stickerWidth", ctx.stickerWidth)("stickerShift", ctx.stickerShift)("stickerMaxHeight", ctx.stickerMaxHeight)("stickerIsDisabled", ctx.stickerIsDisabled)("stickerPosition", ctx.stickerPosition)("stickerIndent", ctx.stickerIndent)("stickerCustomClass", ctx.stickerCustomClass)("stickerIsDynamic", ctx.stickerIsDynamic);
        }
      },
      dependencies: [CommonModule, i2.NgClass, SelectModule, i3.SelectComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ItemsPerPageComponent, [{
    type: Component,
    args: [{
      selector: 'dds-items-per-page',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [CommonModule, SelectModule],
      template: "<div [ngClass]=\"{\r\n        'dds-items-per-page_lg': isSizeLg,\r\n        'dds-items-per-page_sm': isSizeSm,\r\n        'dds-items-per-page_inverse': isInverse\r\n      }\"\r\n     class=\"dds-items-per-page {{customClass}}\">\r\n  <span class=\"dds-items-per-page__title\">{{title}}</span>\r\n  <dds-select [isResponsive]=\"isResponsive\"\r\n              [isInverse]=\"isInverse\"\r\n              [icon]=\"icon\"\r\n              [placeholder]=\"placeholder\"\r\n              [ariaLabel]=\"title\"\r\n              [size]=\"size\"\r\n              [list]=\"list\"\r\n              [value]=\"value\"\r\n              [disabled]=\"disabled\"\r\n              [headingProp]=\"headingProp\"\r\n              [stickerWidth]=\"stickerWidth\"\r\n              [stickerShift]=\"stickerShift\"\r\n              [stickerMaxHeight]=\"stickerMaxHeight\"\r\n              [stickerIsDisabled]=\"stickerIsDisabled\"\r\n              [stickerPosition]=\"stickerPosition\"\r\n              [stickerIndent]=\"stickerIndent\"\r\n              [stickerCustomClass]=\"stickerCustomClass\"\r\n              [stickerIsDynamic]=\"stickerIsDynamic\"\r\n              (valueChanged)=\"changeItem($event)\">\r\n  </dds-select>\r\n</div>"
    }]
  }], () => [{
    type: i1.DdsConfigService
  }, {
    type: i0.ChangeDetectorRef
  }], {
    title: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    isResponsive: [{
      type: Input
    }],
    headingProp: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    stickerWidth: [{
      type: Input
    }],
    stickerMaxHeight: [{
      type: Input
    }],
    stickerCustomClass: [{
      type: Input
    }],
    stickerIndent: [{
      type: Input
    }],
    stickerShift: [{
      type: Input
    }],
    stickerIsDisabled: [{
      type: Input
    }],
    stickerPosition: [{
      type: Input
    }],
    stickerIsDynamic: [{
      type: Input
    }],
    list: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    valueChanged: [{
      type: Output
    }]
  });
})();
class ItemsPerPageModule {
  static {
    this.ɵfac = function ItemsPerPageModule_Factory(t) {
      return new (t || ItemsPerPageModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ItemsPerPageModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [ItemsPerPageComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ItemsPerPageModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [ItemsPerPageComponent],
      exports: [ItemsPerPageComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ItemsPerPageComponent, ItemsPerPageModule };
