import { Accessible } from "../../shared/model/model";

enum filterKeyMap { modificationType = 'FilterType', decision = 'FilterDecision', requestor = 'FilterRequestor', processor = 'FilterProcessor', requestStartDate = 'StartDateAuditLog', requestEndDate = 'EndDateAuditLog', taxonomyStartDate = 'TaxonomyStartDate', taxonomyEndDate = 'TaxonomyEndDate' };

export type AuditLogFiltersType<T> = Accessible & { modificationType: T[]; decision: T[]; requestor: T[]; processor: T[]; requestDate?: Date[]; taxonomyDate?: Date[]; };
export type AuditLogFiltersParams = Accessible & { FilterType?: string[]; FilterDecision?: string[]; FilterRequestor?: string[]; FilterProcessor?: string[]; StartDateAuditLog?: string; EndDateAuditLog?: string; TaxonomyStartDate?: string; TaxonomyEndDate?: string; };

export class AuditLogFilters {
	private store: AuditLogFiltersType<{ id: number; description: string }>;

	public lists: AuditLogFiltersType<string>;

	public filters: Partial<AuditLogFiltersType<string>> = {};

	public get isFiltered(): boolean {
		return Object.keys(this.filters).map(key => this.filters[key]).flat().length > 0;
	}

	public clear() {
		Object.keys(this.filters).forEach(key => {
			switch (key) {
				case 'requestDate': case 'taxonomyDate':
					delete this.filters[key];
					break;
				default:
					this.filters[key] = [];
			}
		});
	}

	public forParams(): AuditLogFiltersParams {
		const params: AuditLogFiltersParams = {};

		Object.keys(this.filters).forEach(key => {
			const name = filterKeyMap[key as keyof typeof filterKeyMap];

			if (key === 'requestDate') {
				const { startDate, endDate } = this.splitDate(this.filters.requestDate);
				if (startDate && endDate) {
					params[filterKeyMap.requestStartDate] = startDate;
					params[filterKeyMap.requestEndDate] = endDate;
				}
				return;
			}

			if (key === 'taxonomyDate') {
				const { startDate, endDate } = this.splitDate(this.filters.taxonomyDate);
				if (startDate && endDate) {
					params[filterKeyMap.taxonomyStartDate] = startDate;
					params[filterKeyMap.taxonomyEndDate] = endDate;
				}
				return;
			}

			params[name] = this.store[key]
				.filter(({ description }: { description: string }) => this.filters[key].includes(description))
				.map(({ id, description }: { id: number, description: string }) => {
					switch (key) {
						case 'requestor':
							return description;
						default:
							return `${id ?? 'NULL'}`;
					}
				});

			// make unique
			(params[name] as string[]) = (params[name] as string[])?.filter((id: string, index: number) => params[name]?.indexOf(id) === index);
		});

		return params;
	}

	constructor(data: AuditLogFiltersType<{ id: number; description: string }> = { modificationType: [], decision: [], requestor: [], processor: [] }) {
		this.store = {
			modificationType: data.modificationType ?? [],
			decision: data.decision ?? [],
			requestor: data.requestor ?? [],
			processor: data.processor ?? []
		};
		this.lists = {
			modificationType: this.toList(this.store.modificationType),
			decision: this.toList(this.store.decision),
			requestor: this.toList(this.store.requestor),
			processor: this.toList(this.store.processor)
		};
	}

	private toList(items: { id: number, description: string }[]): string[] {
		return items
			.filter(item => item.description.length > 0 && item.description !== 'NULL')
			.map(item => item.description)
			.sort((a, b) => a.localeCompare(b));
	}

	private splitDate(dates?: Date[]): { startDate?: string, endDate?: string } {
		return {
			startDate: dates?.[0]?.toISOString().slice(0, 10),
			endDate: dates?.[1]?.toISOString().slice(0, 10)
		};
	}
}
