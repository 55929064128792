import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
var ThemeMapEnum;
(function (ThemeMapEnum) {
  ThemeMapEnum["Size"] = "sizeMap";
  ThemeMapEnum["Kind"] = "kindMap";
  ThemeMapEnum["WidthState"] = "widthStateMap";
  ThemeMapEnum["Themes"] = "ThemesMap";
  ThemeMapEnum["ThemesType"] = "ThemesTypeMap";
  ThemeMapEnum["KindMarker"] = "KindMarker";
})(ThemeMapEnum || (ThemeMapEnum = {}));
class ThemeDirective {
  constructor(renderer, elementRef) {
    this.renderer = renderer;
    this.elementRef = elementRef;
  }
  ngOnChanges(changes) {
    if (changes.size && changes.size.currentValue) {
      const prevValue = changes.size.previousValue;
      const currValue = changes.size.currentValue;
      this.removeClass(this.ddsTheme[ThemeMapEnum.Size][prevValue]);
      this.addClass(this.ddsTheme[ThemeMapEnum.Size][currValue]);
    }
    if (changes.kind && changes.kind.currentValue) {
      const prevValue = changes.kind.previousValue;
      const currValue = changes.kind.currentValue;
      this.removeClass(this.ddsTheme[ThemeMapEnum.Kind][prevValue]);
      this.addClass(this.ddsTheme[ThemeMapEnum.Kind][currValue]);
    }
    if (changes.themeType && changes.themeType.currentValue) {
      const prevValue = changes.themeType.previousValue;
      const currValue = changes.themeType.currentValue;
      this.removeClass(this.ddsTheme[ThemeMapEnum.ThemesType][prevValue]);
      this.addClass(this.ddsTheme[ThemeMapEnum.ThemesType][currValue]);
    }
    if (changes.theme && changes.theme.currentValue) {
      const prevValue = changes.theme.previousValue;
      const currValue = changes.theme.currentValue;
      this.removeClass(this.ddsTheme[ThemeMapEnum.Themes][prevValue]);
      this.addClass(this.ddsTheme[ThemeMapEnum.Themes][currValue]);
    }
    if (changes.width && changes.width.currentValue) {
      const prevValue = changes.width.previousValue;
      const currValue = changes.width.currentValue;
      this.removeClass(this.ddsTheme[ThemeMapEnum.WidthState][prevValue]);
      this.addClass(this.ddsTheme[ThemeMapEnum.WidthState][currValue]);
    }
    if (changes.kindMarker && changes.kindMarker.currentValue) {
      const prevValue = changes.kindMarker.previousValue;
      const currValue = changes.kindMarker.currentValue;
      this.removeClass(this.ddsTheme[ThemeMapEnum.KindMarker][prevValue]);
      this.addClass(this.ddsTheme[ThemeMapEnum.KindMarker][currValue]);
    }
  }
  addClass(className) {
    if (className) {
      this.renderer.addClass(this.elementRef.nativeElement, className);
    }
  }
  removeClass(className) {
    this.renderer.removeClass(this.elementRef.nativeElement, className);
  }
  static {
    this.ɵfac = function ThemeDirective_Factory(t) {
      return new (t || ThemeDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ThemeDirective,
      selectors: [["", "ddsTheme", ""]],
      inputs: {
        ddsTheme: "ddsTheme",
        size: "size",
        kind: "kind",
        theme: "theme",
        themeType: "themeType",
        width: "width",
        kindMarker: "kindMarker"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeDirective, [{
    type: Directive,
    args: [{
      selector: '[ddsTheme]',
      standalone: true
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], {
    ddsTheme: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    kind: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    themeType: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    kindMarker: [{
      type: Input
    }]
  });
})();
class ThemeModule {
  static {
    this.ɵfac = function ThemeModule_Factory(t) {
      return new (t || ThemeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ThemeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [ThemeDirective],
      exports: [ThemeDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ThemeDirective, ThemeMapEnum, ThemeModule };
