import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getSettings } from '../../shared/utils/settingsLoader';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private http: HttpClient = inject(HttpClient);
  settings = getSettings();

  constructor() { }

  public getDSTCardData(): Observable<any> {
    return this.http.get<any>(`${this.settings.apiUrl}/Dashboard/DstCard`);
  }

  public getMLCardData(): Observable<any> {
    return this.http.get<any>(`${this.settings.apiUrl}/Skill/MarketLibraryCard`);
  }

  public getPendingChanges(): Observable<any> {
    return this.http.get<any>(`${this.settings.apiUrl}/PendingChanges/GetLatest`);
  }
}