import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, Injectable, EventEmitter, Directive, Inject, Output, NgModule } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PopupService, checkPosition, addPosition, InvokeType } from '@usitsdasdesign/dds-ng/core/popup';
import { DevicesClass, Devices, PopupClass, PositionState, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
import { Subscription, Subject, fromEvent } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { DestroyMixin } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import * as i1 from '@usitsdasdesign/dds-ng/shared/resize-handler';
import { ResizeHandlerModule } from '@usitsdasdesign/dds-ng/shared/resize-handler';
import * as i2 from '@usitsdasdesign/dds-ng/shared/dds-config';
const _c0 = ["*"];
const StickerBase = DestroyMixin();
class StickerComponent extends StickerBase {
  get currentDevice() {
    return this._currentDevice;
  }
  set currentDevice(device) {
    this._currentDevice = device;
    this.removeDeviceClasses();
    this.addCurrentDeviceClass(device);
  }
  constructor(el, renderer, resizeHandlerService) {
    super();
    this.el = el;
    this.renderer = renderer;
    this.resizeHandlerService = resizeHandlerService;
  }
  ngOnInit() {
    if (this.isResponsive) {
      this.currentDevice = this.resizeHandlerService.getDevice();
      this.resizeHandlerService.device$.pipe(takeUntil(this.destroy)).subscribe(device => this.currentDevice = device);
    }
    if (this.class) {
      this.renderer.addClass(this.el.nativeElement, this.class);
    }
  }
  isNumeric(val) {
    return !(val instanceof Array) && val - parseFloat(val) + 1 >= 0;
  }
  addCurrentDeviceClass(device) {
    this.renderer.addClass(this.el.nativeElement, DevicesClass[device]);
  }
  removeDeviceClasses() {
    for (const key in Devices) {
      if (Devices[key]) this.renderer.removeClass(this.el.nativeElement, DevicesClass[key]);
    }
  }
  static {
    this.ɵfac = function StickerComponent_Factory(t) {
      return new (t || StickerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.ResizeHandlerService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: StickerComponent,
      selectors: [["dds-sticker"]],
      hostAttrs: [1, "popup"],
      hostVars: 4,
      hostBindings: function StickerComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("width", ctx.isNumeric(ctx.width) ? ctx.width + "px" : ctx.width)("max-height", ctx.isNumeric(ctx.maxHeight) ? ctx.maxHeight + "px" : ctx.maxHeight);
        }
      },
      inputs: {
        width: "width",
        maxHeight: "maxHeight",
        class: "class",
        isResponsive: "isResponsive"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function StickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      dependencies: [ResizeHandlerModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StickerComponent, [{
    type: Component,
    args: [{
      selector: 'dds-sticker',
      template: ` <ng-content></ng-content> `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: PopupClass.popup,
        '[style.width]': 'isNumeric(width) ? width + "px" : width',
        '[style.maxHeight]': 'isNumeric(maxHeight) ? maxHeight + "px" : maxHeight'
      },
      standalone: true,
      imports: [ResizeHandlerModule]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i1.ResizeHandlerService
  }], {
    width: [{
      type: Input
    }],
    maxHeight: [{
      type: Input
    }],
    class: [{
      type: Input
    }],
    isResponsive: [{
      type: Input
    }]
  });
})();
const INDENT = 8;
const SHIFT = 0;
const STICKER_ELEMENT_COUNT = 0;
const STICKER_IS_RESPONSIVE = true;

// @dynamic
class StickerService extends PopupService {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵStickerService_BaseFactory;
      return function StickerService_Factory(t) {
        return (ɵStickerService_BaseFactory || (ɵStickerService_BaseFactory = i0.ɵɵgetInheritedFactory(StickerService)))(t || StickerService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StickerService,
      factory: StickerService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StickerService, [{
    type: Injectable
  }], null, null);
})();
class StickerDirective {
  constructor(document, el, renderer, ngZone, stickerService, ddsConfig) {
    this.document = document;
    this.el = el;
    this.renderer = renderer;
    this.ngZone = ngZone;
    this.stickerService = stickerService;
    this.ddsConfig = ddsConfig;
    this.stickerIsDynamic = true;
    this.stickerIsResponsive = this.ddsConfig.getConfig().isResponsive;
    this.stickerPosition = PositionState.bottom;
    this.stickerIndent = INDENT;
    this.stickerShift = SHIFT;
    this.stickerClosingFocusEl = true;
    this.stickerHasBackdrop = true;
    this.stickerBackdropClass = '';
    this.onShown = new EventEmitter();
    this.onHidden = new EventEmitter();
    this.isActive = false;
    this.resize = new Subscription();
    this.scroll = new Subscription();
    this.destroy = new Subject();
    this.checkCoords = () => {
      setTimeout(() => {
        const popupLocationConfig = {
          elRect: this.currentEl.getBoundingClientRect(),
          popupRect: this.popupEl.getBoundingClientRect(),
          indent: this.stickerIndent,
          shift: this.stickerShift,
          isDynamic: this.stickerIsDynamic
        };
        const currentCoords = checkPosition(this.stickerPosition, popupLocationConfig);
        addPosition(this.popupEl, currentCoords.popupCoords);
        this.renderer.addClass(this.popupEl, PopupClass.popup_position);
      });
    };
  }
  ngOnChanges(changes) {
    if (changes.stickerClass && !this.stickerCustomClass) {
      this.stickerCustomClass = this.stickerClass;
    }
  }
  ngAfterViewInit() {
    this.currentEl = this.stickerEl || this.el.nativeElement;
    this.elClick = this.renderer.listen(this.el.nativeElement, 'click', event => this.show(event));
    this.stickerService.closedPopup.pipe(takeUntil(this.destroy)).subscribe(item => {
      if (item === this.popupEl) {
        if (this.stickerClosingFocusEl) {
          this.el.nativeElement.focus();
        }
        this.onHidden.emit();
      }
    });
  }
  ngOnDestroy() {
    if (this.elClick) {
      this.elClick();
    }
  }
  show(e) {
    // IE fix
    if (e) e.preventDefault();
    if (!this.stickerIsDisabled) {
      const stickerWidth = this.stickerWidth ? this.stickerWidth : this.currentEl.clientWidth;
      this.isActive = true;
      this.renderer.removeClass(this.el.nativeElement, AdditionalClass.keyboardFocus);
      this.popupEl = this.stickerService.open(StickerComponent, this.ddsSticker, {
        width: stickerWidth,
        maxHeight: this.stickerMaxHeight,
        class: this.stickerCustomClass,
        isResponsive: this.stickerIsResponsive
      }, InvokeType.click, this.stickerHasBackdrop, this.stickerBackdropClass);
      if (!this.stickerIsOutsideClick) {
        this.outsideCLick = this.renderer.listen(this.popupEl, 'click', event => this.hide(event));
      }
      this.checkCoords();
      if (this.stickerIsDynamic) this.resizeAndScrollListeners();
      this.onShown.emit();
    }
  }
  hide(e) {
    this.isActive = false;
    this.stickerService.close(e);
    this.el.nativeElement.focus();
    if (this.outsideCLick) {
      this.outsideCLick();
    }
    if (this.resize && this.scroll) {
      this.resize.unsubscribe();
      this.scroll.unsubscribe();
    }
  }
  updPosition() {
    this.checkCoords();
  }
  resizeAndScrollListeners() {
    this.ngZone.runOutsideAngular(() => {
      this.resize = fromEvent(window, 'resize').pipe(throttleTime(30)).subscribe(_ => this.checkCoords());
      this.scroll = fromEvent(this.document, 'scroll').pipe(throttleTime(30)).subscribe(_ => this.checkCoords());
    });
  }
  static {
    this.ɵfac = function StickerDirective_Factory(t) {
      return new (t || StickerDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(StickerService), i0.ɵɵdirectiveInject(i2.DdsConfigService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: StickerDirective,
      selectors: [["", "ddsSticker", ""]],
      inputs: {
        ddsSticker: "ddsSticker",
        stickerWidth: "stickerWidth",
        stickerMaxHeight: "stickerMaxHeight",
        stickerClass: "stickerClass",
        stickerCustomClass: "stickerCustomClass",
        stickerIsOutsideClick: "stickerIsOutsideClick",
        stickerIsDisabled: "stickerIsDisabled",
        stickerIsDynamic: "stickerIsDynamic",
        stickerIsResponsive: "stickerIsResponsive",
        stickerPosition: "stickerPosition",
        stickerIndent: "stickerIndent",
        stickerShift: "stickerShift",
        stickerEl: "stickerEl",
        stickerClosingFocusEl: "stickerClosingFocusEl",
        stickerHasBackdrop: "stickerHasBackdrop",
        stickerBackdropClass: "stickerBackdropClass"
      },
      outputs: {
        onShown: "onShown",
        onHidden: "onHidden"
      },
      exportAs: ["dds-sticker"],
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StickerDirective, [{
    type: Directive,
    args: [{
      selector: '[ddsSticker]',
      exportAs: 'dds-sticker',
      standalone: true
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i0.NgZone
  }, {
    type: StickerService
  }, {
    type: i2.DdsConfigService
  }], {
    ddsSticker: [{
      type: Input
    }],
    stickerWidth: [{
      type: Input
    }],
    stickerMaxHeight: [{
      type: Input
    }],
    stickerClass: [{
      type: Input
    }],
    stickerCustomClass: [{
      type: Input
    }],
    stickerIsOutsideClick: [{
      type: Input
    }],
    stickerIsDisabled: [{
      type: Input
    }],
    stickerIsDynamic: [{
      type: Input
    }],
    stickerIsResponsive: [{
      type: Input
    }],
    stickerPosition: [{
      type: Input
    }],
    stickerIndent: [{
      type: Input
    }],
    stickerShift: [{
      type: Input
    }],
    stickerEl: [{
      type: Input
    }],
    stickerClosingFocusEl: [{
      type: Input
    }],
    stickerHasBackdrop: [{
      type: Input
    }],
    stickerBackdropClass: [{
      type: Input
    }],
    onShown: [{
      type: Output
    }],
    onHidden: [{
      type: Output
    }]
  });
})();
class StickerModule {
  static {
    this.ɵfac = function StickerModule_Factory(t) {
      return new (t || StickerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: StickerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [StickerService],
      imports: [StickerComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StickerModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [StickerDirective, StickerComponent],
      exports: [StickerDirective],
      providers: [StickerService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { StickerDirective, StickerModule, StickerService };
