import { QueryList } from '@angular/core';
import { KeyCodes } from '@usitsdasdesign/dds-ng/shared';
import { Subject } from 'rxjs';

class ListManager {
    get activeItemIndex() {
        return this._activeItemIndex;
    }
    get activeItem() {
        return this._activeItem;
    }
    constructor(items) {
        this.items = items;
        this.tabOut = new Subject();
        this.change = new Subject();
        this.previousActiveItem = null;
        this._activeItemIndex = -1;
        this._activeItem = null;
        this._homeAndEnd = false;
        this.skipItemFn = (item) => item.disabled;
        if (items instanceof QueryList) {
            items.changes.subscribe((newItems) => {
                if (this._activeItem) {
                    const itemArray = newItems.toArray();
                    const newIndex = itemArray.indexOf(this._activeItem);
                    if (newIndex > -1 && newIndex !== this._activeItemIndex) {
                        this._activeItemIndex = newIndex;
                    }
                }
            });
        }
    }
    skipItem(item) {
        this.skipItemFn = item;
        return this;
    }
    withHomeAndEnd(enabled = true) {
        this._homeAndEnd = enabled;
        return this;
    }
    setActiveItem(item) {
        this.previousActiveItem = this._activeItem;
        this.updateActiveItem(item);
        if (this._activeItem !== this.previousActiveItem) {
            this.change.next(this._activeItemIndex);
        }
    }
    onKeydown(event, preventTab = true) {
        const keyCode = event.keyCode;
        switch (keyCode) {
            case KeyCodes.TAB:
                if (preventTab)
                    event.preventDefault();
                this.tabOut.next();
                return;
            case KeyCodes.ARROW_DOWN:
            case KeyCodes.ARROW_RIGHT:
                event.preventDefault();
                this.setNextItemActive();
                break;
            case KeyCodes.ARROW_UP:
            case KeyCodes.ARROW_LEFT:
                event.preventDefault();
                this.setPreviousItemActive();
                break;
            case KeyCodes.HOME:
                if (this._homeAndEnd) {
                    event.preventDefault();
                    this.setFirstItemActive();
                    break;
                }
                else {
                    return;
                }
            case KeyCodes.END:
                if (this._homeAndEnd) {
                    event.preventDefault();
                    this.setLastItemActive();
                    break;
                }
                else {
                    return;
                }
        }
    }
    updateActiveItem(item) {
        const itemArray = this.getItemsList();
        const index = typeof item === 'number' ? this.getNextIndex(item, 1) : itemArray.indexOf(item);
        const activeItem = itemArray[index];
        this._activeItem = activeItem == null ? null : activeItem;
        this._activeItemIndex = index;
    }
    setFirstItemActive() {
        const firstIndex = this.getNextIndex(0, 1);
        this.setActiveItem(firstIndex);
    }
    setLastItemActive() {
        const lastIndex = this.getNextIndex(this.items.length - 1, -1);
        this.setActiveItem(lastIndex);
    }
    setNextItemActive() {
        const nextIndex = this.getNextIndex(this._activeItemIndex + 1, 1);
        nextIndex > this.getItemsList().length - 1 ? this.setFirstItemActive() : this.setActiveItem(nextIndex);
    }
    setPreviousItemActive() {
        const nextIndex = this.getNextIndex(this._activeItemIndex - 1, -1);
        nextIndex < 0 ? this.setLastItemActive() : this.setActiveItem(nextIndex);
    }
    getNextIndex(index, fallbackDelta) {
        const items = this.getItemsList();
        if (!items[index]) {
            return index;
        }
        while (this.skipItemFn(items[index])) {
            index += fallbackDelta;
            if (!items[index]) {
                return index;
            }
        }
        return index;
    }
    getItemsList() {
        return this.items instanceof QueryList ? this.items.toArray() : this.items;
    }
}

/**
 * Generated bundle index. Do not edit.
 */

export { ListManager };

