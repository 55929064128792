import { InjectionToken } from '@angular/core';

const DDS_FORM_CONTROL = new InjectionToken('DdsFormControl');
const DDS_FORM_CONTROL_GROUP = new InjectionToken('DdsFormControlGroup');
var ErrorState;
(function (ErrorState) {
    ErrorState["default"] = "default";
    ErrorState["focusOut"] = "focusOut";
})(ErrorState || (ErrorState = {}));
var TableDataTypes;
(function (TableDataTypes) {
    TableDataTypes["string"] = "string";
    TableDataTypes["number"] = "number";
    TableDataTypes["date"] = "date";
})(TableDataTypes || (TableDataTypes = {}));
var Size;
(function (Size) {
    Size["sm"] = "sm";
    Size["md"] = "md";
    Size["lg"] = "lg";
})(Size || (Size = {}));
var Kind;
(function (Kind) {
    Kind["primary"] = "primary";
    Kind["secondary"] = "secondary";
    Kind["danger"] = "danger";
})(Kind || (Kind = {}));
var ButtonKind;
(function (ButtonKind) {
    ButtonKind["primaryLoud"] = "primaryLoud";
    ButtonKind["primary"] = "primary";
    ButtonKind["secondaryLoud"] = "secondaryLoud";
    ButtonKind["secondary"] = "secondary";
    ButtonKind["silent"] = "silent";
})(ButtonKind || (ButtonKind = {}));
var SpinnerThemes;
(function (SpinnerThemes) {
    SpinnerThemes["green"] = "green";
    SpinnerThemes["danger"] = "danger";
    SpinnerThemes["blue"] = "blue";
    SpinnerThemes["dark"] = "dark";
    SpinnerThemes["gray"] = "gray";
})(SpinnerThemes || (SpinnerThemes = {}));
var TabThemeType;
(function (TabThemeType) {
    TabThemeType["fill"] = "fill";
    TabThemeType["border"] = "border";
})(TabThemeType || (TabThemeType = {}));
var WidthState;
(function (WidthState) {
    WidthState["fixed"] = "fixed";
    WidthState["full"] = "full";
})(WidthState || (WidthState = {}));
var PositionState;
(function (PositionState) {
    PositionState["top"] = "top";
    PositionState["topLeft"] = "top-left";
    PositionState["topRight"] = "top-right";
    PositionState["bottom"] = "bottom";
    PositionState["bottomLeft"] = "bottom-left";
    PositionState["bottomRight"] = "bottom-right";
    PositionState["left"] = "left";
    PositionState["leftTop"] = "left-top";
    PositionState["leftBottom"] = "left-bottom";
    PositionState["right"] = "right";
    PositionState["rightTop"] = "right-top";
    PositionState["rightBottom"] = "right-bottom";
})(PositionState || (PositionState = {}));
var MaxLengthPositions;
(function (MaxLengthPositions) {
    MaxLengthPositions["topLeft"] = "top-left";
    MaxLengthPositions["topRight"] = "top-right";
    MaxLengthPositions["bottomLeft"] = "bottom-left";
    MaxLengthPositions["bottomRight"] = "bottom-right";
})(MaxLengthPositions || (MaxLengthPositions = {}));
var LabelPosition;
(function (LabelPosition) {
    LabelPosition["internal"] = "internal";
    LabelPosition["external"] = "external";
})(LabelPosition || (LabelPosition = {}));
var TableClass;
(function (TableClass) {
    TableClass["expandableTable"] = "dds-data-table_expandable";
    TableClass["expandableRow"] = "dds-data-table__row_expandable";
    TableClass["expandableRowExpanded"] = "dds-data-table__row_expanded";
    TableClass["expandableRowContent"] = "dds-data-table__row_expandable-content";
})(TableClass || (TableClass = {}));
var PopupClass;
(function (PopupClass) {
    PopupClass["popup"] = "popup";
    PopupClass["popupBackdrop"] = "popup-backdrop";
    PopupClass["popup_active"] = "popup_active";
    PopupClass["popup_position"] = "popup_position";
})(PopupClass || (PopupClass = {}));
var AdditionalClass;
(function (AdditionalClass) {
    AdditionalClass["flex"] = "dds-flex";
    AdditionalClass["inlineFlex"] = "dds-inline-flex";
    AdditionalClass["disabled"] = "dds-disabled";
    AdditionalClass["fullWidth"] = "dds-full-width";
    AdditionalClass["maxWidth"] = "dds-max-width";
    AdditionalClass["keyboardFocus"] = "dds-keyboard-focused";
    AdditionalClass["focus"] = "dds-focused";
})(AdditionalClass || (AdditionalClass = {}));
var DevicesClass;
(function (DevicesClass) {
    DevicesClass["desktop"] = "dds-desktop";
    DevicesClass["tablet"] = "dds-tablet";
    DevicesClass["mobile"] = "dds-mobile";
})(DevicesClass || (DevicesClass = {}));
var Direction;
(function (Direction) {
    Direction["top"] = "top";
    Direction["left"] = "left";
    Direction["right"] = "right";
    Direction["bottom"] = "bottom";
})(Direction || (Direction = {}));
var KeyCodes;
(function (KeyCodes) {
    KeyCodes[KeyCodes["ARROW_UP"] = 38] = "ARROW_UP";
    KeyCodes[KeyCodes["ARROW_LEFT"] = 37] = "ARROW_LEFT";
    KeyCodes[KeyCodes["ARROW_RIGHT"] = 39] = "ARROW_RIGHT";
    KeyCodes[KeyCodes["ARROW_DOWN"] = 40] = "ARROW_DOWN";
    KeyCodes[KeyCodes["BACKSPACE"] = 8] = "BACKSPACE";
    KeyCodes[KeyCodes["MINUS"] = 189] = "MINUS";
    KeyCodes[KeyCodes["TAB"] = 9] = "TAB";
    KeyCodes[KeyCodes["ESC"] = 27] = "ESC";
    KeyCodes[KeyCodes["ENTER"] = 13] = "ENTER";
    KeyCodes[KeyCodes["SPACE"] = 32] = "SPACE";
    KeyCodes[KeyCodes["HOME"] = 36] = "HOME";
    KeyCodes[KeyCodes["END"] = 35] = "END";
    KeyCodes[KeyCodes["KEY_A"] = 65] = "KEY_A";
    KeyCodes[KeyCodes["KEY_P"] = 80] = "KEY_P";
})(KeyCodes || (KeyCodes = {}));
var SortStates;
(function (SortStates) {
    SortStates["none"] = "none";
    SortStates["asc"] = "asc";
    SortStates["desc"] = "desc";
})(SortStates || (SortStates = {}));
var Themes;
(function (Themes) {
    Themes["green"] = "green";
    Themes["dark"] = "dark";
    Themes["blue"] = "blue";
})(Themes || (Themes = {}));
var ExtThemes;
(function (ExtThemes) {
    ExtThemes["green"] = "green";
    ExtThemes["dark"] = "dark";
    ExtThemes["blue"] = "blue";
    ExtThemes["danger"] = "danger";
})(ExtThemes || (ExtThemes = {}));
var SortingStates;
(function (SortingStates) {
    SortingStates["descending"] = "descending";
    SortingStates["ascending"] = "ascending";
})(SortingStates || (SortingStates = {}));
var Devices;
(function (Devices) {
    Devices["desktop"] = "desktop";
    Devices["tablet"] = "tablet";
    Devices["mobile"] = "mobile";
})(Devices || (Devices = {}));
var DecimalMarkers;
(function (DecimalMarkers) {
    DecimalMarkers["point"] = ".";
    DecimalMarkers["comma"] = ",";
})(DecimalMarkers || (DecimalMarkers = {}));
var ThousandSeparators;
(function (ThousandSeparators) {
    ThousandSeparators["point"] = ".";
    ThousandSeparators["comma"] = ",";
    ThousandSeparators["whiteSpace"] = " ";
})(ThousandSeparators || (ThousandSeparators = {}));
var KindMarker;
(function (KindMarker) {
    KindMarker["info"] = "info";
    KindMarker["danger"] = "danger";
    KindMarker["warning"] = "warning";
    KindMarker["success"] = "success";
})(KindMarker || (KindMarker = {}));
const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

/**
 * Generated bundle index. Do not edit.
 */

export { AdditionalClass, ButtonKind, DDS_FORM_CONTROL, DDS_FORM_CONTROL_GROUP, DEFAULT_DATE_FORMAT, DecimalMarkers, Devices, DevicesClass, Direction, ErrorState, ExtThemes, KeyCodes, Kind, KindMarker, LabelPosition, MaxLengthPositions, PopupClass, PositionState, Size, SortStates, SortingStates, SpinnerThemes, TabThemeType, TableClass, TableDataTypes, Themes, ThousandSeparators, WidthState };

