function coerceBooleanProperty(value) {
    return value != null && `${value}` !== 'false';
}

function dec2hex(dec) {
    return dec.toString(16);
}
function generateId() {
    const arr = new Uint8Array(5);
    const crypto = window.crypto || window.msCrypto;
    crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
}

/**
 * Generated bundle index. Do not edit.
 */

export { coerceBooleanProperty, generateId };

