import { Component, OnInit, signal } from '@angular/core';
import { HomeService } from '../../home.service';
import { PendingChanges } from '../../../../shared/model/pending-changes';
import { firstValueFrom } from 'rxjs';
import { RouterModule } from '@angular/router';
import { Accessible } from '../../../../shared/model/model';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { TableRow, TableComponent, TableLoadEvent } from "../../../../shared/components/table/table.component";

@Component({
  selector: 'app-pend-changes-table',
  standalone: true,
  imports: [TableComponent, ButtonComponent, RouterModule],
  templateUrl: './pend-changes-table.component.html',
  styleUrl: './pend-changes-table.component.sass'
})
export class PendChangesTableComponent {
  public latest: (PendingChanges & TableRow & Accessible)[] = [];
  public columns = [
    { name: 'category', header: 'Category', dataType: 'string', sortable: true },
    { name: 'subcategory', header: 'Subcategory', dataType: 'string', sortable: true },
    { name: 'skillName', header: 'Skill Name', dataType: 'string', sortable: true },
    { name: 'type', header: 'Type', dataType: 'string', sortable: true },
    { name: 'request', header: 'Request', dataType: 'string', sortable: false },
    { name: 'catalog', header: 'Catalog', dataType: 'string', sortable: true }
  ];
  public isLoading: boolean = false;
  public totalSize = 0;
  constructor(
    private homeService: HomeService
  ) { }

  public async getLatest(): Promise<void> {
    this.isLoading = true;
    try {
      const data = await firstValueFrom(this.homeService.getPendingChanges());

      // Si la respuesta es un solo objeto, lo convertimos a un array
      this.latest = Array.isArray(data) ? data : [data]; // Aseguramos que siempre sea un array
      this.latest.sort((a, b) => new Date(b.request).getTime() - new Date(a.request).getTime());
      this.totalSize = this.latest.length; // Total size será el tamaño del array
    } catch (error) {
      //console.error("Error fetching pending changes:", error);
      this.latest = [];
      this.totalSize = 0;
    } finally {
      this.isLoading = false;
    }
  }

}
