<h2>{{fullPCData.pchName}}</h2>
<div class="skill-changes-wrapper">
	<div class="section-header">
		<span>
			<h3>Skill Changes</h3>
		</span>
		<span class="request-type-tag">{{requestTypeName}}</span>
		</div>
	<hr />
	@if(fullPCData.requestTypeId === 3 || fullPCData.requestTypeId === 4) {

		@for(item of changesList; track item.label){
		@if(item.label != 'Tags'){
		<app-pending-change-item [Item]="item"></app-pending-change-item>
		}@else if(addedTags.length > 0 || removedTags.length > 0){
		<label class="tags-title">
			TAGS
		</label>
		@if(addedTags.length > 0){
		<p class="tags-collection-title">NEW</p>
		<div class="tags-container">
			@for(tag of addedTags; track tag){
			<span class="pseudo-tag new">{{tag}}</span>
			}
		</div>
		}
		@if(removedTags.length > 0){
		<p class="tags-collection-title">REMOVED</p>
		<div class="tags-container">
			@for(tag of removedTags; track tag){
			<span class="pseudo-tag">{{tag}}</span>
			}
		</div>
		}
		}
		}
	}
</div>
