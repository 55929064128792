import { inject, Injectable } from '@angular/core';
import { User } from '../model/user';
import { Observable, of, tap } from 'rxjs';
import { SortOptions } from '@usitsdasdesign/dds-ng/shared';
import { HttpClient } from '@angular/common/http';
import { Role } from '../model/role';
import { JwtService } from './jwt.service';
import { PaginatedData } from '../model/model';
import { getSettings } from '../utils/settingsLoader';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private http: HttpClient = inject(HttpClient);
	private jwtService: JwtService = inject(JwtService);
	settings = getSettings();

	public getUsers(page: number, pageSize: number, sort: SortOptions, query?: string): Observable<PaginatedData<User>> {
		const params = new URLSearchParams();
		params.append('PageNumber', (page - 1).toString());
		params.append('PageSize', pageSize.toString());

		if (sort.property?.length) {
			params.append('OrderBy', sort.property);
			params.append('IsAscending', sort.descending ? 'false' : 'true');
		}

		if (query?.length) {
			params.append('Search', query);
		}

		return this.http.get<PaginatedData<User>>(`${this.settings.apiUrl}/User?${params.toString()}`);
	}

	public getUserByCriteria(query?: string): Observable<{ data: Partial<User>[] }> {
		const params = new URLSearchParams();
		if (query) {
			params.append('SearchTerm', query);
		}
		return this.http.get<{ data: Partial<User>[] }>(`${this.settings.apiUrl}/User/no-roles?${params.toString()}`);
	}

	public getUserRole(): Observable<{ data: Partial<Role>[] }> {
		return this.http.get<{ data: Partial<Role>[] }>(`${this.settings.apiUrl}/Role`);
	}

	public saveUserRoleChange(user: Partial<User>): Observable<Object> {
		const processorId = localStorage.getItem('userId');
		return this.http.put(`${this.settings.apiUrl}/User/${user.id}/role/${user.role_id}/processedBy/${processorId}`, null);
	}

	public updateUserRole(userId: number, newRoleId: number): Observable<{ message: string }> {
		const processorId = localStorage.getItem('userId');
		return this.http.put<{ message: string }>(`${this.settings.apiUrl}/User/${userId}/Role/${newRoleId}/processedBy/${processorId}`, {});
	}

	getUserLoggedRole() {
		return this.http.get(`${this.settings.apiUrl}/User/GetRole?userMail=${this.jwtService.decodeToken().preferred_username}`)
			//role id y userID setearla en el local storage.

			.pipe(tap((data: any) => {
				localStorage.setItem('role', data[0].roleId);
				localStorage.setItem('userId', data[0].userId);
				localStorage.setItem('roleName', data[0].rol);
				localStorage.setItem('name', this.jwtService.decodeToken().name);
			}));
	}
}
