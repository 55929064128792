<p class="disclaimer">
  For optimal management we suggest maintaining a <strong>maximum of 3 Admin Approvers</strong> and <strong> 10 Admin
    Users.</strong>
</p>

<form #form="ngForm" (ngSubmit)="save()">
  <div class="user-role-container">
    <div class="user-info">
      <p class="name">{{ user.first_name }} {{ user.last_name }}</p>
      <p class="email">{{ user.email }}</p>
    </div>
    <div class="role-dropdown">
      <select name="role_id" id="roleSelect" [(ngModel)]="user.role_id">
        @for (role of availableRoles; track role.id)
        {
          <option [value]="role.id">{{ role.name }}</option>
        }
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="cancel-button" (click)="closeModal()" aria-label="Cancel" >Cancel</button>
    <button type="submit" class="save-button" aria-label="Save Privileges" >Save Privileges</button>
  </div>
</form>
