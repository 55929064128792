import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewChild, Input, NgModule } from '@angular/core';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import { DestroyMixin, I18nMixin, FormAccessorMixin, valueAccessor } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import * as i4 from '@usitsdasdesign/dds-ng/core/theme';
import { ThemeMapEnum, ThemeModule } from '@usitsdasdesign/dds-ng/core/theme';
import { Size, Themes, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
import * as i1 from '@usitsdasdesign/dds-ng/shared/i18n';
import { I18nModule } from '@usitsdasdesign/dds-ng/shared/i18n';
import { TooltipModule } from '@usitsdasdesign/dds-ng/tooltip';
import * as i2 from '@usitsdasdesign/dds-ng/shared/dds-config';
const _c0 = ["rootElem"];
const _c1 = (a0, a1, a2, a3, a4, a5, a6) => ({
  "dds-progress_indeterminate": a0,
  "dds-progress_error": a1,
  "dds-progress_without-label": a2,
  "dds-progress_without-description": a3,
  "dds-progress_without-info": a4,
  "dds-progress_inverse": a5,
  "dds-progress_vertical": a6
});
function ProgressComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function ProgressComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.description);
  }
}
function ProgressComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.errorMessage);
  }
}
const themeClassesMap = {
  [ThemeMapEnum.Size]: {
    [Size.lg]: 'dds-progress_lg'
  },
  [ThemeMapEnum.Themes]: {
    [Themes.green]: 'dds-progress_green',
    [Themes.blue]: 'dds-progress_blue'
  }
};
const ARIA_VALUENOW = 'aria-valuenow';
const ProgressBase = DestroyMixin(I18nMixin(FormAccessorMixin()));
class ProgressComponent extends ProgressBase {
  constructor(cdr, renderer, i18n, ddsConfig) {
    super();
    this.cdr = cdr;
    this.renderer = renderer;
    this.i18n = i18n;
    this.ddsConfig = ddsConfig;
    this.size = this.ddsConfig.getConfig().size;
    this.theme = this.ddsConfig.getConfig().theme;
    this.themeConfig = themeClassesMap;
  }
  ngOnChanges(changes) {
    const rootElem = this.rootElem?.nativeElement;
    if (changes.isVertical && changes.isVertical.currentValue) {
      this.progressValueStyle();
    }
    if (changes.isIndeterminate && rootElem) {
      if (changes.isIndeterminate.currentValue) {
        this.renderer.removeAttribute(rootElem, ARIA_VALUENOW);
      } else {
        this.renderer.setAttribute(rootElem, ARIA_VALUENOW, `${this.value}`);
      }
    }
    if (changes.value && !this.isIndeterminate && rootElem) {
      this.renderer.setAttribute(rootElem, ARIA_VALUENOW, `${changes.value}`);
    }
  }
  writeValue(value) {
    this.value = value;
    this.cdr.markForCheck();
  }
  progressValueStyle() {
    return this.isVertical ? {
      height: this.value + '%'
    } : {
      width: this.value + '%'
    };
  }
  getValueText() {
    return `${this.ariaLabel} ${this.value}%`;
  }
  setI18nTexts(translation) {
    this.ariaLabel = translation.PROGRESS.A11Y.ARIA_LABEL;
    this.cdr.detectChanges();
  }
  static {
    this.ɵfac = function ProgressComponent_Factory(t) {
      return new (t || ProgressComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.I18nService), i0.ɵɵdirectiveInject(i2.DdsConfigService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProgressComponent,
      selectors: [["dds-progress"]],
      viewQuery: function ProgressComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rootElem = _t.first);
        }
      },
      hostAttrs: [1, "dds-flex"],
      inputs: {
        size: "size",
        theme: "theme",
        label: "label",
        description: "description",
        errorMessage: "errorMessage",
        customClass: "customClass",
        isIndeterminate: "isIndeterminate",
        isError: "isError",
        isInverse: "isInverse",
        isVertical: "isVertical",
        value: "value"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([valueAccessor(ProgressComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 7,
      vars: 23,
      consts: [["rootElem", ""], ["role", "progressbar", "aria-valuemin", "0", "aria-valuemax", "100", 3, "ngClass", "theme", "size", "ddsTheme"], [1, "dds-progress__label"], [1, "dds-progress__bar"], [1, "dds-progress__value", 3, "ngStyle"], [1, "dds-progress__description"], [1, "dds-progress__description", "dds-progress__description_error"]],
      template: function ProgressComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 1, 0);
          i0.ɵɵtemplate(2, ProgressComponent_Conditional_2_Template, 2, 1, "label", 2);
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵelement(4, "span", 4);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(5, ProgressComponent_Conditional_5_Template, 2, 1, "div", 5)(6, ProgressComponent_Conditional_6_Template, 2, 1, "div", 6);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-progress ", ctx.customClass, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction7(15, _c1, ctx.isIndeterminate, ctx.isError, !ctx.label, !ctx.description, !ctx.description && !ctx.label, ctx.isInverse, ctx.isVertical))("theme", ctx.theme)("size", ctx.size)("ddsTheme", ctx.themeConfig);
          i0.ɵɵattribute("aria-label", ctx.label || ctx.getValueText())("aria-valuenow", ctx.value)("aria-valuetext", ctx.getValueText())("aria-invalid", ctx.isError);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.label ? 2 : -1);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngStyle", ctx.progressValueStyle());
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.description && !ctx.errorMessage ? 5 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.errorMessage ? 6 : -1);
        }
      },
      dependencies: [CommonModule, i3.NgClass, i3.NgStyle, TooltipModule, ThemeModule, i4.ThemeDirective, I18nModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressComponent, [{
    type: Component,
    args: [{
      selector: 'dds-progress',
      providers: [valueAccessor(ProgressComponent)],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: AdditionalClass.flex
      },
      standalone: true,
      imports: [CommonModule, TooltipModule, ThemeModule, I18nModule],
      template: "<div [ngClass]=\"{\r\n      'dds-progress_indeterminate': isIndeterminate,\r\n      'dds-progress_error': isError,\r\n      'dds-progress_without-label': !label,\r\n      'dds-progress_without-description': !description,\r\n      'dds-progress_without-info': !description && !label,\r\n      'dds-progress_inverse': isInverse,\r\n      'dds-progress_vertical': isVertical\r\n    }\"\r\n     [theme]=\"theme\"\r\n     [size]=\"size\"\r\n     [ddsTheme]=\"themeConfig\"\r\n     [attr.aria-label]=\"label || getValueText()\"\r\n     [attr.aria-valuenow]=\"value\"\r\n     [attr.aria-valuetext]=\"getValueText()\"\r\n     [attr.aria-invalid]=\"isError\"\r\n     role=\"progressbar\"\r\n     aria-valuemin=\"0\"\r\n     aria-valuemax=\"100\"\r\n     class=\"dds-progress {{customClass}}\"\r\n     #rootElem>\r\n  @if (label) {\r\n    <label class=\"dds-progress__label\">{{ label }}</label>\r\n  }\r\n\r\n  <div class=\"dds-progress__bar\">\r\n    <span [ngStyle]=\"progressValueStyle()\"\r\n          class=\"dds-progress__value\"></span>\r\n  </div>\r\n\r\n  @if (description && !errorMessage) {\r\n    <div class=\"dds-progress__description\">{{ description }}</div>\r\n  }\r\n  @if (errorMessage) {\r\n    <div class=\"dds-progress__description dds-progress__description_error\">{{ errorMessage }}</div>\r\n  }\r\n</div>"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.Renderer2
  }, {
    type: i1.I18nService
  }, {
    type: i2.DdsConfigService
  }], {
    rootElem: [{
      type: ViewChild,
      args: ['rootElem', {
        static: true
      }]
    }],
    size: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    description: [{
      type: Input
    }],
    errorMessage: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    isIndeterminate: [{
      type: Input
    }],
    isError: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    isVertical: [{
      type: Input
    }],
    value: [{
      type: Input
    }]
  });
})();
class ProgressModule {
  static {
    this.ɵfac = function ProgressModule_Factory(t) {
      return new (t || ProgressModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProgressModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [ProgressComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [ProgressComponent],
      exports: [ProgressComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ProgressComponent, ProgressModule };
