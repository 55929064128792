
<div class="dds-row">
    <div class="dds-col">
        <div class="section-title">
            Dashboard
        </div>
    </div>
</div>

<app-cards></app-cards>
<app-pend-changes-table></app-pend-changes-table>
<app-last-mod-table></app-last-mod-table>


