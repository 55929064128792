import { Component, Input } from '@angular/core';
import { PendingChangeItemComponent } from '../pending-change-item/pending-change-item.component';
import { FullPendingChangesData } from '../pending-change-details/pending-change-details.component';
import { masterSkillTechnologies, masterSkillTypes } from '../../../../shared/enums/enums';

@Component({
  selector: 'app-skill-changes-details',
  standalone: true,
  imports: [PendingChangeItemComponent],
  templateUrl: './skill-changes-details.component.html',
  styleUrl: './skill-changes-details.component.sass'
})
export class SkillChangesDetailsComponent {
  @Input() fullPCData!: FullPendingChangesData;
  @Input() availableTags: any = [];
  @Input() availableCategories: any = [];
  @Input() availableSubCategories: any = [];
  changesList: any = [];
  addedTags: any = [];
  removedTags: any = [];
  requestTypeName: string = '';

  ngOnInit() {
    this.populateChangesList();
    this.getRequestTypeName(this.fullPCData.requestTypeId);
  }

  private populateChangesList() {
    //ther order of the changes is important to keep the same order in the UI
    if (this.fullPCData.skillName !== this.fullPCData.pchName) this.changesList.push({ label: 'Name', originalValue: this.fullPCData.skillName, pcValue: this.fullPCData.pchName })
    if (this.fullPCData.skillCategoryId !== this.fullPCData.pchCategoryId) this.changesList.push({ label: 'Category', originalValue: this.fullPCData.skillCategoryId, pcValue: this.fullPCData.pchCategoryId })
    if (this.fullPCData.skillSubCategoryId !== this.fullPCData.pchSubCategoryId) this.changesList.push({ label: 'Sub Category', originalValue: this.fullPCData.skillSubCategoryId, pcValue: this.fullPCData.pchSubCategoryId })
    if (this.fullPCData.skillTypeId !== this.fullPCData.requestTypeId) this.changesList.push({ label: 'Type', originalValue: this.fullPCData.skillTypeId, pcValue: this.fullPCData.pchTypeId })
    if (this.fullPCData.skillTechnologyId !== this.fullPCData.pchTechnologyId) this.changesList.push({ label: 'Technology', originalValue: this.fullPCData.skillTechnologyId, pcValue: this.fullPCData.pchTechnologyId })
    if (this.fullPCData.skillLink !== this.fullPCData.pchLink) this.changesList.push({ label: 'Description URL', originalValue: this.fullPCData.skillLink, pcValue: this.fullPCData.pchLink })
    if (this.fullPCData.skillDescription !== this.fullPCData.pchDescription) {
      this.changesList.push({ label: 'Description', originalValue: this.fullPCData.skillDescription, pcValue: this.fullPCData.pchDescription, textShortener: true })
    }
    if (!!this.fullPCData.skillTags || !!this.fullPCData.pchTags) {
      this.changesList.push({ label: 'Tags', originalValue: this.fullPCData.skillTags, pcValue: this.fullPCData.pchTags })
      this.processTagsChangeLog()
    }

    this.replaceTechnologyIds();
    this.replceTypeIds();
    this.replaceCategoriesIds();
    this.replaceSubCategoriesIds();
  }

  private processTagsChangeLog() {
    this.addedTags = this.availableTags
    .filter((tag: any) => this.fullPCData.pchTags?.includes(tag.value) && !this.fullPCData.skillTags?.includes(tag.value))
    .map((tag: any) => tag.heading);

    this.removedTags = this.availableTags
    .filter((tag: any) => this.fullPCData.skillTags?.includes(tag.value) && !this.fullPCData.pchTags?.includes(tag.value))
    .map((tag: any) => tag.heading);
  }

  private replaceTechnologyIds() {
    this.changesList.forEach((change: any) => {
      if (change.label === 'Technology') {
        change.originalValue = masterSkillTechnologies[change.originalValue]
        change.pcValue = masterSkillTechnologies[change.pcValue]
      }
    })
  }

  private replceTypeIds() {
    this.changesList.forEach((change: any) => {
      if (change.label === 'Type') {
        change.originalValue = masterSkillTypes[change.originalValue]
        change.pcValue = masterSkillTypes[change.pcValue]
      }
    })
  }

  private replaceCategoriesIds() {
    this.changesList.forEach((change: any) => {
      if (change.label === 'Category') {
        change.originalValue = this.availableCategories.find((category: any) => category.value === change.originalValue)?.heading
        change.pcValue = this.availableCategories.find((category: any) => category.value === change.pcValue)?.heading
      }
    })
  }

  private replaceSubCategoriesIds() {
    this.changesList.forEach((change: any) => {
      if (change.label === 'Sub Category') {
        change.originalValue = this.availableSubCategories.find((subCategory: any) => subCategory.value === change.originalValue)?.heading
        change.pcValue = this.availableSubCategories.find((subCategory: any) => subCategory.value === change.pcValue)?.heading
      }
    })
  }

  private getRequestTypeName(typeId: number): void {
    //requestTypeId 1:Add, 2:Remove, 3:Consolidate, 4:Edit
    switch (typeId) {
      case 1:
        this.requestTypeName = 'Add';
        break;
      case 2:
        this.requestTypeName = 'Remove';
        break;
      case 3:
        this.requestTypeName = 'Consolidate';
        break;
      default:
        this.requestTypeName = 'Edit';
    }
  }

}

