@if(basePendingChangeDetails && dataReady){
<section>
	<app-audit-log-information [auditInformation]="basePendingChangeDetails" />
</section>
@if(basePendingChangeDetails.requestTypeId === 3 || basePendingChangeDetails.requestTypeId === 4 ){
<section class="section-space">
	<app-skill-changes-details-audit-log [fullPCData]="basePendingChangeDetails" [availableTags]="tags"
		[availableCategories]="categories" [availableSubCategories]="subcategories" />
</section>
}
	<section class="section-space">
		<app-skill-information [skillInformation]="basePendingChangeDetails" [availableCategories]="categories"
			[availableSubCategories]="subcategories" [availableTags]="tags" />
	</section>
}
