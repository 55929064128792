import { Component, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PopupService } from '../../../../shared/services/popup.service';
import { SkillService } from '../../../../shared/services/skill.service';
import { TextareaModule } from '@usitsdasdesign/dds-ng/textarea';
import { SkillDetailComponent } from '../../../../shared/components/skill-detail/skill-detail.component';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { Skill } from '../../../../shared/model/skill';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-change-status-modal',
  standalone: true,
  imports: [TextareaModule, ButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './change-status-modal.component.html',
  styleUrl: './change-status-modal.component.sass'
})
export class ChangeStatusModalComponent {
  constructor(
    private popups: PopupService,
    private _skillService: SkillService
  ) {}

  @Input() public skillData!:Skill;
  
  requestorMessage:string="";
  requestorMessageTouched:boolean=false
  
  closeModal() {
    this.popups.modals.close();
    this.popups.drawer(SkillDetailComponent, { data:{skill:this.skillData} })
  }

  get isError():boolean{
    return (this.requestorMessageTouched &&!this.requestorMessage.length)
  }

  onCommentChange(value:string):void{
    this.requestorMessageTouched=true;
    this.requestorMessage=value
  }
  
  async save(newStatus:boolean) {
    const spinner = this.popups.spinner();
    try {
      await firstValueFrom(this._skillService.ChangeSkillStatus(this.skillData.skillid, this.requestorMessage, newStatus));
      this.popups.modals.close();
    } finally {
      spinner.stop();
    };
  }
}
