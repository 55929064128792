import { InjectionToken, NgModule, APP_INITIALIZER, ModuleWithProviders, ɵisEnvironmentProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MsalService, MsalModule, MsalInterceptor, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration, MsalGuard } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { configService } from './shared/services/config.service';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AuthorizeInterceptor } from './shared/interceptors/token.interceptor';
import { HttpAuthInterceptor } from './shared/interceptors/http-auth.interceptor';
import { getSettings } from './shared/utils/settingsLoader';

export function MSALInstanceFactory(config: configService): IPublicClientApplication {
  const settings = getSettings();

  return new PublicClientApplication({
    auth: {
      clientId: settings.clientId,
      authority: settings.authority,
      redirectUri: settings.redirectUri
    },
    cache: {
      cacheLocation: settings.cacheLocation,
      storeAuthStateInCookie: false,
    },
    system: {
      loadFrameTimeout: 15000,
      allowNativeBroker: false
    }
  });
}

export function MSALInterceptorConfigFactory(config: configService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
 
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me/photo/$value', ['Contacts.Read, Contacts.ReadWrite']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule, MsalModule
  ]
})
export class MsalApplicationModule {

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: MsalApplicationModule,
      providers: [
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [configService]
        },
        MsalService,
        MsalGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [configService]
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthorizeInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpAuthInterceptor,
          multi: true
        },
      ]
    };
  }
}
