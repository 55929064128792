import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { TableComponent, TableLoadEvent } from "../../shared/components/table/table.component";
import { AuditLogService } from '../../shared/services/audit-log.service';
import { firstValueFrom, take, takeUntil } from 'rxjs';
import { Accessible, PaginatedData } from '../../shared/model/model';
import { SearchComponent } from '@usitsdasdesign/dds-ng/search';
import { MultiSelectComponent } from '@usitsdasdesign/dds-ng/multi-select';
import { FormsModule } from '@angular/forms';
import { AuditLogFilters } from './audit-log.filters';
import { LogItem } from '../../shared/model/log-item';
import { CommonModule } from '@angular/common';
import { DaterangepickerComponent } from '@usitsdasdesign/dds-ng/datepicker';
import { TableEmptyComponent } from '../../shared/components/table-empty/table-empty.component';
import { AuditLogDetailsComponent } from './widgets/audit-log-details/audit-log-details.component';
import { PopupService } from '../../shared/services/popup.service';

@Component({
	selector: 'app-audit-log',
	standalone: true,
	imports: [CommonModule, ButtonComponent, SearchComponent, TableComponent, MultiSelectComponent, FormsModule, DaterangepickerComponent, TableEmptyComponent],
	templateUrl: './audit-log.component.html',
	styleUrl: './audit-log.component.sass'
})
export class AuditLogComponent implements OnInit {
	@ViewChild(TableComponent) private table!: TableComponent;
	private auditLogService: AuditLogService = inject(AuditLogService);
	private popups: PopupService = inject(PopupService);
	private query: string = '';

	public logItems: (LogItem & Accessible)[] = [];
	public totalSize: number = 0;
	public isLoading: boolean = false;

	public columns: Column[] = [
    { name: 'skillName', header: 'Skill Name', dataType: 'string', sortable: true, width: '300px' },
    { name: 'type', header: 'Type', dataType: 'string', sortable: true, width: '100px' },
    { name: 'decision', header: 'Decision', dataType: 'string', sortable: true, width: '100px' },
    { name: 'requestor', header: 'Requestor', dataType: 'string', sortable: true, width: '250px' },
    { name: 'processor', header: 'Processor', dataType: 'string', sortable: true, width: '250px' },
    { name: 'requestDate', header: 'Request Date', dataType: 'date', sortable: true, width: '150px' },
    { name: 'taxonomyChangeDate', header: 'Taxonomy Change Date', dataType: 'date', sortable: true, width: '150px' }
	];

	public filters: AuditLogFilters = new AuditLogFilters();

	public async ngOnInit(): Promise<void> {
		try {
			this.filters = new AuditLogFilters(await firstValueFrom(this.auditLogService.getFilters()));
		} catch { }
	}

	public async getLogItems({ page, pageSize, sortingState }: TableLoadEvent) {
		this.isLoading = true;
		try {
			const { data, totalResults } = await new Promise<PaginatedData<LogItem>>(resolve =>
				this.auditLogService.getLogItems(page, pageSize, sortingState, this.query, this.filters.forParams())
					.pipe(take(1), takeUntil(this.table.load))
					.subscribe(resolve)
			);
			this.logItems = data.map(skill => new LogItem(skill));
			this.totalSize = totalResults;
		} finally {
			this.isLoading = false;
			this.table.scrollTop();
		}
	}

	public searchLogItems(search: string) {
		if (search.length > 0 && search.length < 3 || search === this.query)
			return;

		this.query = search;
		this.reload();
	}

	public clearFilters() {
		this.filters.clear();
		this.reload();
	}

	public reload() {
		this.table.reload({ page: 1 });
	}

	public viewSkillDetails(skillId: number) {
		this.popups.drawer(AuditLogDetailsComponent, { data: { logId: skillId } });
	}
}
