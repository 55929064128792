import { Component, Input } from '@angular/core';
import { SkillChangesDetailsComponent } from '../skill-changes-details/skill-changes-details.component';
import { forkJoin, take } from 'rxjs';
import { SkillService } from '../../../../shared/services/skill.service';
import { TagService } from '../../../../shared/services/tag.service';
import { AuditLogInformationComponent } from "../audit-log-information/audit-log-information.component";
import { SkillInformationComponent } from "../skill-information/skill-information.component";
import { AuditLogService } from '../../../../shared/services/audit-log.service';
import { FullAuditLogData } from '../../../../shared/model/full-audit-log-data';



@Component({
	selector: 'app-audit-log-details',
	standalone: true,
	imports: [SkillChangesDetailsComponent, AuditLogInformationComponent, SkillInformationComponent],
	templateUrl: './audit-log-details.component.html',
	styleUrl: './audit-log-details.component.sass'
})
export class AuditLogDetailsComponent {
  @Input() logId!: number;
	dataReady: boolean = false;
	categories: { id: number; name: string; }[] = [];
	subcategories: { id: number; name: string; parentCategoryId: number; }[] = [];
	filteredSubCategories: { id: number; name: string; parentCategoryId: number; }[] = [];
	tags: { id: number; tagName: string }[] = [];

	basePendingChangeDetails!: FullAuditLogData;
	formData: any; //TODO: Populate with original value or new value from basePEndingChangeDetails


	constructor(
    private auditLogService: AuditLogService,
		private skillService: SkillService,
		private tagService: TagService
	) { }


	ngOnInit() {
		this.getData();

	}

	getData() {
		const cats$ = this.skillService.getMasterSkillsCategories()
		const subCats$ = this.skillService.getMasterSkillsSubCategories()
		const tags$ = this.tagService.getMasterSkillsTags()
    const pendingChange$ = this.auditLogService.getLogDetails(this.logId)
		forkJoin([cats$, subCats$, tags$, pendingChange$]).pipe(take(1)).subscribe((results: any) => {
			this.categories = results[0];
			this.subcategories = results[1];
			this.tags = results[2];
			this.basePendingChangeDetails = results[3];
			if (this.basePendingChangeDetails?.pchCategoryId) {
				this.filterSubCategories(this.basePendingChangeDetails.pchCategoryId);
			}
			this.dataReady = true;
		})
	}

	filterSubCategories(parentId: number) {
		this.filteredSubCategories = this.subcategories.filter((subCategory: any) => {
			return subCategory.parentCategoryId === parentId;
		});

	}

}
