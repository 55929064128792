import { Accessible } from "../../shared/model/model";

enum filterKeyMap { categories = 'FilterCategory', subCategories = 'FilterSubCategory', dmtStates = 'FilterDMTStatus', skillTypes = 'FilterSkillType', technologies = 'FilterTechnology', catalogs = 'FilterCatalog', tags='FilterTags', startDate= 'StartDate',endDate= 'EndDate' }

export type SkillFiltersType<T> = Accessible & { categories: T[]; dmtStates: T[]; skillTypes: T[]; subCategories: T[]; technologies: T[]; catalogs: T[],tags:T[],startDate:T[],endDate:T[] }
export type SkillFiltersValueType<T> = Accessible & { categories: T; dmtStates: T[]; skillTypes: T[]; subCategories: T[]; technologies: T[]; catalogs: T[],tags:T[],startDate:T[],endDate:T[] }
export type SkillFiltersParams = Accessible & { FilterCategory?: string[]; FilterSubCategory?: string[]; FilterDMTStatus?: string[]; FilterSkillType?: string[]; FilterTechnology?: string[]; FilterCatalog?: string[]; FilterTags?:string[], StartDate?:string[],EndDate?:string[]  }

export class SkillTaxonomyFilters {
	private store: SkillFiltersType<{ id: number; description: string, parentId?: number }>;

	public lists: SkillFiltersType<string>;

	public filters: Partial<SkillFiltersValueType<string>> = {};

	public get isFiltered(): boolean {
		return Object.keys(this.filters).map(key => this.filters[key]).flat().length > 0;
	}

	public get computedLists(): { subCategories: string[] } {
		return {
			subCategories: this.filters.categories?.[0] ? this.toList(this.store.subCategories.filter(sc => sc.parentId == this.forParams().FilterCategory?.[0])) : this.lists.subCategories
		};
	}

	public clear(name?: keyof SkillFiltersType<string>): void {
		if (name == 'categories') {
			delete this.filters.categories;
			this.clear('subCategories');
			return;
		}
		if (name) {
			this.filters[name] = [];
			return;
		}
		delete this.filters.categories;
    delete this.filters.catalogs;
    delete this.filters.dmtStates;
		Object.keys(this.filters).forEach(key => this.filters[key] = []);
	}

	public forParams(): SkillFiltersParams {
		if (this.filters.subCategories?.length && !this.filters.categories?.length) {
			const parentId = this.store.subCategories.find(sc => sc.description == this.filters.subCategories?.[0])?.parentId;
			this.filters.categories = this.store.categories.filter(c => c.id == parentId).map(c => c.description)?.[0];
		}
		const params: SkillFiltersParams = {};

		Object.keys(this.filters).forEach(key => {
			const name = filterKeyMap[key as keyof typeof filterKeyMap];

			params[name] = this.store[key]
			.filter(({ description }: { description: string }) => this.filters[key].includes(description))
			.map(({ id }: { id: number }) => `${id ?? 'NULL'}`);

			// make unique
			params[name] = params[name]?.filter((id: string, index: number) => params[name]?.indexOf(id) === index);
      //Date Filter Logic
      if(this.filters.startDate && this.store.endDate ){
        params['StartDate'] = this.filters.startDate;
        params['EndDate'] = this.filters.endDate;
      }
		});

		return params;
	}

	constructor(data: SkillFiltersType<{ id: number; description: string }> = { categories: [], dmtStates: [], skillTypes: [], subCategories: [], technologies: [],catalogs:[],tags:[],startDate:[],endDate:[] }) {
    this.store = {
			categories: data.categories ?? [],
			dmtStates: data.dmtStates ?? [],
			skillTypes: data.skillTypes ?? [],
			subCategories: data.subCategories ?? [],
			technologies: data.technologies ?? [],
			catalogs: data.catalogs ?? [],
			tags: data.tags ?? [],
      startDate: data.startDate ?? [],
      endDate: data.endDate ?? [],
		};
		this.lists = {
			categories: this.toList(this.store.categories),
			dmtStates: this.toList(this.store.dmtStates),
			skillTypes: this.toList(this.store.skillTypes),
			subCategories: this.toList(this.store.subCategories),
			technologies: this.toList(this.store.technologies),
			catalogs: this.toList(this.store.catalogs),
			tags: this.toList(this.store.tags),
      startDate: this.toList(this.store.startDate),
      endDate: this.toList(this.store.endDate)
		};
	}

	private toList(items: { id: number, description: string }[]): string[] {
		return items
		.filter(item => item.description.length > 0 && item.description !== 'NULL')
		.map(item => item.description)
		.sort((a, b) => a.localeCompare(b));
	}
}
