import { inject, Injectable } from '@angular/core';
import { Skill } from '../model/skill';
import { map, Observable, of } from 'rxjs';
import { SortOptions } from '@usitsdasdesign/dds-ng/shared';
import { HttpClient } from '@angular/common/http';
import { SkillFiltersParams, SkillFiltersType } from '../../views/market-library/skill-filters';
import { MasterSkillPayloadForEdition, newMasterSkillPayload } from '../model/masterSkill';
import { PaginatedData } from '../model/model';
import { getSettings } from '../utils/settingsLoader';

@Injectable({
  providedIn: 'root',
})
export class SkillService {
	private http: HttpClient = inject(HttpClient);
	settings = getSettings();

	public getSkills(page: number, pageSize: number, sort: SortOptions, query?: string, filters: SkillFiltersParams = {}): Observable<PaginatedData<Skill>> {
		const params = new URLSearchParams();
		params.append('PageNumber', (page - 1).toString());
		params.append('PageSize', pageSize.toString());

		if (sort.property?.length) {
			params.append('OrderBy', sort.property);
			params.append('IsAscending', sort.descending ? 'false' : 'true');
		}

		if (query?.length) {
			params.append('Search', query);
		}

		Object.keys(filters).forEach(key => {
			if (filters[key].length)
				params.append(key, filters[key]);
		});

		return this.http.get<PaginatedData<Skill>>(`${this.settings.apiUrl}/Skill?${params.toString()}`);
  }

	public getVersion(): Observable<{ versionNumber: string }> {
		return this.http.get<{ versionNumber: string }>(`${this.settings.apiUrl}/Skill/Version`);
	}

	public getFilters(): Observable<SkillFiltersType<{ id: number; description: string }>> {
		return this.http.get<SkillFiltersType<{ id: number; description: string }>>(`${this.settings.apiUrl}/Skill/Filters`);
	}
	
	public getSkillDetails(skillId: number): Observable<Skill> {
		return this.http.get<Skill>(`${this.settings.apiUrl}/Skill/${skillId}`)
	}

	public ChangeSkillStatus(skillId: number, comments:string, newStatus: boolean): Observable<any> {
		let body:any= 
		{
			skillId: skillId,
			requestorInitiatiorId: localStorage.getItem('userId'),
			requestorStatus: newStatus,
			requestorMessage: comments
		  }
					
		return this.http.put(`${this.settings.apiUrl}/Skill/changeDMTSkillStatus`, body);
		
	}
	
	public changeDSTSkillStatus(skillId: number, comments:string, newStatus: boolean): Observable<any> {
		const requestorInitiatiorId = localStorage.getItem('userId');
		return this.http.put(`${this.settings.apiUrl}/Skill/changeDSTSkillStatus`, { skillId, requestorInitiatiorId, requestorStatus: newStatus, requestorMessage: comments });
	}

	getMasterSkillsCategories(){
		let url = `${this.settings.apiUrl}/category`
		return this.http.get<{id:number, name:string}[]>(url).pipe(
		  map((data:any)=>{
			return data.map((cat:any)=>({
			  value:cat.id,
			  heading:cat.name
			}))
		  }
		))
	  }

	  getMasterSkillsSubCategories(){
			let url = `${this.settings.apiUrl}/SubCategory`
		return this.http.get<{id:number, name:string, parentCategoryId:number}[]>(url).pipe(
		  map((data:any)=>{
			return data.map((subCat:any)=>({
			  value:subCat.id,
			  heading:subCat.name,
			  parentCategoryId:subCat.parentCategoryId
			}))
		  }
		))
	  }

	  addNewMasterSkill(skillData:newMasterSkillPayload	){
			let url = `${this.settings.apiUrl}/Skill/addSkill`
		return this.http.put(url, skillData)
	  }

	  getMasterSkillById(skillId:number){
			let url = `${this.settings.apiUrl}/Skill/Edit/${skillId}`
		return this.http.get(url)
	  }

	  editMasterSkill(skillData:MasterSkillPayloadForEdition){
			let url = `${this.settings.apiUrl}/Skill/Edit`
		return this.http.put(url, skillData)
	  }

	validateSkillNameAvailability(skillName: string) {
		return this.http.get(`${this.settings.apiUrl}/Skill/NameValidation?skillName=${skillName}`)
	}
	
}
