import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import * as i1 from '@angular/platform-browser';
class SafeStylePipe {
  constructor(sanitized) {
    this.sanitized = sanitized;
  }
  transform(value) {
    return this.sanitized.bypassSecurityTrustStyle(value);
  }
  static {
    this.ɵfac = function SafeStylePipe_Factory(t) {
      return new (t || SafeStylePipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "safeStyle",
      type: SafeStylePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafeStylePipe, [{
    type: Pipe,
    args: [{
      name: 'safeStyle',
      standalone: true
    }]
  }], () => [{
    type: i1.DomSanitizer
  }], null);
})();
class SafeStyleModule {
  static {
    this.ɵfac = function SafeStyleModule_Factory(t) {
      return new (t || SafeStyleModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SafeStyleModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafeStyleModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [SafeStylePipe],
      exports: [SafeStylePipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SafeStyleModule, SafeStylePipe };
