<div class="skill-detail-drawer">
    <h2>{{ skill.skillname }}</h2>
    <div class="skill-information">
        <h3>Skill Information</h3>
        <div class="info-grid-three">
            <div class="info-item">
                <p><strong>Skill Name</strong><br>{{ skill.skillname }}</p>
            </div>
            <div class="info-item">
                <p><strong>Skill ID</strong><br>{{ skill.skillid }}</p>
            </div>
            <div class="info-item">
                <p><strong>Lightcast ID</strong><br>{{ skill.lightCastId }}</p>
            </div>
        </div>
        <!-- Skill Description with Show More/Less functionality-->

        <div class="skill-description" [class.expanded]="showFullDescription">
            <p><strong>Skill Description</strong><br>
                @if (skill.skillDescription.length <= descriptionLimit) {
                        <p>{{ skill.skillDescription }}</p>
                } @else {
                    {{ showFullDescription ? skill.skillDescription : (skill.skillDescription | slice: 0: descriptionLimit) + '...' }}
                    <div class="toggle-description">
                        <button (click)="toggleDescription()" [ariaLabel]="showFullDescription ? 'Show Less' : 'Show More'" >
                        {{ showFullDescription ? 'Show Less' : 'Show More' }}
                        </button>
                    </div>
                }
            </p>
        </div>

        <div class="info-grid">
            <div>
                <p><strong>Category</strong><br> {{ skill.category || 'No category' }}</p>
            </div>
            <div>
                <p><strong>Subcategory</strong><br> {{ skill.subcategory || 'No subcategory' }}</p>
            </div>
            <div>
                <p><strong>Status</strong><br>
                    <span [ngClass]="{
                        'status-active': skill.skillstatuslabel === 'Active',
                        'status-inactive': skill.skillstatuslabel !== 'Active'
                        }">
                        {{ skill.skillstatuslabel === 'Active' ? 'Active' : skill.skillstatuslabel === 'Retired' ? 'Retired' : 'Unassigned' }}
                    </span>
                </p>
            </div>
                <div>
                <p><strong>Skill Type</strong><br> {{ skill.skilltype || 'No skill type' }}</p>
                </div>
        </div>

        <!-- Tags Section-->
        <div class="tags-section">
            <h4>TAGs</h4>
            @if (skill.tags && skill.tags.length > 0) {
                <ul>
                    @for (tag of skill.tags; track tag) {
                        <li>{{ tag }}</li>
                    }
                </ul>
                } @else {
                    <p>No tags available</p>
            }
        </div>
        <!-- Reactivate Skill Button-->
            @if (role == 1 && (skill.skillstatus!= true)) {
                <div class="reactivate-button-container">
                    <dds-button [theme]="'green'" [icon]="'dds-icon_send__l__stroke'"[isIconLeft]="false" [ariaLabel]="'Request Reactivate Skill'"class="reactivate-button" (click)="changeStatus(true)">
                        Reactivate Skill
                    </dds-button>
                </div>
            }


    </div>
</div>
