export class LogItem {
	id: number;
	decision: string;
	processor: string;
	requestor: string;
	skillName: string;
	type: string;
	requestDate: Date;
	taxonomyChangeDate: Date;

	constructor(data: Partial<LogItem>) {
		this.id = data.id ?? 0;
		this.decision = data.decision ?? '';
		this.processor = data.processor ?? '';
		this.requestor = data.requestor ?? '';
		this.skillName = data.skillName ?? '';
		this.type = data.type ?? '';
		this.requestDate = data.requestDate ?? new Date();
		this.taxonomyChangeDate = data.taxonomyChangeDate ?? new Date();
	}
}
