import { Component } from '@angular/core';

@Component({
  selector: 'app-general-settings',
  standalone: true,
  imports: [],
  templateUrl: './general-settings.component.html',
  styleUrl: './general-settings.component.sass'
})
export class GeneralSettingsComponent {

}
