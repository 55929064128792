import { Component, Input } from '@angular/core';
import { SkillChangesDetailsComponent } from '../skill-changes-details/skill-changes-details.component';
import { PendingChangesFormComponent } from '../pending-changes-form/pending-changes-form.component';
import { PendingChangesService } from '../../pending-changes.service';
import { SkillService } from '../../../../shared/services/skill.service';
import { TagService } from '../../../../shared/services/tag.service';
import { forkJoin, take } from 'rxjs';
import { AccordionModule } from '@usitsdasdesign/dds-ng/accordion';

export interface FullPendingChangesData {
  skillId: number;
  pchId: number;
  skillLightCastId: string;
  skillName: string;
  pchName: string;
  skillCategoryId: number;
  pchCategoryId: number;
  skillSubCategoryId: number;
  pchSubCategoryId: number;
  skillTypeId: number;
  pchTypeId: number;
  requestTypeId: number;
  skillTechnologyId: number;
  pchTechnologyId: number;
  skillDescription: string;
  pchDescription: string;
  skillLink: string;
  pchLink: string;
  skillTags?: number[];
  pchTags?: number[];
  skillRequestor: string;
  skillRequestDescription: string;
  pchRequestor: string;
  basePendingChangeDetails: number;
  dsTstatus: string;
  sourceCatalog: string

}

@Component({
  selector: 'app-pending-change-details',
  standalone: true,
  imports: [SkillChangesDetailsComponent, PendingChangesFormComponent, AccordionModule],
  templateUrl: './pending-change-details.component.html',
  styleUrl: './pending-change-details.component.sass'
})
export class PendingChangeDetailsComponent {
  @Input() pchSkillId!: number;
  dataReady: boolean = false;
  categories: any = [];
  subcategories: any = [];
  filteredSubCategories: any = [];
  tags: any = [];

  basePendingChangeDetails: FullPendingChangesData | undefined;
  formData: any; //TODO: Populate with original value or new value from basePEndingChangeDetails


  constructor(
    private pendignChangesService: PendingChangesService,
    private skillService: SkillService,
    private tagService: TagService
  ) { }


  ngOnInit() {
    this.getData();

  }

  getData() {
    const cats$ = this.skillService.getMasterSkillsCategories()
    const subCats$ = this.skillService.getMasterSkillsSubCategories()
    const tags$ = this.tagService.getMasterSkillsTags()
    const pendingChange$ = this.pendignChangesService.getPendingChangeDetails(this.pchSkillId)
    forkJoin([cats$, subCats$, tags$, pendingChange$]).pipe(take(1)).subscribe((results: any) => {
      this.categories = results[0];
      this.subcategories = results[1];
      this.tags = results[2];
      this.basePendingChangeDetails = results[3];
      if (this.basePendingChangeDetails?.pchCategoryId) {
        this.filterSubCategories(this.basePendingChangeDetails.pchCategoryId);
      }
      this.dataReady = true;
    })
  }

  filterSubCategories(parentId: number) {
    this.filteredSubCategories = this.subcategories.filter((subCategory: any) => {
      return subCategory.parentCategoryId === parentId;
    });

  }



}