<div class="dds-row">
	<div class="dds-col">
		<div class="section-title">
			User Management
			<div class="section-title-right">
				<dds-button theme="dark" role="button" ariaLabel="Add New User" (clicked)="newUser()">
					Add New User
				</dds-button>
			</div>
		</div>

		<div class="search-bar">
			<dds-search #search placeholder="Search in User Management by First Name, Last Name, Email or Privilege" [ariaLabel]="search.placeholder"
				[isTrimValue]="true" size="lg" (valueChanged)="searchUsers($event)"></dds-search>
		</div>

		<app-table #table [columns]="columns" [isLoading]="isLoading" [totalSize]="totalSize" (load)="getUsers($event)">
			@if (users.length === 0) {
				<app-table-empty [colspan]="columns.length" />
			}
			@for (user of users; track user.id) {
				<tr class="dds-data-table__row">
					@for (column of columns; track column.name) {
						@if (column.name === 'action') {
							<td class="dds-data-table__cell">
								<dds-button theme="dark" icon="dds-icon_edit" role="button" ariaLabel="Modify Privileges" (clicked)="modifyUser(user)">
									Modify Privileges
								</dds-button>
							</td>
						} @else if (column.dataType === 'date') {
							<td class="dds-data-table__cell">{{ user[column.name] | date:'shortDate' }}</td>
						} @else {
							<td class="dds-data-table__cell">{{ user[column.name] }}</td>
						}
					}
				</tr>
			}
		</app-table>
	</div>
</div>
