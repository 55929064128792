import { Component, Input, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormControl, FormGroup, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { InputComponent } from '@usitsdasdesign/dds-ng/input';
import { TextareaModule } from '@usitsdasdesign/dds-ng/textarea';
import { SelectModule } from '@usitsdasdesign/dds-ng/select';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';
import { MultiSelectModule } from '@usitsdasdesign/dds-ng/multi-select';
import { FullPendingChangesData } from '../pending-change-details/pending-change-details.component';
import { MasterSkillTag } from '../../../../shared/model/masterSkill';
import { TagsModule } from '@usitsdasdesign/dds-ng/tags';
import { PendingChangesService } from '../../pending-changes.service';
import { catchError, firstValueFrom, map, of, take } from 'rxjs';
import { PopupService } from '../../../../shared/services/popup.service';
import { TagInputComponent } from '../../../../shared/components/tag-input/tag-input.component';
import { FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import { TagService } from '../../../../shared/services/tag.service';
import { SkillService } from '../../../../shared/services/skill.service';

@Component({
  selector: 'app-pending-changes-form',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, InputComponent, TextareaModule, SelectModule, ButtonModule, MultiSelectModule, CommonModule, TagsModule, TagInputComponent, FocusHandlerModule],
  providers: [DatePipe],
  templateUrl: './pending-changes-form.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],    
  styleUrl: './pending-changes-form.component.sass'
})
export class PendingChangesFormComponent implements OnInit {
  @Input() fullPCData!: FullPendingChangesData;
  @Input() categories!: any[];
  @Input() subCategories!: any[];
  @Input() availableTags!: any[];
  buttonsLabels = {
    approve: 'Aprove Changes',
    decline: 'Decline Changes'
  }

  public skillDataIsReady: boolean = false;
  public pendingChangeForm!: FormGroup;
  public isApprover: boolean = false;
  formPatched: boolean = false;
  formOriginalRawValue: string = '';
  filteredSubCategories: any[] = []
  skillTypesList = [{
    heading: 'Specialized Skill',
    value: 1
  },
  {
    heading: 'Common Skill',
    value: 2
  },
  {
    heading: 'Certification',
    value: 3
  }];
  technologiesList = [{
    heading: 'Language',
    value: 1
  },
  {
    heading: 'Software',
    value: 2
  }];
  selectedTags: MasterSkillTag[] = [];
  isRemovedType = 2;
  checkingSkillName: string = ''
  skillNameValdationError: string = ''

  constructor(
    private pendingChangesService: PendingChangesService,
    private popUpService: PopupService,
    private tagService: TagService,
    private skillService: SkillService
  ) {

  }

  ngOnInit(): void {
    this.filteredSubCategories = this.subCategories.filter((subCat: any) => subCat.parentCategoryId == this.fullPCData.pchCategoryId)
    this.createForm();
  }


  private createForm() {
    this.pendingChangeForm = new FormGroup({
      pchName: new FormControl(this.fullPCData.pchName, { asyncValidators: [this.skillNameValidator()], updateOn: 'blur' }),
      pchDescription: new FormControl(this.fullPCData.pchDescription, [Validators.required]),
      pchLink: new FormControl(this.fullPCData.pchLink, [Validators.pattern('https?://.+')]),
      pchCategoryId: new FormControl(this.fullPCData.pchCategoryId, [Validators.required]),
      pchSubCategoryId: new FormControl(this.fullPCData.pchSubCategoryId, [Validators.required]),
      pchTypeId: new FormControl(this.fullPCData.pchTypeId, [Validators.required]),
      pchTechnologyId: new FormControl(this.fullPCData.pchTechnologyId),
      pchTags: new FormControl(this.fullPCData.pchTags || []),
    });
    this.formOriginalRawValue = JSON.stringify(this.pendingChangeForm.getRawValue()); // Save the original value of the form in an string to compare later
    this.pendingChangeForm.updateValueAndValidity();

    this.setButtonsLabels();
    this.isApprover = localStorage.getItem('role') === '1';
    if (this.fullPCData.requestTypeId === this.isRemovedType || !this.isApprover) {
      this.pendingChangeForm.disable();
    }
    this.formPatched = true;
  }
  
  onCategoryChanges(event: any) {
    if (this.subCategories.length > 0) {
      let subCatField = this.pendingChangeForm.get('pchSubCategoryId')
      this.filteredSubCategories = this.subCategories.filter((subCat: any) => subCat.parentCategoryId == event)
      subCatField?.setValue('')
      subCatField?.clearValidators();
      subCatField?.enable();
      subCatField?.setValidators([Validators.required]);
      subCatField?.updateValueAndValidity();
    }
  }

  private compareTags(): boolean {
    let rawSelectedTags = JSON.stringify(this.pendingChangeForm.get('pchTags')?.value.sort() || []);
    let rawOriginalTags = JSON.stringify(this.fullPCData.pchTags?.sort() || []);
    let tagsHasChanges = rawSelectedTags !== rawOriginalTags;
    return tagsHasChanges;
  }

  private formOrTagsHasChanges(): boolean {
    const currentFormRawValue = JSON.stringify(this.pendingChangeForm.getRawValue());
    let formHasChanges = this.formOriginalRawValue !== currentFormRawValue;
    let tagsHasChanges = this.compareTags()
    return (formHasChanges || tagsHasChanges);
  }

  private getSkillSourceId() {
    switch (this.fullPCData.sourceCatalog) {
      case 'LightCast': return 1
        break;
      case 'Deloitte Specific': return 2
      default: return null
    }
  }

  private keyHasChanges(originalKeyName: string, pchKeyName: string): boolean {
    return ((this.fullPCData as any)[originalKeyName] !== this.pendingChangeForm.get(pchKeyName)?.value);
  }

  async onSaveAndApprove() {
    let objKeyMap: any = {
      pchName: 'skillName',
      pchDescription: 'skillDescription',
      pchLink: 'skillLink',
      pchCategoryId: 'skillCategoryId',
      pchSubCategoryId: 'skillSubCategoryId',
      pchTypeId: 'skillTypeId',
      pchTechnologyId: 'skillTechnologyId'
    }

    let payload: any = {
      hasChanges: false,
      requestTypeId: this.fullPCData.requestTypeId,
      pchId: this.fullPCData.pchId,

      skillDescriptionSource: this.fullPCData.skillRequestDescription,
      skillSourceId: this.getSkillSourceId(),
      skillRequestorId: Number(localStorage.getItem('userId')),
      skillNameForToastMessage: this.fullPCData.pchName,
    }

    if (this.fullPCData.skillId) {
      payload.skillId = this.fullPCData.skillId
    }

    if (this.fullPCData.skillLightCastId) {
      payload.skillLightCastId = this.fullPCData.skillLightCastId;
    }


    if (this.formOrTagsHasChanges()) {
      payload.hasChanges = true;
      Object.keys(this.pendingChangeForm.value).forEach((key) => {
        if (key !== 'pchTags' && this.keyHasChanges(objKeyMap[key], key)) payload[objKeyMap[key]] = this.pendingChangeForm.value[key];
      });

      //TAGS verification, if tags changed, add the tags to the payload
      if (this.compareTags()) {
        payload['skillTags'] = this.pendingChangeForm.get('pchTags')?.value || [];
      }
    }
    const skillName = this.pendingChangeForm.get('pchName')?.value;
    const skillID = this.fullPCData.skillId
    const message = skillID ?
      `Are you sure you want to <strong>accept the changes</strong> to the skill: "<strong><u>${skillName}</u></strong>"?` :
      `Are you sure you want to <strong>approve</strong> the skill: "<strong><u>${skillName}</u></strong>"?`;
    let confirmButtonLabel = ''
    switch (this.fullPCData.requestTypeId) {
      case 1: confirmButtonLabel = 'Add Skill';
        break;
      case 2: confirmButtonLabel = 'Remove Skill';
        break;
      case 3: confirmButtonLabel = 'Approve Consolidation';
        break;
      case 4: confirmButtonLabel = 'Approve Changes';
        break;
      default: 'Approve'
    }
    const buttons = {
      cancel: { icon: '', text: 'Dismiss' },
      accept: { icon: 'dds-icon_send__l__stroke', text: confirmButtonLabel },
    }

    const comment = await firstValueFrom(
      this.popUpService.confirmComment({ title: skillID ? 'Approve Skill Edit' : 'Approve New Skill', message, buttons }).onClosed
    );

    if (typeof comment !== 'string') return;

    const spinner = this.popUpService.spinner();
    try {
      payload['userComments'] = comment;
      await firstValueFrom(this.pendingChangesService.editApprovePendingChange(payload));
    } finally {
      spinner.stop();
      this.pendingChangesService.onApproveOrDeclineEnded.emit(true);
    }
  }

  private setButtonsLabels() {
    //requestTypeId 1:Add, 2:Remove, 3:Consolidate, 4:Edit
    let declineLabel: string = '';
    let approveLabel: string = '';
    switch (this.fullPCData.requestTypeId) {
      case 1: declineLabel = 'Decline New Skill'; approveLabel = 'Approve New Skill';
        break;
      case 2: declineLabel = 'Keep Skill'; approveLabel = 'Remove Skill';
        break;
      case 3: declineLabel = 'Decline Consolidation'; approveLabel = 'Approve Consolidation';
        break;
      case 4: declineLabel = 'Decline Changes'; approveLabel = 'Accept Changes';
        break;
      default: declineLabel = 'Decline'; approveLabel = 'Approve'
    }
    this.buttonsLabels.approve = approveLabel;
    this.buttonsLabels.decline = declineLabel;
  }

  async onDecline() {
    this.setButtonsLabels()
    let message = '';
    let popUpTitle = '';
    let popUpAcceptBtnLabel = '';
    // 1:Add 2:Remove 4:Edit
    switch (this.fullPCData.requestTypeId) {
      case 1:
        message = `Are you sure you want to <strong>decline</strong> the skill: "<strong><u>${this.fullPCData.skillName}</u></strong>"?`;
        popUpTitle = 'Decline New Skill';
        popUpAcceptBtnLabel = 'Decline Request';
        break;
      case 2:
        message = `Are you sure you want to <strong>keep</strong>  "<strong><u>${this.fullPCData.skillName}</u></strong>" in Deloitte Skill Taxonomy?`;
        popUpTitle = 'Keep Skill';
        popUpAcceptBtnLabel = 'Keep Skill';
        break;
      case 4:
        message = `Are you sure you want to <strong>decline the changes to the skill:</strong> "<strong><u>${this.fullPCData.skillName}</u></strong>"?`;
        popUpTitle = 'Decline Skill Changes';
        popUpAcceptBtnLabel = 'Decline Changes';
        break;
      default:
        message = 'Are you sure you want to decline the changes to the skill?';
        popUpTitle = 'Decline';
    }
    if (this.fullPCData.requestTypeId === 1) {
      message = `Are you sure you want to <strong>decline</strong> the new skill: "<strong><u>${this.fullPCData.pchName}</u></strong>"?`;
    }
    const buttons = {
      cancel: { icon: '', text: 'Dismiss' },
      accept: { icon: 'dds-icon_send__l__stroke', text: popUpAcceptBtnLabel },
    }
    const comment = await firstValueFrom(
      this.popUpService.confirmComment({ title: popUpTitle, message, buttons }).onClosed
    );
    if (typeof comment !== 'string') return;

    const spinner = this.popUpService.spinner();
    try {
      let skillName = this.fullPCData.skillName || this.pendingChangeForm.get('pchName')?.value;
      await firstValueFrom(this.pendingChangesService.bulkDecline([this.fullPCData.pchId], comment, this.fullPCData.requestTypeId, this.fullPCData.skillLightCastId, skillName));
    } finally {
      spinner.stop();
      this.pendingChangesService.onApproveOrDeclineEnded.emit(true);
    }
  }

  onNewTag(tagName: string) {
    const spinner = this.popUpService.spinner();
    this.tagService.addNew(tagName).subscribe({
      next: (res: any) => {
        this.availableTags.push({ value: res, heading: tagName });
        const value = this.pendingChangeForm.get('pchTags')?.value ?? [];
        this.pendingChangeForm.get('pchTags')?.patchValue([...value, res]);
      },
      error: (e: any) => console.error(e),
      complete: () => spinner.stop()
    })
  }

  private skillNameValidator(): AsyncValidatorFn {
    this.checkingSkillName = '';
    return (control: AbstractControl) => {
      if (!control.value && control.touched) {
        this.skillNameValdationError = 'Is required';
        this.checkingSkillName = '';
        return of({ isRequired: true });
      } else {
        this.skillNameValdationError = '';
        this.checkingSkillName = 'Checking name availability...';
        return this.skillService.validateSkillNameAvailability(control.value).pipe(
          take(1),
          map((res: any) => {
            this.checkingSkillName = ''
            if (res && (res.skillNameAvailable == false && (control.value != this.fullPCData?.pchName))) {
              this.skillNameValdationError = 'A skill with this name already exists. Please choose a different name.';
              return { skillNameTaken: true };
            } else {
              this.checkingSkillName = ''
              return null;
            }
          }),
          catchError(() => {
            this.checkingSkillName = ''
            return of(null)
          })
        );
      }
    }
  }
}
