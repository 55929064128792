import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, HostBinding, Input, ViewChild, Output, NgModule } from '@angular/core';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import { DestroyMixin, I18nMixin } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import * as i4 from '@usitsdasdesign/dds-ng/core/focus-handler';
import { FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import * as i5 from '@usitsdasdesign/dds-ng/core/theme';
import { ThemeMapEnum, ThemeModule } from '@usitsdasdesign/dds-ng/core/theme';
import { Size, Themes, AdditionalClass } from '@usitsdasdesign/dds-ng/shared';
import * as i1 from '@usitsdasdesign/dds-ng/shared/i18n';
import { I18nModule } from '@usitsdasdesign/dds-ng/shared/i18n';
import * as i2 from '@usitsdasdesign/dds-ng/shared/dds-config';
const _c0 = ["closeBtn"];
const _c1 = ["tag"];
const _c2 = ["tagText"];
const _c3 = [[["dds-user-pic"]], [["", "user-pic", ""]], "*"];
const _c4 = ["dds-user-pic", "[user-pic]", "*"];
const _c5 = (a0, a1, a2, a3, a4) => ({
  "dds-tag_removable": a0,
  "dds-tag_error": a1,
  "dds-tag_disabled": a2,
  "dds-tag_with-icon": a3,
  "dds-tag_inverse": a4
});
function TagComponent_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7, 2);
    i0.ɵɵlistener("click", function TagComponent_Conditional_8_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.removedItem($event));
    })("keydown", function TagComponent_Conditional_8_Template_button_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleKeydown($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const tag_r3 = i0.ɵɵreference(1);
    i0.ɵɵclassMapInterpolate1("dds-tag__icon dds-icon ", ctx_r1.removeBtnIcon, "");
    i0.ɵɵproperty("disabled", ctx_r1.disabled)("ddsFocus", tag_r3);
    i0.ɵɵattribute("aria-label", ctx_r1.deleteBtnText);
  }
}
const _c6 = [[["dds-tag"]]];
const _c7 = ["dds-tag"];
const themeClassesMap = {
  [ThemeMapEnum.Size]: {
    [Size.sm]: 'dds-tag_sm',
    [Size.lg]: 'dds-tag_lg'
  },
  [ThemeMapEnum.Themes]: {
    [Themes.green]: 'dds-tag_green',
    [Themes.blue]: 'dds-tag_blue'
  }
};
const TAG_WRAP_CLASS = 'dds-tag-wrap';
const DEFAULT_REMOVE_BTN_ICON = 'dds-icon_close';
const TagBase = DestroyMixin(I18nMixin());
class TagComponent extends TagBase {
  constructor(cdr, i18n, renderer, ddsConfig) {
    super();
    this.cdr = cdr;
    this.i18n = i18n;
    this.renderer = renderer;
    this.ddsConfig = ddsConfig;
    this.isWrap = true;
    this.theme = this.ddsConfig.getConfig().theme;
    this.size = this.ddsConfig.getConfig().size;
    this.removeBtnIcon = DEFAULT_REMOVE_BTN_ICON;
    this.removed = new EventEmitter();
    this.keyPressed = new EventEmitter();
    this.themeConfig = themeClassesMap;
  }
  ngAfterViewInit() {
    super.ngOnInit();
  }
  handleKeydown(event) {
    this.keyPressed.emit(event);
  }
  focus() {
    if (this.closeBtn && this.tag) {
      this.renderer.addClass(this.tag.nativeElement, AdditionalClass.keyboardFocus);
      this.closeBtn.nativeElement.focus();
    }
  }
  blur() {
    if (this.closeBtn && this.tag) {
      this.renderer.removeClass(this.tag.nativeElement, AdditionalClass.keyboardFocus);
      this.closeBtn.nativeElement.blur();
    }
  }
  removedItem(event) {
    this.removed.emit(event);
  }
  setI18nTexts(translation) {
    const tagText = this.tagText?.nativeElement?.innerText || '';
    this.deleteBtnText = `${translation.TAG.A11Y.CLOSE_BTN_ARIA_LABEL} ${tagText}`;
    this.cdr.detectChanges();
  }
  static {
    this.ɵfac = function TagComponent_Factory(t) {
      return new (t || TagComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.I18nService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i2.DdsConfigService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TagComponent,
      selectors: [["dds-tag"]],
      viewQuery: function TagComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
          i0.ɵɵviewQuery(_c1, 5);
          i0.ɵɵviewQuery(_c2, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.closeBtn = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tag = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tagText = _t.first);
        }
      },
      hostVars: 4,
      hostBindings: function TagComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("dds-tag-wrap", ctx.isWrap)("dds-disabled", ctx.disabled);
        }
      },
      inputs: {
        disabled: "disabled",
        theme: "theme",
        isInverse: "isInverse",
        isRemovable: "isRemovable",
        size: "size",
        removeBtnIcon: "removeBtnIcon",
        customClass: "customClass",
        isError: "isError"
      },
      outputs: {
        removed: "removed",
        keyPressed: "keyPressed"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c4,
      decls: 9,
      vars: 16,
      consts: [["tag", ""], ["tagText", ""], ["closeBtn", ""], ["data-qa", "tag", "role", "status", 3, "ngClass", "size", "theme", "ddsTheme"], [1, "dds-tag__content"], [1, "dds-tag__text"], ["data-qa", "button", "role", "button", 3, "disabled", "ddsFocus", "class"], ["data-qa", "button", "role", "button", 3, "click", "keydown", "disabled", "ddsFocus"]],
      template: function TagComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c3);
          i0.ɵɵelementStart(0, "div", 3, 0);
          i0.ɵɵprojection(2);
          i0.ɵɵprojection(3, 1);
          i0.ɵɵelementStart(4, "div", 4)(5, "span", 5, 1);
          i0.ɵɵprojection(7, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(8, TagComponent_Conditional_8_Template, 2, 6, "button", 6);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-tag ", ctx.customClass, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction5(10, _c5, ctx.isRemovable, ctx.isError, ctx.disabled, ctx.isRemovable, ctx.isInverse))("size", ctx.size)("theme", ctx.theme)("ddsTheme", ctx.themeConfig);
          i0.ɵɵattribute("aria-disabled", ctx.disabled)("aria-invalid", ctx.isError);
          i0.ɵɵadvance(8);
          i0.ɵɵconditional(ctx.isRemovable ? 8 : -1);
        }
      },
      dependencies: [CommonModule, i3.NgClass, FocusHandlerModule, i4.FocusHandlerDirective, ThemeModule, i5.ThemeDirective, I18nModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagComponent, [{
    type: Component,
    args: [{
      selector: 'dds-tag',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [CommonModule, FocusHandlerModule, ThemeModule, I18nModule],
      template: "<div [ngClass]=\"{\r\n       'dds-tag_removable': isRemovable,\r\n       'dds-tag_error': isError,\r\n       'dds-tag_disabled': disabled,\r\n       'dds-tag_with-icon': isRemovable,\r\n       'dds-tag_inverse': isInverse\r\n     }\"\r\n     [size]=\"size\"\r\n     [theme]=\"theme\"\r\n     [ddsTheme]=\"themeConfig\"\r\n     [attr.aria-disabled]=\"disabled\"\r\n     [attr.aria-invalid]=\"isError\"\r\n     data-qa=\"tag\"\r\n     role=\"status\"\r\n     class=\"dds-tag {{customClass}}\"\r\n     #tag>\r\n  <ng-content select=\"dds-user-pic\"></ng-content>\r\n  <ng-content select=\"[user-pic]\"></ng-content>\r\n  <div class=\"dds-tag__content\">\r\n    <span class=\"dds-tag__text\"\r\n          #tagText>\r\n      <ng-content></ng-content>\r\n    </span>\r\n    @if (isRemovable) {\r\n      <button [disabled]=\"disabled\"\r\n              [ddsFocus]=\"tag\"\r\n              [attr.aria-label]=\"deleteBtnText\"\r\n              data-qa=\"button\"\r\n              role=\"button\"\r\n              class=\"dds-tag__icon dds-icon {{removeBtnIcon}}\"\r\n              (click)=\"removedItem($event)\"\r\n              (keydown)=\"handleKeydown($event)\"\r\n              #closeBtn></button>\r\n    }\r\n  </div>\r\n</div>"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i1.I18nService
  }, {
    type: i0.Renderer2
  }, {
    type: i2.DdsConfigService
  }], {
    isWrap: [{
      type: HostBinding,
      args: [`class.${TAG_WRAP_CLASS}`]
    }],
    disabled: [{
      type: HostBinding,
      args: [`class.${AdditionalClass.disabled}`]
    }, {
      type: Input
    }],
    closeBtn: [{
      type: ViewChild,
      args: ['closeBtn']
    }],
    tag: [{
      type: ViewChild,
      args: ['tag']
    }],
    tagText: [{
      type: ViewChild,
      args: ['tagText']
    }],
    theme: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    isRemovable: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    removeBtnIcon: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    isError: [{
      type: Input
    }],
    removed: [{
      type: Output
    }],
    keyPressed: [{
      type: Output
    }]
  });
})();
class TagsComponent {
  static {
    this.ɵfac = function TagsComponent_Factory(t) {
      return new (t || TagsComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TagsComponent,
      selectors: [["dds-tags"]],
      inputs: {
        customClass: "customClass"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c7,
      decls: 2,
      vars: 3,
      consts: [["data-qa", "tags", "role", "group"]],
      template: function TagsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c6);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("dds-tags ", ctx.customClass, "");
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagsComponent, [{
    type: Component,
    args: [{
      selector: 'dds-tags',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      template: "<div class=\"dds-tags {{customClass}}\"\r\n     data-qa=\"tags\"\r\n     role=\"group\">\r\n  <ng-content select=\"dds-tag\"></ng-content>\r\n</div>"
    }]
  }], null, {
    customClass: [{
      type: Input
    }]
  });
})();
class TagsModule {
  static {
    this.ɵfac = function TagsModule_Factory(t) {
      return new (t || TagsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TagsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [TagComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagsModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [TagsComponent, TagComponent],
      exports: [TagsComponent, TagComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TagComponent, TagsComponent, TagsModule };
