export class PendingChanges {
	id: number;
	skillId: number;
	category: string;
	subCategory: string;
	skillName: string;
	type: string;
	request: string;
	requestDate: Date;
	dstStatus: boolean | null;
	dstStatusLabel: string;
	catalog: string;
	tagsIds: string[];
	tagsDescription: string[];

	public get requestShort(): string {
		return this.request.length > 50 ? this.request.slice(0, 50) + '...' : this.request;
	}

	public get firstTagDescription(): string {
		return this.tagsDescription[0] ?? '';
	}

	constructor(data: Partial<PendingChanges>) {
		this.id = data.id ?? 0;
		this.skillId = data.skillId ?? 0;
		this.category = data.category ?? '';
		this.subCategory = data.subCategory ?? '';
		this.skillName = data.skillName ?? '';
		this.type = data.type ?? '';
		this.request = data.request ?? '';
		this.requestDate = data.requestDate ? new Date(data.requestDate) : new Date();
		this.dstStatus = data.dstStatus ?? null;
		this.dstStatusLabel = data.dstStatusLabel ?? '';
		this.catalog = data.catalog ?? '';
		this.tagsIds = data.tagsIds ? (data.tagsIds as unknown as string).split(',') : [];
		this.tagsDescription = data.tagsDescription ? (data.tagsDescription as unknown as string).split(',') : [];
	}
}
