<div class="dds-row">
    <div class="dds-col">
        <div class="dds-row section-title">
            <div class="dds-col ">
                Deloitte Skills Taxonomy
            </div>
            <div class="dds-col section-title-right">
              <dds-dropdown theme="dark" kind="primaryLoud" label="Export" ariaLabel="Click to export to XLSX or CSV" width="fixed"
              icon="dds-icon_download__l__stroke" [disabled]="!this.isExportEnabled" [stickerWidth]="150" (itemSelected)="export($event)">
                  <dds-dropdown-item value="csv">Export to CSV</dds-dropdown-item>
                  <dds-dropdown-item value="xls">Export to XLXS</dds-dropdown-item>
          </dds-dropdown>
                @if(addSkillButtonVisible){
                    <dds-button theme="green" class="add-button" icon="dds-icon_plus__l__stroke" role="button" ariaLabel="Add New Skill"
                        (click)="openFormInDrawer()">
                        Add New Skill
                    </dds-button>
                    }
            </div>
        </div>
        <div class="search-bar">
          <dds-search #search placeholder="Search in Deloitte Skills Taxonomy by Skill Name, Category, Subcategory..." [ariaLabel]="search.placeholder"
            [isTrimValue]="true" size="lg" (valueChanged)="searchSkillsTax($event)"></dds-search>
        </div>
        <div class="filters">
          <dds-select label="Category" ariaLabel="Category" type="counter" theme="dark" stickerWidth="auto" stickerMaxHeight="400" [list]="filters.lists.categories" [(ngModel)]="filters.filters.categories" (valueChanged)="filters.clear('subCategories'); reload()" />
          <dds-multi-select label="Subcategory" ariaLabel="Subcategory" type="counter" theme="dark" stickerWidth="auto" stickerMaxHeight="400" [list]="filters.computedLists.subCategories" [(ngModel)]="filters.filters.subCategories" (valueChanged)="reload()" />
          <dds-multi-select label="Skill Type" ariaLabel="Skill Type" type="counter"  theme="dark" stickerWidth="auto" stickerMaxHeight="400" [list]="filters.lists.skillTypes" [(ngModel)]="filters.filters.skillTypes" (valueChanged)="reload()" />
          <dds-daterangepicker label="Start & End Date" ariaLabel="Start & End Date" theme="dark" [isManualInput]="false" stickerWidth="auto" stickerMaxHeight="400" [isStartDateEmitted]="false" [formControl]="daterangepickerFormCtrl" (onHidden)="dateChanged()" />
          <dds-select label="Catalog" ariaLabel="Catalog" type="counter" theme="dark" stickerWidth="auto" stickerMaxHeight="400"
            [list]="filters.lists.catalogs" [(ngModel)]="filters.filters.catalogs" (valueChanged)="reload()" />
        <dds-select label="DST Status" ariaLabel="DST Status" type="counter" theme="dark" stickerWidth="auto"
            stickerMaxHeight="400"
            [list]="filters.lists.dmtStates" [(ngModel)]="filters.filters.dmtStates" (valueChanged)="reload()" />
          <dds-multi-select label="Tags" ariaLabel="Tags" type="counter" theme="dark" stickerWidth="auto" stickerMaxHeight="400" [list]="filters.lists.tags" [(ngModel)]="filters.filters.tags" (valueChanged)="reload()" />

          <dds-button [disabled]="!filters.isFiltered" theme="dark" icon="dds-icon_close" role="button" ariaLabel="Clear" (clicked)="clearFilters()">Clear</dds-button>
        </div>
        <app-table #table [columns]="columns" [isLoading]="isLoading" [totalSize]="totalSize" (load)="loadSkills($event)">
            @if (skills.length === 0) {
                <app-table-empty [colspan]="columns.length" />
            }
            @for (skill of skills; track skill.skillid) {
                <tr class="dds-data-table__row" (click)="openFormInDrawer(skill.skillid)">
                    @for (column of columns; track column.name){
                        @if (column.name === 'skilldescription') {
                            <td class="dds-data-table__cell skill-description">
                                {{ skill[column.name] }}
                            </td>
                        }  @else if (column.dataType === "date"){
                            <td class="dds-data-table__cell">{{ skill[column.name] | date: 'shortDate'}}</td>
                        } @else if (column.name === 'skillstatus') {
                            <td class="dds-data-table__cell">
								<span>
									<span class="badge" [class.black]="skill.skillstatus === false" [class.wire]="skill.skillstatus === null">
										{{ skill.skillstatus === null ? 'Unassigned' : skill.skillstatus ? 'Active' : 'Retired' }}
									</span>
								</span>
							</td>
						}@else{
                            @if(column.name === 'skillname'){
                                <td class="dds-data-table__cell">
                                    {{ skill[column.name] }}
                                </td>
                            }@else{
                            <td class="dds-data-table__cell">{{ skill[column.name] }}</td>
                            }
                        }
                    }
                </tr>
            }
        </app-table>
    </div>
</div>
