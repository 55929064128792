import { Accessible } from "../../shared/model/model";

enum filterKeyMap { categories = 'FilterCategory', subCategories = 'FilterSubCategory', dmtStates = 'FilterDSTStatus', typesPendingChange = 'FilterModificationType', technologies = 'FilterTechnology', catalogs = 'FilterCatalog', tags = 'FilterTags' }

export type PendingChangesType<T> = Accessible & { categories: T[]; dmtStates: T[]; typesPendingChange: T[]; subCategories: T[]; technologies: T[]; catalogs: T[], tags: T[] }
export type PendingChangesValueType<T> = Accessible & { categories: T; dmtStates: T[]; typesPendingChange: T[]; subCategories: T[]; technologies: T[]; catalogs: T[], tags: T[] }
export type PendingChangesParams = Accessible & { FilterCategory?: string[]; FilterSubCategory?: string[]; FilterDSTStatus?: string[]; FilterModificationType?: string[]; FilterTechnology?: string[]; FilterCatalog?: string[]; FilterTags?: string[] }

export class PendingChangesFilters {
	private store: PendingChangesType<{ id: number; description: string, parentId?: number }>;

	public lists: PendingChangesType<string>;

	public filters: Partial<PendingChangesValueType<string>> = {};

	public get isFiltered(): boolean {
		return Object.keys(this.filters).map(key => this.filters[key]).flat().length > 0;
	}

	public get computedLists(): { subCategories: string[] } {
		return {
			subCategories: this.filters.categories?.[0] ? this.toList(this.store.subCategories.filter(sc => sc.parentId == this.forParams().FilterCategory?.[0])) : this.lists.subCategories
		};
	}

	public clear(name?: keyof PendingChangesType<string>): void {
		if (name == 'categories') {
			delete this.filters.categories;
			this.clear('subCategories');
			return;
		}
		if (name) {
			this.filters[name] = [];
			return;
		}
		delete this.filters.categories;
		Object.keys(this.filters).forEach(key => this.filters[key] = []);
	}

	public forParams(): PendingChangesParams {
		if (this.filters.subCategories?.length && !this.filters.categories?.length) {
			const parentId = this.store.subCategories.find(sc => sc.description == this.filters.subCategories?.[0])?.parentId;
			this.filters.categories = this.store.categories.filter(c => c.id == parentId).map(c => c.description)?.[0];
		}
		const params: PendingChangesParams = {};
		
		Object.keys(this.filters).forEach(key => {
			const name = filterKeyMap[key as keyof typeof filterKeyMap];

			params[name] = this.store[key]
			.filter(({ description }: {description: string}) => this.filters[key].includes(description))
			.map(({ id }: {id: number, description: string}) => `${id ?? 'NULL'}`);

			// make unique
			params[name] = params[name]?.filter((id: string, index: number) => params[name]?.indexOf(id) === index);
		});

		return params;
	}

	constructor(data: PendingChangesType<{ id: number; description: string, parentId: number }> = { categories: [], dmtStates: [], typesPendingChange: [], subCategories: [], technologies: [], catalogs: [], tags: [] }) {
		this.store = {
			categories: data.categories ?? [],
			dmtStates: data.dmtStates ?? [],
			typesPendingChange: data.typesPendingChange ?? [],
			subCategories: data.subCategories ?? [],
			technologies: data.technologies ?? [],
			catalogs: data.catalogs ?? [],
			tags: data.tags ?? [],
		};
		this.lists = {
			categories: this.toList(this.store.categories),
			dmtStates: this.toList(this.store.dmtStates),
			typesPendingChange: this.toList(this.store.typesPendingChange),
			subCategories: this.toList(this.store.subCategories),
			technologies: this.toList(this.store.technologies),
			catalogs: this.toList(this.store.catalogs),
			tags: this.toList(this.store.tags),
		};
	}

	private toList(items: { id: number, description: string }[]): string[] {
		return items
		.filter(item => item.description.length > 0 && item.description !== 'NULL')
		.map(item => item.description)
		.sort((a, b) => a.localeCompare(b));
	}
}
