
@if(skillData){
<div class="user-role-container">
  <div class="skill-info">
    <p>
      Are you sure you want to <strong>request the reactivation</strong> of the skill:
      <strong>{{skillData.skillname}}</strong>?
    </p>
  </div>

  <dds-textarea
  label="Comment"
  placeholder="Add a comment here..."
  ariaLabel="Add a comment here"
  [minHeight]="100"
  [isRequired]="true"
  [isError]="isError"
  errorMessage="Is required"
  (valueChanged)="onCommentChange($event)">
  </dds-textarea>


</div>
<div class="modal-footer">
  <dds-button
  theme="green"
  kind="secondary"
  [isIconLeft]="false"
  ariaLabel="Dismiss"
  customClass="cancel-button"
  (clicked)="closeModal()">Dismiss</dds-button>


    <dds-button
    theme="green"
    kind="primary"
    icon="dds-icon_send__l__stroke"
    [isIconLeft]="false"
    ariaLabel="Click to reactivate skill"
    customClass="save-button"
    [disabled]="!requestorMessage.length"
    (clicked)="save(true)">Reactivate Skill</dds-button>


</div>
}@else{
  <p>No data</p>
}
