import { inject, Injectable } from '@angular/core';
import { LogItem } from '../model/log-item';
import { Observable, of } from 'rxjs';
import { SortOptions } from '@usitsdasdesign/dds-ng/shared';
import { HttpClient } from '@angular/common/http';
import { AuditLogFiltersParams, AuditLogFiltersType } from '../../views/audit-log/audit-log.filters';
import { PaginatedData } from '../model/model';
import { getSettings } from '../utils/settingsLoader';
import { FullAuditLogData } from '../model/full-audit-log-data';

@Injectable({
	providedIn: 'root',
})
export class AuditLogService {
	private http: HttpClient = inject(HttpClient);
	settings = getSettings();

	public getLogItems(page: number, pageSize: number, sort: SortOptions, query?: string, filters: AuditLogFiltersParams = {}): Observable<PaginatedData<LogItem>> {
		const params = new URLSearchParams();
		params.append('PageNumber', (page - 1).toString());
		params.append('PageSize', pageSize.toString());

		if (sort.property?.length) {
			params.append('OrderBy', sort.property);
			params.append('IsAscending', sort.descending ? 'false' : 'true');
		}

		if (query?.length) {
			params.append('Search', query);
		}

		Object.keys(filters).forEach(key => {
			if (filters[key].length)
				params.append(key, filters[key]);
		});

		return this.http.get<PaginatedData<LogItem>>(`${this.settings.apiUrl}/AuditLog?${params.toString()}`);
	}

	public getFilters(): Observable<AuditLogFiltersType<{ id: number; description: string }>> {
		return this.http.get<AuditLogFiltersType<{ id: number; description: string }>>(`${this.settings.apiUrl}/Skill/Filters`);
	}
  public getLogDetails(id: number): Observable<FullAuditLogData> {
    return this.http.get<FullAuditLogData>(`${this.settings.apiUrl}/PendingChanges/GetAuditLogbyId/${id}`);
	}
}
