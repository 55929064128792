import { ExportService } from '../../shared/services/export.service';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { TableComponent, TableLoadEvent } from "../../shared/components/table/table.component";
import { Skill } from '../../shared/model/skill';
import { SkillService } from '../../shared/services/skill.service';
import { firstValueFrom, take, takeUntil } from 'rxjs';
import { Accessible, DataExportParams, PaginatedData } from '../../shared/model/model';
import { SearchComponent } from '@usitsdasdesign/dds-ng/search';
import { SkillDetailComponent } from '../../shared/components/skill-detail/skill-detail.component';
import { MultiSelectComponent } from '@usitsdasdesign/dds-ng/multi-select';
import { FormsModule } from '@angular/forms';
import { SkillFilters } from './skill-filters';
import { PopupService } from '../../shared/services/popup.service';
import { DropdownModule } from '@usitsdasdesign/dds-ng/dropdown';
import { ToastService } from '@usitsdasdesign/dds-ng/toast';
import { TableEmptyComponent } from '../../shared/components/table-empty/table-empty.component';
import { SelectComponent } from '@usitsdasdesign/dds-ng/select';


@Component({
	selector: 'app-market-library',
	standalone: true,
	imports: [ButtonComponent, SearchComponent, TableComponent, MultiSelectComponent, SelectComponent, FormsModule, DropdownModule, TableEmptyComponent],
	templateUrl: './market-library.component.html',
	styleUrl: './market-library.component.sass'
})
export class MarketLibraryComponent implements OnInit {
	@ViewChild(TableComponent) private table!: TableComponent;
	private skillService: SkillService = inject(SkillService);
	private popupService: PopupService = inject(PopupService);
  private _exportService: ExportService = inject(ExportService);
  private _toastService: ToastService = inject(ToastService);
	private query: string = '';

	public version?: string;
	public skills: (Skill & Accessible)[] = [];
	public totalSize: number = 0;
	public isLoading: boolean = false;
	public isExportEnabled: boolean = false;

	public columns: Column[] = [
    { name: 'category', header: 'Category', dataType: 'string', sortable: true, width: '150px' },
    { name: 'subcategory', header: 'Subcategory', dataType: 'string', sortable: true, width: '500px' },
    { name: 'skillname', header: 'Skill Name', dataType: 'string', sortable: true, width: '150px' },
    { name: 'skilltype', header: 'Skill Type', dataType: 'string', sortable: true, width: '150px' },
    { name: 'skillstatus', header: 'Status', dataType: 'boolean', sortable: true, width: '100px' }
	];

	public filters: SkillFilters = new SkillFilters();

	public async ngOnInit(): Promise<void> {
		try {
			this.version = (await firstValueFrom(this.skillService.getVersion())).versionNumber;
			this.filters = new SkillFilters(await firstValueFrom(this.skillService.getFilters()));

			const role = localStorage.getItem('role');
			this.isExportEnabled = role == '1' || role == '2';
		} catch {}
	}

  public async getSkills({ page, pageSize, sortingState }: TableLoadEvent) {
		this.isLoading = true;
		try {
			const { data, totalResults } = await new Promise<PaginatedData<Skill>>(resolve =>
				this.skillService.getSkills(page, pageSize, sortingState, this.query, this.filters.forParams())
				.pipe(take(1), takeUntil(this.table.load))
				.subscribe(resolve)
			);
			this.skills = data.map(skill => new Skill(skill));
			this.totalSize = totalResults;
		} finally {
			this.isLoading = false;
			this.table.scrollTop();
		}
	}

	public searchSkills(search: string) {
		if (search.length > 0 && search.length < 3 || search === this.query)
			return;

		this.query = search;
		this.reload();
	}

	public clearFilters() {
		this.filters.clear();
		this.reload();
	}

	public reload() {
		this.table.reload({ page: 1 });
	}

	public async viewSkillDetails(skillId: number) {
		const skill = await firstValueFrom(this.skillService.getSkillDetails(skillId));
		this.popupService.drawer(SkillDetailComponent, { data: { skill } });
	}

  public async marketLibraryExport(fileType: string) { //xls, csv
		const params: DataExportParams = {
      fileType: fileType,
      OrderBy: this.table.sortingState.property || '',
      IsAscending: !this.table.sortingState.descending,
      Search: this.query,
			filters: this.filters.forParams()
    }
    try {
      await firstValueFrom(this._exportService.exportMarketLibrary(params));
      this._toastService.createToast({ title: 'Data has been exported', position: 'top-center', isLoading: false, lifeTime: 2500, isCloseIcon: true });
    } catch {
      this._toastService.createToast({ title: 'Download Failed.', message: 'Please try again.', position: 'top-center', isLoading: false, lifeTime: 2500, isCloseIcon: true, isError: true });
    }
  }
}
