import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, HostBinding, Input, Injectable, EventEmitter, Directive, Inject, Output, NgModule } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PopupService, checkPosition, addPosition, BeakClass } from '@usitsdasdesign/dds-ng/core/popup';
import { PositionState, Size, Themes, PopupClass, KeyCodes } from '@usitsdasdesign/dds-ng/shared';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import * as i1 from '@usitsdasdesign/dds-ng/shared/dds-config';
import * as i2 from '@usitsdasdesign/dds-ng/core/focus-handler';
const _c0 = ["*"];
var TooltipType;
(function (TooltipType) {
  TooltipType["regular"] = "regular";
  TooltipType["error"] = "error";
})(TooltipType || (TooltipType = {}));
var TooltipClass;
(function (TooltipClass) {
  TooltipClass["tooltip"] = "dds-tooltip";
  TooltipClass["tooltipLarge"] = "dds-tooltip_lg";
  TooltipClass["error"] = "dds-tooltip_error";
  TooltipClass["isInverse"] = "dds-tooltip_inverse";
  TooltipClass["blueTheme"] = "dds-tooltip_blue";
  TooltipClass["greenTheme"] = "dds-tooltip_green";
})(TooltipClass || (TooltipClass = {}));
var InvokeType;
(function (InvokeType) {
  InvokeType["click"] = "click";
  InvokeType["hover"] = "hover";
})(InvokeType || (InvokeType = {}));
class TooltipComponent {
  constructor(el, renderer, ddsConfig) {
    this.el = el;
    this.renderer = renderer;
    this.ddsConfig = ddsConfig;
    this.position = PositionState.top;
    this.type = TooltipType.regular;
    this.size = this.ddsConfig.getConfig().size;
    this.theme = this.ddsConfig.getConfig().theme;
  }
  ngOnInit() {
    if (this.type === TooltipType.error) {
      this.renderer.addClass(this.el.nativeElement, TooltipClass.error);
    }
    if (this.size === Size.lg) {
      this.renderer.addClass(this.el.nativeElement, TooltipClass.tooltipLarge);
    }
    if (this.isInverse) {
      this.renderer.addClass(this.el.nativeElement, TooltipClass.isInverse);
    }
    if (this.customClass) {
      this.renderer.addClass(this.el.nativeElement, this.customClass);
    }
    if (this.theme === Themes.blue) {
      this.renderer.addClass(this.el.nativeElement, TooltipClass.blueTheme);
    }
    if (this.theme === Themes.green) {
      this.renderer.addClass(this.el.nativeElement, TooltipClass.greenTheme);
    }
    this.renderer.addClass(this.el.nativeElement, TooltipClass.tooltip);
    this.renderer.addClass(this.el.nativeElement, PopupClass.popup);
    this.renderer.addClass(this.el.nativeElement, PopupClass.popup_active);
  }
  static {
    this.ɵfac = function TooltipComponent_Factory(t) {
      return new (t || TooltipComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.DdsConfigService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TooltipComponent,
      selectors: [["dds-tooltip"]],
      hostVars: 4,
      hostBindings: function TooltipComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("width", ctx.width)("max-width", ctx.maxWidth);
        }
      },
      inputs: {
        width: "width",
        maxWidth: "maxWidth",
        position: "position",
        type: "type",
        size: "size",
        customClass: "customClass",
        isInverse: "isInverse",
        theme: "theme"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TooltipComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipComponent, [{
    type: Component,
    args: [{
      selector: 'dds-tooltip',
      template: ` <ng-content></ng-content> `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i1.DdsConfigService
  }], {
    width: [{
      type: HostBinding,
      args: ['style.width']
    }, {
      type: Input
    }],
    maxWidth: [{
      type: HostBinding,
      args: ['style.max-width']
    }, {
      type: Input
    }],
    position: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    isInverse: [{
      type: Input
    }],
    theme: [{
      type: Input
    }]
  });
})();
const INDENT = 15;
const SHIFT = 0;
const HIDE_DELAY = 100;
const BACKDROP_DEFAULT_STYLES = 'position:fixed;top:0;bottom:0;left:0;right:0;';
const ATTR_ARIA_DESCRIBEDBY = 'aria-describedby';
const ATTR_ID = 'id';
const ATTR_ROLE = 'role';
const ATTR_LIVE = 'aria-live';
const ATTR_INVALID = 'aria-invalid';
const ATTR_LIVE_POLITE = 'polite';
const ROLE_TOOLTIP = 'tooltip';

// @dynamic
class TooltipService extends PopupService {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTooltipService_BaseFactory;
      return function TooltipService_Factory(t) {
        return (ɵTooltipService_BaseFactory || (ɵTooltipService_BaseFactory = i0.ɵɵgetInheritedFactory(TooltipService)))(t || TooltipService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TooltipService,
      factory: TooltipService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipService, [{
    type: Injectable
  }], null, null);
})();
class TooltipDirective {
  constructor(document, renderer, el, ngZone, tooltipService, focusHandlerService) {
    this.document = document;
    this.renderer = renderer;
    this.el = el;
    this.ngZone = ngZone;
    this.tooltipService = tooltipService;
    this.focusHandlerService = focusHandlerService;
    this.tooltipIsDynamic = true;
    this.tooltipShift = SHIFT;
    this.tooltipIndent = INDENT;
    this.tooltipInvokeType = InvokeType.hover;
    this.tooltipPosition = PositionState.top;
    this.tooltipType = TooltipType.regular;
    this.tooltipSize = Size.md;
    this.tooltipHasBeak = true;
    this.onShown = new EventEmitter();
    this.onHidden = new EventEmitter();
    this.destroy = new Subject();
    this.checkCoords = (setFinishCoords = false) => {
      const popupLocationConfig = {
        elRect: this.el.nativeElement.getBoundingClientRect(),
        beakRect: this.popupEl && this.beakEl ? this.beakEl.getBoundingClientRect() : null,
        popupRect: this.popupEl.getBoundingClientRect(),
        indent: this.tooltipIndent,
        shift: this.tooltipShift,
        isDynamic: this.tooltipIsDynamic
      };
      const currentCoords = checkPosition(this.tooltipPosition, popupLocationConfig);
      let setMaxWidth = setFinishCoords;
      if (this.tooltipMaxWidth) setMaxWidth = false;
      addPosition(this.popupEl, currentCoords.popupCoords, setMaxWidth);
      addPosition(this.beakEl, currentCoords.beakCoords);
      this.checkBeak(currentCoords.beakPosition);
      if (setFinishCoords) {
        this.renderer.addClass(this.popupEl, PopupClass.popup_position);
      }
    };
  }
  ngOnChanges(changes) {
    if (changes.ddsTooltip && typeof changes.ddsTooltip.currentValue === 'string' && this.popupEl) {
      this.popupEl.innerText = changes.ddsTooltip.currentValue;
    }
  }
  ngAfterViewInit() {
    this.bindListeners();
    this.tooltipService.closedPopup.pipe(takeUntil(this.destroy)).subscribe(item => {
      if (item === this.popupEl) {
        this.onHidden.emit();
      }
    });
    this.keyboardInteraction();
  }
  ngOnDestroy() {
    this.hide();
    if (this.elClick) {
      this.elClick();
    }
    if (this.mouseenter) {
      this.mouseenter();
    }
    if (this.mouseleave) {
      this.mouseleave();
    }
    this.destroy.next();
    this.destroy.complete();
  }
  show() {
    if (!this.tooltipIsDisabled) {
      this.hide();
      // Cancel the delayed hide if it is scheduled
      if (this.hideTimeoutVar != null) {
        clearTimeout(this.hideTimeoutVar);
      }
      this.showTimeoutVar = setTimeout(() => {
        const stickerWidth = this.tooltipWidth ? `${this.tooltipWidth}px` : 'auto';
        const stickerMaxWidth = this.tooltipMaxWidth ? `${this.tooltipMaxWidth}px` : 'none';
        if (typeof this.ddsTooltip === 'string') {
          this.popupEl = this.tooltipService.open(TooltipComponent, undefined, {
            width: stickerWidth,
            maxWidth: stickerMaxWidth,
            position: this.tooltipPosition,
            type: this.tooltipType,
            size: this.tooltipSize,
            theme: this.tooltipTheme,
            isInverse: this.tooltipIsInverse,
            customClass: this.tooltipCustomClass
          }, this.tooltipInvokeType);
          this.popupEl.innerText = this.ddsTooltip;
        } else {
          this.popupEl = this.tooltipService.open(TooltipComponent, this.ddsTooltip, {
            width: stickerWidth,
            maxWidth: stickerMaxWidth,
            position: this.tooltipPosition,
            type: this.tooltipType,
            size: this.tooltipSize,
            theme: this.tooltipTheme,
            isInverse: this.tooltipIsInverse,
            customClass: this.tooltipCustomClass
          }, this.tooltipInvokeType);
        }
        if (this.tooltipHasBeak) {
          this.createBeak();
        }
        if (!this.tooltipIsOutsideClick) {
          this.outsideCLick = this.renderer.listen(this.popupEl, 'click', e => this.hide(e));
        }
        if (this.tooltipIsDynamic) {
          this.resizeAndScrollListeners();
        }
        this.checkCoords();
        this.setAria();
        this.onShown.emit();
        this.showTimeoutVar = undefined;
      }, this.tooltipShowDelay);
    }
  }
  hide(event) {
    // Cancel the delayed show if it is scheduled
    if (this.showTimeoutVar != null) {
      clearTimeout(this.showTimeoutVar);
    }
    this.hideTimeoutVar = setTimeout(() => {
      this.tooltipService.close(event);
      if (this.outsideCLick) {
        this.outsideCLick();
      }
      if (this.resize && this.scroll) {
        this.resize.unsubscribe();
        this.scroll.unsubscribe();
      }
      this.el.nativeElement.removeAttribute(ATTR_ARIA_DESCRIBEDBY);
      this.hideTimeoutVar = undefined;
    }, this.tooltipHideDelay);
  }
  updPosition() {
    this.checkCoords();
  }
  keyboardInteraction() {
    this.ngZone.runOutsideAngular(() => {
      this.focusHandlerService.keyup$.pipe(takeUntil(this.destroy)).subscribe(e => {
        if (e.keyCode === KeyCodes.TAB && this.tooltipInvokeType === InvokeType.hover) this.keyboardFocus(e);
      });
      this.focusHandlerService.keydown$.pipe(takeUntil(this.destroy)).subscribe(e => {
        if (e.keyCode === KeyCodes.TAB) this.keyboardUnFocus(e);
      });
    });
  }
  keyboardFocus(event) {
    if (this.tooltipKeyboardFocusEl === event.target || this.el.nativeElement === event.target) {
      this.show();
    }
  }
  keyboardUnFocus(event) {
    if (this.tooltipKeyboardFocusEl === event.target || this.el.nativeElement === event.target) {
      this.hide();
    }
  }
  setAria() {
    const elemID = `${ROLE_TOOLTIP}${Math.ceil(Math.random() * 1000)}`;
    this.el.nativeElement.setAttribute(ATTR_ARIA_DESCRIBEDBY, elemID);
    this.popupEl.setAttribute(ATTR_ID, elemID);
    this.popupEl.setAttribute(ATTR_ROLE, ROLE_TOOLTIP);
    this.popupEl.setAttribute(ATTR_LIVE, ATTR_LIVE_POLITE);
    this.popupEl.setAttribute(ATTR_INVALID, `${this.tooltipType === TooltipType.error}`);
    if (this.tooltipKeyboardFocusEl) {
      this.tooltipKeyboardFocusEl.setAttribute(ATTR_ARIA_DESCRIBEDBY, elemID);
    }
    setTimeout(() => this.checkCoords(true));
  }
  bindListeners() {
    if (this.tooltipInvokeType === InvokeType.hover) {
      this.hoverListener();
    } else {
      this.clickListener();
    }
  }
  createBeak() {
    this.beakEl = this.renderer.createElement('span');
    this.renderer.addClass(this.beakEl, BeakClass.beak);
    this.checkBeak(this.tooltipPosition);
    this.renderer.appendChild(this.popupEl, this.beakEl);
  }
  checkBeak(position) {
    if (this.beakEl) {
      this.renderer.removeClass(this.beakEl, BeakClass.top);
      this.renderer.removeClass(this.beakEl, BeakClass.bottom);
      this.renderer.removeClass(this.beakEl, BeakClass.left);
      this.renderer.removeClass(this.beakEl, BeakClass.right);
      switch (position) {
        case PositionState.top:
        case PositionState.topLeft:
        case PositionState.topRight:
          this.renderer.addClass(this.beakEl, BeakClass.top);
          break;
        case PositionState.bottom:
        case PositionState.bottomLeft:
        case PositionState.bottomRight:
          this.renderer.addClass(this.beakEl, BeakClass.bottom);
          break;
        case PositionState.left:
        case PositionState.leftBottom:
        case PositionState.leftTop:
          this.renderer.addClass(this.beakEl, BeakClass.left);
          break;
        case PositionState.right:
        case PositionState.rightBottom:
        case PositionState.rightTop:
          this.renderer.addClass(this.beakEl, BeakClass.right);
          break;
      }
    }
  }
  hoverListener() {
    this.mouseenter = this.renderer.listen(this.el.nativeElement, 'mouseenter', () => this.show());
    this.mouseleave = this.renderer.listen(this.el.nativeElement, 'mouseleave', () => this.hide());
  }
  clickListener() {
    this.elClick = this.renderer.listen(this.el.nativeElement, 'click', () => this.show());
  }
  resizeAndScrollListeners() {
    this.ngZone.runOutsideAngular(() => {
      this.resize = fromEvent(window, 'resize').pipe(throttleTime(30)).subscribe(_ => this.checkCoords(true));
      this.scroll = fromEvent(this.document, 'scroll').pipe(throttleTime(30)).subscribe(_ => this.checkCoords(true));
    });
  }
  static {
    this.ɵfac = function TooltipDirective_Factory(t) {
      return new (t || TooltipDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(TooltipService), i0.ɵɵdirectiveInject(i2.FocusHandlerService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TooltipDirective,
      selectors: [["", "ddsTooltip", ""]],
      inputs: {
        ddsTooltip: "ddsTooltip",
        tooltipKeyboardFocusEl: "tooltipKeyboardFocusEl",
        tooltipWidth: "tooltipWidth",
        tooltipMaxWidth: "tooltipMaxWidth",
        tooltipIsDisabled: "tooltipIsDisabled",
        tooltipIsInverse: "tooltipIsInverse",
        tooltipIsOutsideClick: "tooltipIsOutsideClick",
        tooltipIsDynamic: "tooltipIsDynamic",
        tooltipShift: "tooltipShift",
        tooltipIndent: "tooltipIndent",
        tooltipShowDelay: "tooltipShowDelay",
        tooltipHideDelay: "tooltipHideDelay",
        tooltipInvokeType: "tooltipInvokeType",
        tooltipPosition: "tooltipPosition",
        tooltipType: "tooltipType",
        tooltipTheme: "tooltipTheme",
        tooltipSize: "tooltipSize",
        tooltipHasBeak: "tooltipHasBeak",
        tooltipCustomClass: "tooltipCustomClass"
      },
      outputs: {
        onShown: "onShown",
        onHidden: "onHidden"
      },
      exportAs: ["dds-tooltip"],
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipDirective, [{
    type: Directive,
    args: [{
      selector: '[ddsTooltip]',
      exportAs: 'dds-tooltip',
      standalone: true
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }, {
    type: TooltipService
  }, {
    type: i2.FocusHandlerService
  }], {
    ddsTooltip: [{
      type: Input
    }],
    tooltipKeyboardFocusEl: [{
      type: Input
    }],
    tooltipWidth: [{
      type: Input
    }],
    tooltipMaxWidth: [{
      type: Input
    }],
    tooltipIsDisabled: [{
      type: Input
    }],
    tooltipIsInverse: [{
      type: Input
    }],
    tooltipIsOutsideClick: [{
      type: Input
    }],
    tooltipIsDynamic: [{
      type: Input
    }],
    tooltipShift: [{
      type: Input
    }],
    tooltipIndent: [{
      type: Input
    }],
    tooltipShowDelay: [{
      type: Input
    }],
    tooltipHideDelay: [{
      type: Input
    }],
    tooltipInvokeType: [{
      type: Input
    }],
    tooltipPosition: [{
      type: Input
    }],
    tooltipType: [{
      type: Input
    }],
    tooltipTheme: [{
      type: Input
    }],
    tooltipSize: [{
      type: Input
    }],
    tooltipHasBeak: [{
      type: Input
    }],
    tooltipCustomClass: [{
      type: Input
    }],
    onShown: [{
      type: Output
    }],
    onHidden: [{
      type: Output
    }]
  });
})();
class TooltipModule {
  static {
    this.ɵfac = function TooltipModule_Factory(t) {
      return new (t || TooltipModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TooltipModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [TooltipService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [TooltipDirective, TooltipComponent],
      exports: [TooltipDirective],
      providers: [TooltipService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InvokeType, TooltipClass, TooltipDirective, TooltipModule, TooltipType };
