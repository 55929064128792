export enum MimeTypes {
    CSV='text/csv',
    XLS="application/vnd.ms-excel",
    XLSX="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}

export enum FileTypes {
    'text/csv'='csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'='xlsx',
    'application/vnd.ms-excel'='xls'
}

export enum masterSkillTypes{
    'Specialized Skill'=1,
    'Common Skill'=2,
    'Certification'=3,
}

export enum masterSkillTechnologies{
    'Language'=1,
    'Software'=2
}