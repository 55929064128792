import { Component, input, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-pending-change-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pending-change-item.component.html',
  styleUrl: './pending-change-item.component.sass'
})
export class PendingChangeItemComponent implements OnInit {
  @Input() Item!: { label: string, originalValue: any, pcValue: any, textShortener?: boolean };
  showFullText: boolean = false;
  isLongText: boolean = false;
  
  ngOnInit(): void {
    this.showMoreVisible(this.Item.originalValue, this.Item.pcValue)
  }

  showMoreVisible(value1: string, value2: string) {
    if (value1 && value2) {
      let charCount = value1.length + value2.length;
      this.isLongText = charCount > 50;
      if (this.Item.textShortener && this.isLongText) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toggleText(): void {
    this.showFullText = !this.showFullText;
  }


}
