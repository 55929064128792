import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
declare var _satellite: any;
declare var analyticsDataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class configService {
  private settings: any;

  constructor(private readonly http: HttpClient) {


  }

  init(endpoint: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      let init$ = this.http.get(endpoint);
      let response = await lastValueFrom(init$);
      this.settings = response;
      resolve(true);
    });
  }

  setSettings(value: any): void {
    this.settings = value;
  }

  getSettings(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.settings;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    let result = key.reduce((acc: any, current: string) => acc && acc[current], this.settings);

    return result;
  }

  getAllSettings() {
    return this.settings;
  }
  callAdobeEvent(pageName: string) {
    let userName = localStorage.getItem("loggedInUserName");
    if (userName !== null && userName !== undefined) {
      userName = userName.split('@')[0].toString();
    } else {
      userName = '';
    }
    
  }

  test() {
    return this.http.get('https://651ec54444a3a8aa4768fa35.mockapi.io/user');
  }
}

export const CONFIG_STRING = new InjectionToken('configs');