<div class="dds-row cards-wrapper">
  <div class="dds-col card" [ngClass]="{'skeleton':DSTCardLoading}" (click)="navigateTo(DSTCard.link)" role="link"
    aria-label="click to go to Deloitte Skill Taxonomy">
  @if(DSTCard){
    <div class="card-header">
      <span>{{DSTCard.title}}</span>
      @if(DSTCard.titleTag){
      <span class="badge badge-primary">{{DSTCard.titleTag}}</span>
      }
    </div>
    <div class="card-body">
      <div class="amount">{{DSTCard.amount | number:'1.0-0':'en-US'}}</div>
      <div class="description">{{DSTCard.description}}</div>
      <div class="seconday-amount">
        <span><strong>{{DSTCard.secondaryAmount}} </strong></span>
        <span>{{DSTCard.secondaryText}}</span>
      </div>
      <div class="updated">Updated <strong>{{DSTCard.updateDate | date: 'shortDate'}}</strong></div>
    </div>
  }@else{
  <div class="card-header">No data available</div>
  }
  </div>
  <div class="dds-col card" [ngClass]="{'skeleton':MLCardLoading}" (click)="navigateTo(MLCard.link)" role="link"
    aria-label="click to go to Market library">
    @if(MLCard){
    <div class="card-header">
      <span>{{MLCard.title}}</span>
      @if(MLCard.titleTag){
      <span class="badge badge-primary">{{MLCard.titleTag}}</span>
      }
    </div>
    <div class="card-body">
      <div class="amount">{{MLCard.amount | number:'1.0-0':'en-US'}}</div>
      <div class="description">{{MLCard.description}}</div>
      <div class="seconday-amount">
        <span><strong>{{MLCard.secondaryAmount}} </strong></span>
        <span>{{MLCard.secondaryText}}</span>
      </div>
      <div class="updated">Updated <strong>{{MLCard.updateDate | date: 'shortDate'}}</strong></div>
    </div>
  }@else{
  <div class="card-header">No data available</div>
    }
  </div>
  
</div>
