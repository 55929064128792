import { Component, Input, OnInit } from '@angular/core';
import { Skill } from '../../model/skill';
import { ButtonModule } from'@usitsdasdesign/dds-ng/button';
import { CommonModule } from '@angular/common';
import { ChangeStatusModalComponent } from '../../../views/market-library/widgets/change-status-modal/change-status-modal.component';
import { PopupService } from '../../services/popup.service';
import { DrawerComponent } from '../drawer/drawer.component';


@Component({
  selector: 'app-skill-detail',
  standalone: true,
  imports: [ButtonModule, CommonModule],
  templateUrl: './skill-detail.component.html',
  styleUrl: './skill-detail.component.sass'
})

export class SkillDetailComponent implements OnInit {
  @Input() skill!: Skill;
  public showFullDescription = false;
  public descriptionLimit = 100;
  public role: number = 0;
  

  constructor(
              private _popUpService:PopupService,
              private _drawer: DrawerComponent
            ) { }

  ngOnInit(): void {
    const storedRole = localStorage.getItem('role');
    this.role = Number(storedRole);
  }

  toggleDescription(): void {
  this.showFullDescription = !this.showFullDescription;
  }

  trackByFn(indesx: number, tag: string): string {
    return tag;
  }
  
  async changeStatus(newStatus:boolean) {
    this._drawer.close();
    let skillData = this.skill;
    let title: string= newStatus==true?"Reactivate Skill":"Retire Skill";
    this._popUpService.dialog (ChangeStatusModalComponent, { title: title, data: { skillData} })
    
  }
}
