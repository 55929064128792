<div class="">
  <dds-footer>
    <div content>
      <div class="dds-container">
        <strong class="dds-footer__title">
          For questions, concerns, or feedback, please contact
          <a href="mailto:admin&#64;deloitte.com" ddsFocus class="dds-link dds-link_dark"
            target="_blank">Admin&#64;deloitte.com</a>
        </strong>
        <p class="dds-footer__text">
          Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member
          firms, and their related entities (collectively, the “Deloitte organization”). DTTL (also referred to as
          “Deloitte Global”) and each of its member firms and related entities are legally separate and independent
          entities, which cannot obligate or bind each other in respect of third parties. DTTL and each DTTL member firm
          and related entity is liable only for its own acts and omissions, and not those of each other. DTTL does not
          provide services to clients.
          <br>
          Please see
          <a href="http://www.deloitte.com/about" ddsFocus class="dds-link dds-link_dark"
            target="_blank">www.deloitte.com/about</a>
          to learn more.
        </p>
        <p class="dds-footer__text">
          © 2022 For information, contact Deloitte Global.
        </p>
      </div>
    </div>
  </dds-footer>
</div>